import Sidebar from "../Sidebar";
import { Input, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import PageTitle from "../../../components/Pagetitle";
import { useEffect, useState } from "react";
import { HttpClient } from "../../../api/HttpClient";
import { ScaleLoader } from "react-spinners";
import { format } from "date-fns";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Plugin } from "chart.js";
import ErrorHandler from "../../ErrorHandler";

ChartJS.register(ArcElement, Legend);

interface InvestData {
  _id?: string;
  key?: string;
  user: string;
  amount: number;
  roiExpected: number;
  totalPayable: number;
  createdAt: string;
  maturityDate: string;
  numberOfUnits: number;
  investment_details: {
    _id: string;
    title: string;
    units: number;
    investmentDurationInDays: string;
  };
  user_details: {
    firstName: string;
    lastName: string;
  };
}

interface InvestmentStatusChart {
  name: string;
  value: number;
  fill?: string;
}

interface ChartDataItem {
  _id: {
    category: string;
  };
  categoryCount: number;
}

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

interface CustomPlugin extends Plugin<"doughnut"> {
  id: string;
}

function InvestmentLog() {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<InvestData[]>([]);
  const [allInvestData, setAllInvestData] = useState<InvestData[]>([]);
  const [barData, setBarData] = useState<InvestmentStatusChart[]>([]);
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ["#009306", "#6B006B", "#0357D1", "#C59832"],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
  });

  const centerTextPlugin: CustomPlugin = {
    id: "centerTextPlugin",
    beforeDraw: (chart) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const total = chart.data.datasets[0].data.reduce(
        (acc: number, value: number) => acc + value,
        0
      );
      ctx.fillText("Total:", width / 2, height / 2);

      ctx.font = "20px PoppinsSemiBold";
      ctx.fillStyle = "#3D3C3A";
      ctx.fillText(`${total}`, width / 2, height / 2 + 25);
      ctx.restore();
    },
  };

  const fetchAllInvestData = async () => {
    try {
      const [investChartRes, donutChartRes, investDataRes] = await Promise.all([
        HttpClient.get("/investments/status-statistics", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            search: "",
            startdate: "",
            enddate: "",
          },
        }),
        HttpClient.get("/investments/category-statistics", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            search: "",
            startdate: "",
            enddate: "",
          },
        }),
        HttpClient.get("/investments/booking", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);

      const investData = investChartRes.data.data;

      const formattedData: InvestmentStatusChart[] = [
        {
          name: "All Investments",
          value: investData.all_investment_data,
          fill: "#0357D1",
        },
        {
          name: "Pending",
          value: investData.pending_investment_data,
          fill: "#C59832",
        },
        {
          name: "Running",
          value: investData.running_investment_data,
          fill: "#009306",
        },
        {
          name: "Matured",
          value: investData.matured_investment_data,
          fill: "#6B006B",
        },
        {
          name: "Sold Out",
          value: investData.sold_out_investment_data,
          fill: "#E80404",
        },
      ];

      setBarData(formattedData);

      const dataItems: ChartDataItem[] = donutChartRes.data.data;
      const dataValues = dataItems.map((item) => item.categoryCount);
      const labels = dataItems.map((item) => item._id.category);

      setChartData((prevData) => ({
        ...prevData,
        labels: labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataValues,
          },
        ],
      }));

      const investLog = investDataRes.data.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          createdAt: item?.createdAt,
          name:
            item?.user_details?.firstName + " " + item?.user_details?.lastName,
          roiExpected: item?.roiExpected,
          description: item?.description,
          totalPayable: item?.totalPayable,
          numberOfUnits: item?.numberOfUnits,
          amount: item?.amount,
          maturityDate: item?.maturityDate,
          investmentTitle: item?.investment_details?.title,
          investmentUnits: item?.investment_details?.units,
          investmentDurationInDays:
            item?.investment_details?.investmentDurationInDays,
          user_details: item?.user_details,
        })
      );

      setAllInvestData(investLog);
      setPagination((prev) => ({
        ...prev,
        total: investDataRes.data.data.total,
      }));
      applyFilters(investLog);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = (data: InvestData[]) => {
    let filtered = data;

    if (filters.name) {
      filtered = filtered.filter((item) => {
        const firstName = item?.user_details?.firstName?.toLowerCase() || "";
        const lastName = item?.user_details?.lastName?.toLowerCase() || "";
        const fullName = `${firstName} ${lastName}`;
        return fullName.includes(filters.name.toLowerCase());
      });
    }

    setFilteredData(filtered);
    setPagination((prev) => ({
      ...prev,
      total: filtered.length,
    }));
  };

  useEffect(() => {
    fetchAllInvestData();
  }, []);

  useEffect(() => {
    applyFilters(allInvestData);
  }, [filters]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const columns: ColumnsType<InvestData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      filterDropdown: () => (
        <Input
          placeholder="Jon Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(allInvestData),
    },
    {
      key: "investmentTitle",
      title: "Title",
      dataIndex: "investmentTitle",
    },
    {
      key: "investmentUnits",
      title: "Units",
      dataIndex: "investmentUnits",
    },
    {
      key: "numberOfUnits",
      title: "Units Purchased",
      dataIndex: "numberOfUnits",
    },
    {
      key: "maturityDate",
      title: "Maturity Date",
      dataIndex: "maturityDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },

    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => `₦${Number(amount).toLocaleString()}`,
    },
    {
      key: "roiExpected",
      title: "ROI",
      dataIndex: "roiExpected",
      render: (roiExpected: number) =>
        `₦${Number(roiExpected).toLocaleString()}`,
    },
    {
      key: "totalPayable",
      title: "Total Payable",
      dataIndex: "totalPayable",
      render: (totalPayable: number) =>
        `₦${Number(totalPayable).toLocaleString()}`,
    },
  ];
  return (
    <>
      <PageTitle title="Affluence - Admin: Investment Log" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="p-4">
              <div className="flex flex-col lg:flex-row gap-4 mt-28">
                <div className="border shadow-md border-transactionCard rounded-lg p-5 h-[370px] w-full">
                  <span className="text-semibold text-textcolor font-PoppinsBold pb-4">
                    Investment Status Statistics
                  </span>
                  <div className="overflow-x-auto">
                    <div style={{ width: 650, height: 300 }}>
                      <BarChart width={600} height={300} data={barData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip formatter={(value: number) => `${value}`} />
                        <Bar dataKey="value" />
                      </BarChart>
                    </div>
                  </div>
                </div>

                <div className="border shadow-md border-transactionCard rounded-lg p-5 h-[370px] w-full">
                  <span className="text-semibold text-textcolor font-PoppinsBold">
                    Investment Category Statistics
                  </span>
                  <div className="flex justify-center">
                    <div style={{ height: 300 }}>
                      <Doughnut
                        data={chartData}
                        plugins={[centerTextPlugin]}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              display: true,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full shadow-md mt-8 border border-transactionCard rounded-lg p-4">
                  <div className="flex gap-4 mb-4 items-center">
                    <span className="text-semibold text-textcolor font-PoppinsBold block mb-4">
                      Investment Logs
                    </span>
                  </div>
                  <div className="overflow-x-auto">
                    <Table
                      dataSource={filteredData}
                      columns={columns}
                      pagination={pagination}
                      onChange={(newPagination) => setPagination(newPagination)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InvestmentLog;
