import { useState } from "react";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

const DateFilter = ({ onFilter }: any) => {
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);

  const handleValueChange: RangePickerProps["onChange"] = (dates) => {
    setDateRange(dates as [Dayjs | null, Dayjs | null]);
    if (dates && dates[0] && dates[1]) {
      onFilter({
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div>
      <div>
        <label className="text-textcolor text-[12px]">Filter by Date</label>
      </div>
      <RangePicker
        value={dateRange}
        onChange={handleValueChange}
        presets={[
          {
            label: "Today",
            value: [dayjs().startOf("day"), dayjs().endOf("day")],
          },
          {
            label: "This Month",
            value: [dayjs().startOf("month"), dayjs().endOf("month")],
          },
        ]}
      />
    </div>
  );
};

export default DateFilter;
