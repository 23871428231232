import PageTitle from "../../../components/Pagetitle";
import Sidebar from "../Sidebar";
import { useState } from "react";

function InvestmentCategory() {

  return (
    <>
      <PageTitle title="Investment Category" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          <div className="mt-28 p-4 shadow-lg border border-transactionCard rounded-lg mx-4">
            <span className="text-semibold text-textcolor font-PoppinsBold block mb-4">
              Add Investment Category
            </span>
            <div className="overflow-x-auto">
              <form className="w-full">
                <div className="mb-6">
                  <label
                    htmlFor="username"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Investment Category
                  </label>
                  <input
                    type="text"
                    name="username"
                    // value={accountData?.username || ""}
                    className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                  />
                </div>

                <button
                  type="submit"
                  className="py-2 px-4 border border-primary text-primary font-PoppinsMedium rounded-lg"
                >
                  Add Category
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestmentCategory;
