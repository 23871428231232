import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define your AuthState interface
interface AuthState {
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  role: string; // Add role to AuthState
}

const initialState: AuthState = {
  isAuthenticated: false,
  isEmailVerified: false,
  role: "", // Initialize role
};

// Update the authSlice with a new action for setting the role
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setIsEmailVerified(state, action: PayloadAction<boolean>) {
      state.isEmailVerified = action.payload;
    },
    setRole(state, action: PayloadAction<string>) {
      state.role = action.payload;
    },
  },
});

export const { setIsAuthenticated, setIsEmailVerified, setRole } =
  authSlice.actions;
export default authSlice.reducer;
