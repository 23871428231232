import axios from "axios";
const getToken = () => {
  return localStorage.getItem("token");
};
export const HttpClient = axios.create({
  baseURL: "http://203.161.55.174:5000/api/v2",
  headers: {
    "Content-Type": "application/json",
    "x-auth-token": getToken() ? `Bearer ${getToken()}` : "",
    "verify-affluence-hash": process.env.REACT_APP_TRANSACTION_HASH || "",
  },
});
