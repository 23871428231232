import { useState } from "react";
import Illustrator from "../assets/img/illustrator-aff 1.png";
import Logo from "../assets/img/affluence.png";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { HttpClient } from "../api/HttpClient";
import TextInput from "../components/TextInput";
import AuthButton from "../components/AuthButton";
import PasswordInput from "../components/PasswordInput";
import ToastComponent from "../components/ToastComponent";
import { isAxiosError } from "axios";
import PageTitle from "../components/Pagetitle";
import LightAuthTextInput from "../components/LightAuthTextInput";
import LightAuthPasswordInput from "../components/LightAuthPasswordInput";
// import bcrypt from "bcryptjs";
const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const phoneRegExp = /^\+?\d{1,3}?\d{10}$/;
  const userSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter your Firstname"),
    lastName: Yup.string().required("Enter your Lastname"),
    email: Yup.string()
      .email("Invalid email")
      .required("Enter your Email Address"),
    phone: Yup.string()
      .matches(phoneRegExp, "Invalid phone number format")
      .required("Enter your Phone Number"),
    username: Yup.string().required("Enter your Username"),
    password: Yup.string().required("Enter your Password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password do not match")
      .required("Confirm your Password"),
  });
  const navigate = useNavigate();

  const handleSubmit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    if (!termsChecked) {
      ToastComponent.error("You must accept the terms and conditions");
      return;
    }
    // const data = {
    //   firstName: values.firstName,
    //   lastName: values.lastName,
    //   email: values.email,
    //   phone: values.phone,
    //   username: values.username,
    // };
    setLoading(true);
    try {
      // const hashedPassword = await bcrypt.hash(values.password, 15);
      // const PWD_HEADER = process.env.REACT_APP_PWD_HEADER;
      const res = await HttpClient.post(
        "/users/register",
        values
        // {
        // headers: {
        //   // [PWD_HEADER || "PWD_HEADER"]: hashedPassword,
        //   [PWD_HEADER || "PWD_HEADER"]: values.password,
        // },
        // }
      );
      ToastComponent.success(res.data.message);
      navigate("/verify", {
        state: { email: values.email },
      });
      // const token = res.data.data;
      // localStorage.setItem("token", token);
      if (res.data.statusCode === 201) {
        await HttpClient.post("users/generate-token", {
          email: values.email,
        });
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("password", "", false);
      setFieldValue("confirmPassword", "", false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <PageTitle title="Affluence - SignUp" />
      <div className=" font-PoppinsRegular text-textcolor">
        <div className="h-screen flex xmd:flex-row flex-col justify-center xmd:items-stretch items-center">
          <div className="bg-primary xxmd:flex w-[55%] h-screen hidden justify-center items-center bg-opacity-10">
            <img src={Illustrator} className="" alt="img" />
          </div>
          <div className="xxmd:w-[45%] pb-10 w-full hide-scroll overflow-y-auto bg-white sm:px-[3%] px-[30px] sm:pt-[300px] pt-[150px] flex justify-center items-center">
            <div className="xmd:w-[90%] w-full max-w-[500px]">
              <div className="flex justify-center">
                <img src={Logo} className="sm:w-[120px] w-[100px]" alt="img" />
              </div>
              <h1 className="text-center flex gap-2 justify-center text-primary pt-2 font-PoppinsLight">
                <span className=" font-PoppinsMedium">Save</span>
                <span className="text-secondary">|</span>
                <span className=" font-PoppinsMedium">Invest</span>
                <span className="text-secondary">|</span>
                <span className=" font-PoppinsMedium">Earn</span>
              </h1>
              <div className="pt-3 pb-5 sm:text-start text-center">
                <h1 className="sm:text-[28px] text-[black] text-[24px] font-PoppinsBold pb-1">
                  Sign up with Affluence
                </h1>
                <p className="font-PoppinsRegular text-[black] sm:text-[15px] text-[14px]">
                  Create a new account
                </p>
              </div>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  username: "",
                  password: "",
                  confirmPassword: "",
                  // terms: false,
                }}
                validationSchema={userSchema}
                onSubmit={handleSubmit}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <LightAuthTextInput
                      name="firstName"
                      type="text"
                      placeholder="Firstname"
                    />
                    <LightAuthTextInput
                      name="lastName"
                      type="text"
                      placeholder="Lastname"
                    />
                    <LightAuthTextInput
                      name="email"
                      type="email"
                      placeholder="Email Address"
                    />
                    <LightAuthTextInput
                      name="phone"
                      type="tel"
                      placeholder="Phone Number"
                    />
                    <LightAuthTextInput
                      name="username"
                      type="text"
                      placeholder="Username"
                    />
                    <LightAuthPasswordInput
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                    <LightAuthPasswordInput
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                    />
                    <div className="pb-4">
                      <div className="flex sm:items-center items-start">
                        <Field
                          type="checkbox"
                          className="mr-2 custom-checkbox"
                          id="terms"
                          checked={termsChecked}
                          onChange={() => setTermsChecked(!termsChecked)}
                        />
                        <label
                          htmlFor="terms"
                          className="font-PoppinsRegular text-[14px]"
                        >
                          I agree to terms of service and privacy policy
                        </label>
                      </div>
                    </div>
                    <AuthButton
                      disabled={!isValid || !dirty}
                      isLoading={loading}
                    >
                      Sign up
                    </AuthButton>
                    <h1 className="font-PoppinsRegular text-center pt-3 text-[14px]">
                      Already have an account?{" "}
                      <Link
                        to="/login"
                        className="text-primary font-PoppinsMedium"
                      >
                        Login
                      </Link>
                    </h1>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
