import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import TextAreaInput from "../components/TextAreaInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import { HttpClient } from "../api/HttpClient";
import FileInput from "../components/FileInput";
import KYCButton from "../components/KYCbutton";
import { useNavigate, useOutletContext } from "react-router-dom";
import PageTitle from "../components/Pagetitle";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { AuthenticatedLayoutContext } from "../assets/Typeprops";
import { RiMenu2Fill } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";

interface Level3FormValues {
  identificationType: string;
  identificationNumber: string;
  document_files: (File | string)[];
}
interface KYCStatus {
  level1Status: string;
  level2Status: string;
  level3Status: string;
}
interface KYCDocumentData {
  homeAddress?: string;
  purposeOfInvestment?: string;
  nextOfKinFullName?: string;
  nextOfKinAddress?: string;
  nextOfKinPhone?: string;
  nextOfKinRelationship?: string;
  identificationType?: string;
  identificationNumber?: string;
  uploadSelectedId?: string;
  uploadProofOfAddress?: string;
  uploadSelfieWithId?: string;
  kycLevelOneRejectionComment?: string;
  kycLevelTwoRejectionComment?: string;
  kycLevelThreeRejectionComment?: string;
  kycAllLevelRejectionComment?: string;
}
const KYC = () => {
  const level1Schema = Yup.object().shape({
    homeAddress: Yup.string().required("Enter Home Address"),
    purposeOfInvestment: Yup.string().required(),
  });
  const phoneRegExp = /^\+?\d{1,3}?\d{10}$/;

  const level2Schema = Yup.object().shape({
    nextOfKinFullName: Yup.string().required("Enter Next of Kin Full Name"),
    nextOfKinAddress: Yup.string().required("Enter Next of Kin Address"),
    nextOfKinPhone: Yup.string()
      .matches(phoneRegExp, "Invalid phone number format")
      .required("Enter Next of Kin Phone Number"),
    nextOfKinRelationship: Yup.string().required(),
  });
  const level3Schema = Yup.object().shape({
    identificationType: Yup.string().required(
      "Identification type is required"
    ),
    identificationNumber: Yup.string().required(
      "Identification number is required"
    ),
    document_files: Yup.array()
      .min(1, "At least one document is required")
      .required("Documents are required"),
  });

  const [loading, setLoading] = useState(false);
  const [isOpenLevel, setIsOpenLevel] = useState({
    level1: true,
    level2: false,
    level3: false,
  });
  const [completedLevels, setCompletedLevels] = useState({
    level1: false,
    level2: false,
    level3: false,
  });
  const [kycStatus, setKycStatus] = useState<KYCStatus>({
    level1Status: "action required",
    level2Status: "action required",
    level3Status: "action required",
  });
  const toggleOpenLevel = (level: keyof typeof isOpenLevel) => {
    if (
      (level === "level2" && !completedLevels.level1) ||
      (level === "level3" && !completedLevels.level2)
    ) {
      if (
        !initialValues.level1KYC.homeAddress &&
        !initialValues.level1KYC.purposeOfInvestment
      ) {
        ToastComponent.error("Please complete the previous level first.");
        return;
      }
    }

    setIsOpenLevel((prevState) => ({
      ...prevState,
      [level]: !prevState[level],
    }));
  };

  const handleSuccess = (level: keyof typeof isOpenLevel) => {
    setIsOpenLevel((prevState) => ({
      ...prevState,
      [level]: false,
      [`level${parseInt(level.slice(-1)) + 1}`]: true,
    }));

    setCompletedLevels((prevState) => ({
      ...prevState,
      [level]: true,
    }));
  };

  const [initialValues, setInitialValues] = useState({
    level1KYC: { homeAddress: "", purposeOfInvestment: "" },
    level2KYC: {
      nextOfKinFullName: "",
      nextOfKinAddress: "",
      nextOfKinPhone: "",
      nextOfKinRelationship: "",
    },
    level3KYC: {
      identificationType: "",
      identificationNumber: "",
      document_files: [] as (File | string)[],
    },
  });
  const [fetchedData, setFetchedData] = useState<KYCDocumentData | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const navigate = useNavigate();
  const fetchUserKYCData = async () => {
    const getToken = () => {
      return localStorage.getItem("token");
    };
    const token = getToken();
    setIsPageLoading(true);
    try {
      const userData = await HttpClient.get("/users/me", {
        headers: {
          "x-auth-token": token,
        },
      });
      const userKYCData = userData.data.data.kyc;
      setFetchedData(userKYCData);
      const documentFiles = [
        userKYCData.uploadSelectedId || "",
        userKYCData.uploadProofOfAddress || "",
        userKYCData.uploadSelfieWithId || "",
      ];
      setInitialValues({
        level1KYC: {
          homeAddress: userKYCData.homeAddress,
          purposeOfInvestment: userKYCData.purposeOfInvestment || "",
        },
        level2KYC: {
          nextOfKinFullName: userKYCData.nextOfKinFullName || "",
          nextOfKinAddress: userKYCData.nextOfKinAddress || "",
          nextOfKinPhone: userKYCData.nextOfKinPhone || "",
          nextOfKinRelationship: userKYCData.nextOfKinRelationship || "",
        },
        level3KYC: {
          identificationType: userKYCData.identificationType || "",
          identificationNumber: userKYCData.identificationNumber || "",
          document_files: documentFiles,
        },
      });
      const kycLevelOneStatus =
        userKYCData.kycLevelOneStatus || "action required";
      const kycLevelTwoStatus =
        userKYCData.kycLevelTwoStatus || "action required";
      const kycLevelThreeStatus =
        userKYCData.kycLevelThreeStatus || "action required";
      setKycStatus((prevStatus) => ({
        ...prevStatus,
        level1Status: kycLevelOneStatus,
        level2Status: kycLevelTwoStatus,
        level3Status: kycLevelThreeStatus,
      }));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setLoading(false);
      setIsPageLoading(false);
    }
  };
  useEffect(() => {
    fetchUserKYCData();
  }, []);

  const level1handleSubmit = async (values: any) => {
    const getToken = () => {
      return localStorage.getItem("token");
    };
    const token = getToken();
    setLoading(true);
    try {
      const res = await HttpClient.post("/users/kyc/1", values, {
        headers: {
          "x-auth-token": token,
        },
      });
      ToastComponent.success(res.data.message);
      handleSuccess("level1");
      fetchUserKYCData();
      if (fetchedData?.homeAddress && fetchedData?.purposeOfInvestment) {
        setIsOpenLevel((prevState) => ({
          ...prevState,
          level2: true,
        }));
        setCompletedLevels((prevState) => ({
          ...prevState,
          level1: true,
        }));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const level2handleSubmit = async (values: any) => {
    const getToken = () => {
      return localStorage.getItem("token");
    };
    const token = getToken();
    setLoading(true);
    try {
      const res = await HttpClient.post("/users/kyc/2", values, {
        headers: {
          "x-auth-token": token,
        },
      });
      ToastComponent.success(res.data.message);
      handleSuccess("level2");
      fetchUserKYCData();
      if (
        fetchedData?.nextOfKinFullName &&
        fetchedData?.nextOfKinAddress &&
        fetchedData?.nextOfKinPhone &&
        fetchedData?.nextOfKinRelationship
      ) {
        setIsOpenLevel((prevState) => ({
          ...prevState,
          level3: true,
        }));
        setCompletedLevels((prevState) => ({
          ...prevState,
          level2: true,
        }));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const level3handleSubmit = async (values: Level3FormValues) => {
    const getToken = () => {
      return localStorage.getItem("token");
    };
    const token = getToken();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("identificationType", values.identificationType);
      formData.append("identificationNumber", values.identificationNumber);

      if (values.document_files && values.document_files.length > 0) {
        values.document_files.forEach((file) => {
          formData.append("document_files", file);
        });
      } else {
        if (fetchedData) {
          if (fetchedData.uploadSelectedId) {
            formData.append("document_files", fetchedData.uploadSelectedId);
          }
          if (fetchedData.uploadProofOfAddress) {
            formData.append("document_files", fetchedData.uploadProofOfAddress);
          }
          if (fetchedData.uploadSelfieWithId) {
            formData.append("document_files", fetchedData.uploadSelfieWithId);
          }
        }
      }

      const res = await HttpClient.post("/users/kyc/3", formData, {
        headers: {
          "x-auth-token": token,
          "Content-Type": "multipart/form-data",
        },
      });
      ToastComponent.success(res.data.message);
      handleSuccess("level3");
      fetchUserKYCData();
      navigate("/");
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserKYCData().then(() => {
      if (
        initialValues.level1KYC.homeAddress &&
        initialValues.level1KYC.purposeOfInvestment
      ) {
        setCompletedLevels((prevLevels) => ({
          ...prevLevels,
          level1: true,
        }));
        setIsOpenLevel((prevState) => ({
          ...prevState,
          level2: true,
        }));
        setIsOpenLevel((prevState) => ({
          ...prevState,
          level3: true,
        }));
      }
    });
  }, [
    initialValues.level1KYC.homeAddress,
    initialValues.level1KYC.purposeOfInvestment,
  ]);
  const purposeOfInvestment = [
    { value: "Investment Purpose", label: "Investment Purpose" },
    { value: "Future Use", label: "Future Use" },
    { value: "Business", label: "Business" },
  ];
  const nextofKinRelationShip = [
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
    { value: "Others", label: "Others" },
  ];
  const identificationType = [
    { value: "National ID", label: "National ID" },
    { value: "Voter's Card", label: "Voter's Card" },
    { value: "International Passport", label: "International Passport" },
    { value: "Driver's License", label: "Driver's License" },
  ];
  useEffect(() => {
    if (
      kycStatus.level1Status === "pending" ||
      kycStatus.level1Status === "approved"
    ) {
      setIsOpenLevel((prevState) => ({
        ...prevState,
        level1: false,
      }));
    }
    if (
      kycStatus.level2Status === "pending" ||
      kycStatus.level2Status === "approved"
    ) {
      setIsOpenLevel((prevState) => ({
        ...prevState,
        level2: false,
      }));
    }
    if (
      kycStatus.level3Status === "pending" ||
      kycStatus.level3Status === "approved"
    ) {
      setIsOpenLevel((prevState) => ({
        ...prevState,
        level3: false,
      }));
    }
  }, [kycStatus.level1Status, kycStatus.level2Status, kycStatus.level3Status]);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  return (
    <>
      <PageTitle title="Affluence - KYC" />
      {/* KYC Verification section */}
      {isPageLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="pt-[6rem] xmd:px-[3rem] px-[1.5rem] bg-white h-full dark:bg-darkPrimary hide-scroll overflow-y-scroll">
          <div className="w-full">
            <div className="flex items-center gap-3">
              <button onClick={toggleNav} className="md:hidden block px-[3%]">
                <RiMenu2Fill
                  size={30}
                  className="text-primary dark:text-white"
                />
              </button>
              <h1 className="text-[25px] tracking-wide text-textcolor dark:text-white font-PoppinsBold">
                KYC Verification
              </h1>
            </div>
            {/* KYC Section */}
            <div className="pt-[3rem]">
              {/* Level 1 Section */}
              <div className="mb-[2rem]">
                <div className="bg-[#FAFAFA] shadow-md shadow-disabled dark:bg-textcolor dark:shadow-[#232B2B] rounded-[8px] p-[2.3rem] w-full">
                  <div className="flex items-center justify-between text-textcolor dark:text-white">
                    <div className="flex items-center gap-3">
                      <div className="font-PoppinsSemiBold text-[1.1em]">
                        Level 1
                      </div>
                      <div
                        className={`text-[0.8em] ${
                          kycStatus.level1Status === "action required"
                            ? "text-error bg-error bg-opacity-[28%]"
                            : kycStatus.level1Status === "rejected"
                            ? "text-error bg-error bg-opacity-[28%]"
                            : kycStatus.level1Status === "pending"
                            ? "text-secondary bg-secondary bg-opacity-[28%]"
                            : kycStatus.level1Status === "approved"
                            ? "text-success bg-success bg-opacity-[28%]"
                            : ""
                        } rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem]`}
                      >
                        {kycStatus.level1Status}
                      </div>
                    </div>
                    <div
                      className="text-textcolor dark:text-white cursor-pointer"
                      onClick={() => toggleOpenLevel("level1")}
                    >
                      {isOpenLevel.level1 ? (
                        <IoIosArrowUp size={22} />
                      ) : (
                        <IoIosArrowDown size={22} />
                      )}
                    </div>
                  </div>
                  {kycStatus.level1Status === "rejected" ? (
                    fetchedData?.kycAllLevelRejectionComment ? (
                      <div className="text-error mt-2 font-PoppinsMedium text-[12px]">
                        {fetchedData?.kycAllLevelRejectionComment}
                      </div>
                    ) : (
                      <div className="text-error mt-2 font-PoppinsMedium text-[12px]">
                        {fetchedData?.kycLevelOneRejectionComment}
                      </div>
                    )
                  ) : null}
                  {isOpenLevel.level1 && (
                    <div className="pt-[3rem]">
                      <Formik
                        initialValues={initialValues.level1KYC}
                        validationSchema={level1Schema}
                        onSubmit={(values) => level1handleSubmit(values)}
                        enableReinitialize
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <TextAreaInput
                              type="text"
                              name="homeAddress"
                              placeholder="Home Address"
                            />
                            <SelectInput
                              name="purposeOfInvestment"
                              options={purposeOfInvestment}
                              placeholder="Purpose of Investment"
                            />
                            <KYCButton
                              isLoading={loading}
                              disabled={!isValid || !dirty}
                            >
                              Save Information
                            </KYCButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>

              {/* Level 2 Section */}
              <div className="mb-[2rem]">
                <div className="bg-[#FAFAFA] dark:bg-textcolor shadow-md dark:shadow-[#232B2B] shadow-disabled rounded-[8px] p-[2.3rem] w-full">
                  <div className="flex items-center justify-between text-textcolor dark:text-white">
                    <div className="flex items-center gap-3">
                      <div className="font-PoppinsSemiBold text-[1.1em]">
                        Level 2
                      </div>
                      <div
                        className={`text-[0.8em] ${
                          kycStatus.level2Status === "action required"
                            ? "text-error bg-error bg-opacity-[28%]"
                            : kycStatus.level2Status === "rejected"
                            ? "text-error bg-error bg-opacity-[28%]"
                            : kycStatus.level2Status === "pending"
                            ? "text-secondary bg-secondary bg-opacity-[28%]"
                            : kycStatus.level2Status === "approved"
                            ? "text-success bg-success bg-opacity-[28%]"
                            : ""
                        } rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem]`}
                      >
                        {kycStatus.level2Status}
                      </div>
                    </div>
                    <div
                      className="text-textcolor dark:text-white cursor-pointer"
                      onClick={() => toggleOpenLevel("level2")}
                    >
                      {isOpenLevel.level2 ? (
                        <IoIosArrowUp size={22} />
                      ) : (
                        <IoIosArrowDown size={22} />
                      )}
                    </div>
                  </div>
                  {kycStatus.level2Status === "rejected" ? (
                    fetchedData?.kycAllLevelRejectionComment ? (
                      <div className="text-error mt-2 font-PoppinsMedium text-[12px]">
                        {fetchedData?.kycAllLevelRejectionComment}
                      </div>
                    ) : (
                      <div className="text-error mt-2 font-PoppinsMedium text-[12px]">
                        {fetchedData?.kycLevelTwoRejectionComment}
                      </div>
                    )
                  ) : null}

                  {/* Level 2 KYC form */}

                  {isOpenLevel.level2 && (
                    <div className="pt-[3rem]">
                      <Formik
                        initialValues={initialValues.level2KYC}
                        validationSchema={level2Schema}
                        onSubmit={(values) => level2handleSubmit(values)}
                        enableReinitialize
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <TextInput
                              type="text"
                              name="nextOfKinFullName"
                              placeholder="Next of Kin FullName"
                            />
                            <TextAreaInput
                              type="text"
                              name="nextOfKinAddress"
                              placeholder="Next of Kin Address"
                            />
                            <TextInput
                              type="tel"
                              name="nextOfKinPhone"
                              placeholder="Next of Kin Phone Number"
                            />
                            <SelectInput
                              name="nextOfKinRelationship"
                              options={nextofKinRelationShip}
                              placeholder="Next of Kin Relationship"
                            />
                            <KYCButton
                              isLoading={loading}
                              disabled={!isValid || !dirty}
                            >
                              Save Information
                            </KYCButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>

              {/* Level 3 Section */}
              <div className="mb-[2rem]">
                <div className="bg-[#FAFAFA] dark:bg-textcolor shadow-md shadow-disabled dark:shadow-[#222222] rounded-[8px] p-[2.3rem] w-full">
                  <div className="flex items-center justify-between text-textcolor dark:text-white">
                    <div className="flex items-center gap-3">
                      <div className="font-PoppinsSemiBold text-[1.1em]">
                        Level 3
                      </div>
                      <div
                        className={`text-[0.8em] ${
                          kycStatus.level3Status === "action required"
                            ? "text-error bg-error bg-opacity-[28%]"
                            : kycStatus.level3Status === "rejected"
                            ? "text-error bg-error bg-opacity-[28%]"
                            : kycStatus.level3Status === "pending"
                            ? "text-secondary bg-secondary bg-opacity-[28%]"
                            : kycStatus.level3Status === "approved"
                            ? "text-success bg-success bg-opacity-[28%]"
                            : ""
                        } rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem]`}
                      >
                        {kycStatus.level3Status}
                      </div>
                    </div>
                    <div
                      className="text-textcolor dark:text-white cursor-pointer"
                      onClick={() => toggleOpenLevel("level3")}
                    >
                      {isOpenLevel.level3 ? (
                        <IoIosArrowUp size={22} />
                      ) : (
                        <IoIosArrowDown size={22} />
                      )}
                    </div>
                  </div>
                  {kycStatus.level3Status === "rejected" ? (
                    fetchedData?.kycAllLevelRejectionComment ? (
                      <div className="text-error mt-2 font-PoppinsMedium text-[12px]">
                        {fetchedData?.kycAllLevelRejectionComment}
                      </div>
                    ) : (
                      <div className="text-error mt-2 font-PoppinsMedium text-[12px]">
                        {fetchedData?.kycLevelThreeRejectionComment}
                      </div>
                    )
                  ) : null}
                  {/* Level 3 KYC form */}

                  {isOpenLevel.level3 && (
                    <div className="pt-[3rem]">
                      <Formik
                        initialValues={initialValues.level3KYC}
                        validationSchema={level3Schema}
                        onSubmit={level3handleSubmit}
                        enableReinitialize
                      >
                        {({ isValid }) => (
                          <Form>
                            <SelectInput
                              name="identificationType"
                              options={identificationType}
                              placeholder="Identification Type"
                            />
                            <TextInput
                              type="number"
                              name="identificationNumber"
                              placeholder="Identification Number"
                            />
                            <div className="flex items-center justify-between">
                              <FileInput
                                label="Select an Image of your Means of Identification"
                                name="document_files[0]"
                                initialPreviewUrl={
                                  initialValues.level3KYC
                                    .document_files[0] as string
                                }
                              />
                              <FileInput
                                label="Select an Image of your Proof of Identity"
                                name="document_files[1]"
                                initialPreviewUrl={
                                  initialValues.level3KYC
                                    .document_files[1] as string
                                }
                              />
                              <FileInput
                                label="Select an Image of you smiling with Identity Card"
                                name="document_files[2]"
                                initialPreviewUrl={
                                  initialValues.level3KYC
                                    .document_files[2] as string
                                }
                              />
                            </div>
                            <KYCButton isLoading={loading} disabled={!isValid}>
                              Save Information
                            </KYCButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KYC;
