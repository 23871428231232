import { AiOutlineHome } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { FaPowerOff } from "react-icons/fa6";
import { IoBriefcaseOutline, IoClose } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/img/affluence-logo.png";
const MobileNav = ({ openNav, closeNav }: any) => {
  const navigate = useNavigate();
  const router = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  if (!openNav) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-[black] bg-opacity-[50%] z-50 md:hidden block">
      <div className="bg-white h-screen w-full max-w-[370px] relative py-[60px] px-7 flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-between">
            <img src={Logo} className="w-[170px]" alt="affluence-logo" />
            <button onClick={closeNav} className="focus:outline-none">
              <IoClose size={30} />
            </button>
          </div>
          <div className="pt-[3rem]">
            <Link
              to="/"
              onClick={closeNav}
              className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                router.pathname === "/"
                  ? "bg-primary text-white"
                  : "text-textcolor"
              }`}
            >
              <AiOutlineHome size={24} />
              Home
            </Link>
            <Link
              to="/savings"
              onClick={closeNav}
              className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                router.pathname.startsWith("/savings")
                  ? "bg-primary text-white"
                  : "text-textcolor"
              }`}
            >
              <MdOutlineAccountBalanceWallet size={24} />
              Savings
            </Link>
            <Link
              onClick={closeNav}
              to="/investment/my_investments"
              className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                router.pathname.startsWith("/investment")
                  ? "bg-primary text-white"
                  : "text-textcolor"
              }`}
            >
              <IoBriefcaseOutline size={24} />
              Investment
            </Link>
            <Link
              to="/kyc"
              onClick={closeNav}
              className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                router.pathname === "/kyc"
                  ? "bg-primary text-white"
                  : "text-textcolor"
              }`}
            >
              <LuUser2 size={24} />
              Account
            </Link>
            <Link
              onClick={closeNav}
              to="support"
              className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                router.pathname === "/support"
                  ? "bg-primary text-white"
                  : "text-textcolor"
              }`}
            >
              <BiSupport size={24} />
              Support
            </Link>
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="flex items-center text-textcolor gap-3 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]"
        >
          <FaPowerOff size={24} />
          Logout
        </button>
      </div>
    </div>
  );
};

export default MobileNav;
