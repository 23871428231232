import { FC } from "react";
import { InvestmentItem } from "../assets/Typeprops";
import { formatCurrency } from "../components/CurrencyFormat";

interface ActiveInvestmentsProps {
  data: InvestmentItem[];
  categoryTab: any;
  setCategoryTab: any;
}
const MaturedInvestment: FC<ActiveInvestmentsProps> = ({
  data,
  categoryTab,
  setCategoryTab,
}) => {
  return (
    <div className="pt-6">
      <div className="border-b-[1.5px] border-transactionCard pb-5">
        <div className="flex text-[12px] font-PoppinsMedium justify-between items-center gap-10 pt-6 px-[3%]">
          <div
            onClick={() => setCategoryTab(null)}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === null ? "bg-primary text-white" : ""
            }`}
          >
            All
          </div>
          <div
            onClick={() => setCategoryTab("Fixed Income")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Fixed Income" ? "bg-primary text-white" : ""
            }`}
          >
            Fixed Income
          </div>
          <div
            onClick={() => setCategoryTab("Real Estate")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Real Estate" ? "bg-primary text-white" : ""
            }`}
          >
            Real Estate
          </div>
          <div
            onClick={() => setCategoryTab("Agriculture")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Agriculture" ? "bg-primary text-white" : ""
            }`}
          >
            Agriculture
          </div>
          <div
            onClick={() => setCategoryTab("Transportation")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Transportation" ? "bg-primary text-white" : ""
            }`}
          >
            Transportation
          </div>
        </div>
      </div>
      <div>
        {data?.map((item, index) => (
          <div
            key={index}
            className="border-y-[1.5px] cursor-pointer w-full border-transactionCard dark:border-textcolor mb-2 py-2 dark:shadow-[#000000] shadow-transactionCard shadow-sm"
          >
            <div className="flex items-center gap-[50px] px-[3%]">
              <div className="w-[200px] h-[150px] relative">
                <img
                  src={item.previewImage}
                  className="w-full h-full object-cover rounded-[10px]"
                  alt="investment-img"
                />
              </div>
              <div>
                <h1 className="font-PoppinsSemiBold uppercase text-[18px] pb-2">
                  {item.title}
                </h1>
                <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                  {`${item.rate}% `}
                  <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                    {`returns in ${item.durationNumber} ${item.durationInterval}`}
                  </span>
                </h1>
                <div className="py-2">
                  <div>
                    <h1 className=" font-PoppinsSemiBold text-[12px] text-primary">
                      {formatCurrency(item.amount)}
                    </h1>
                    <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                      per unit
                    </h1>
                  </div>
                </div>
                <div className="bg-opacity-[28%] dark:bg-opacity-[28%] dark:text-[#C8A2C8] dark:bg-[#C8A2C8] text-primary bg-primary inline-block py-1 px-4 rounded-[10px] font-PoppinsMedium text-[10px]">
                  Matured
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MaturedInvestment;
