import SLogo from "../../assets/img/affluence-logo-small.png";
import Logo from "../../assets/img/affluence-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaPowerOff,
} from "react-icons/fa";
import { PiUsersThree, PiHandDeposit, PiHandWithdraw } from "react-icons/pi";
import { MdOutlineDashboardCustomize, MdOutlineSavings } from "react-icons/md";
import { GoBriefcase } from "react-icons/go";
import { GrTransaction } from "react-icons/gr";
import { AiOutlineAudit } from "react-icons/ai";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { VscSettings } from "react-icons/vsc";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

const Sidebar = () => {
  const [largeSideBar, setLargeSideBar] = useState(false);
  const navigate = useNavigate();
  const router = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSetLargeSideBar = () => {
    setLargeSideBar((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleDropdownToggle = (index: number) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    e.preventDefault();
    navigate(e.currentTarget.getAttribute("href") || "/");
  };

  const sidebarLinks = [
    {
      to: "/admin/home",
      label: "Dashboard",
      icon: <MdOutlineDashboardCustomize size={24} />,
    },
    {
      to: "/admin/allCustomers",
      label: "Customers",
      icon: <PiUsersThree size={24} />,
    },
    {
      to: "/admin/deposits",
      label: "Deposit",
      icon: <PiHandDeposit size={24} />,
    },
    {
      to: "/admin/payouts",
      label: "Payout",
      icon: <PiHandWithdraw size={24} />,
    },
    {
      to: "/admin/savings",
      label: "Savings",
      icon: <MdOutlineSavings size={24} />,
    },
    {
      to: "#",
      label: "Investment",
      icon: <GoBriefcase size={24} />,
      submenu: [
        { to: "/admin/investment/investment_plan", label: "Investment Plan" },
        { to: "/admin/investment/investment_log", label: "Investment Log" },
        { to: "/admin/investment/add_category", label: "Investment Category" },
      ],
    },
    {
      to: "#",
      label: "Transaction",
      icon: <GrTransaction size={24} />,
      submenu: [
        { to: "/admin/transactions/transaction_log", label: "Transaction Log" },
        { to: "/admin/transactions/payment_gateway", label: "Payment Gateway" },
      ],
    },
    {
      to: "/admin/audit",
      label: "Audit Log",
      icon: <AiOutlineAudit size={24} />,
    },
    {
      to: "/admin/promotional_emails",
      label: "Promotional Emails",
      icon: <SiAmazonsimpleemailservice size={24} />,
    },
    {
      to: "/admin/configuration",
      label: "Configuration",
      icon: <VscSettings size={24} />,
    },
  ];

  return (
    <div
      className={`border-r-[1.5px] border-opacity-30 ${
        largeSideBar ? "w-[80px]" : "minixlg:w-[20%] w-[25%]"
      }  relative border-placeholdercolor md:block hidden overflow-y-auto`}
    >
      <div
        onClick={handleSetLargeSideBar}
        className="text-primary absolute cursor-pointer right-[-4px] top-10"
      >
        {largeSideBar ? (
          <FaArrowAltCircleRight size={30} />
        ) : (
          <FaArrowAltCircleLeft size={30} />
        )}
      </div>
      <div className="flex flex-col justify-between h-full pt-[4rem]">
        <div>
          <div className="flex justify-center">
            <img
              src={largeSideBar ? SLogo : Logo}
              className={largeSideBar ? "w-[30px]" : "w-[170px]"}
              alt="affluence-logo"
            />
          </div>
          <div className="pt-[1rem]">
            {sidebarLinks.map((link, index) => (
              <div key={index}>
                {link.submenu ? (
                  <div>
                    <button
                      onClick={() => handleDropdownToggle(index)}
                      className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular rounded-[8px] py-3 px-4 ${
                        router.pathname.startsWith(link.to)
                          ? "bg-primary text-white"
                          : "text-textcolor"
                      }`}
                    >
                      {link.icon}
                      <span
                        className={`ml-2 ${largeSideBar ? "hidden" : "block"}`}
                      >
                        {link.label}
                      </span>
                      {openDropdown === index ? (
                        <IoIosArrowForward size={20} className="ml-auto" />
                      ) : (
                        <IoIosArrowDown size={20} className="ml-auto" />
                      )}
                    </button>
                    {openDropdown === index && (
                      <div className="pl-4">
                        {link.submenu.map((subLink) => (
                          <Link
                            key={subLink.to}
                            to={subLink.to}
                            className={`block py-3 px-4 ${
                              router.pathname === subLink.to
                                ? "bg-primary text-white font-PoppinsLight"
                                : "text-textcolor font-PoppinsLight"
                            }`}
                            onClick={(event) => {
                              if (!(event.target instanceof HTMLElement))
                                return;
                              if (!event.target.closest("a")) {
                                handleDropdownToggle(index);
                              }
                            }}
                          >
                            {subLink.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    key={link.to}
                    to={link.to}
                    className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 ${
                      router.pathname.startsWith(link.to)
                        ? "bg-primary text-white"
                        : "text-textcolor"
                    }`}
                  >
                    {link.icon}
                    <span
                      className={`ml-2 ${largeSideBar ? "hidden" : "block"}`}
                    >
                      {link.label}
                    </span>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="flex mt-[7rem] items-center text-error gap-3 mb-4 text-[1.15em] font-PoppinsRegular py-3 px-4"
        >
          <FaPowerOff size={24} />
          <span className={`${largeSideBar ? "hidden" : "block"}`}>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
