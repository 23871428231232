import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../../../components/ToastComponent";
import { HttpClient } from "../../../api/HttpClient";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import TextInput from "../../../components/TextInput";
import TextAreaInput from "../../../components/TextAreaInput";
import FileInput from "../../../components/FileInput";
import CreateBtn from "./CreateBtn";
import ErrorHandler from "../../ErrorHandler";
import { format } from "path";

interface InvestData {
  _id?: string;
  key?: string;
  investmentTitle: string;
  investmentDescription: string;
  investmentDisclaimer: string;
  investmentCategory: string;
  investmentRate: string;
  investmentDurationNumber: string;
  investmentDurationInterval: string;
  investmentUnits: string;
  investmentAmount: string;
  investmentStartDate: string;
  investmentEndDate: string;
  investmentClaim: string;
  investmentStatus: "pending";
  image: File | null;
}

type MyComponentProps = {
  onBack?: () => void;
  record?: InvestData;
  onSubmit?: (formData: InvestData) => void;
};

const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

const validationSchema = Yup.object({
  investmentTitle: Yup.string().required("Title is required"),
  investmentDescription: Yup.string().required("Description is required"),
  investmentDisclaimer: Yup.string(),
  investmentCategory: Yup.string().required("Category is required"),
  investmentRate: Yup.number()
    .min(1, "Rate must not be less than 1")
    .max(100, "Rate must not be greater than 100")
    .required("Rate is required"),
  investmentDurationNumber: Yup.number()
    .min(1, "Duration number must not be less than 1")
    .max(366, "Rate must not be greater than 366")
    .required("Duration Number is required"),
  investmentDurationInterval: Yup.string().required(
    "Duration Interval is required"
  ),
  investmentUnits: Yup.number()
    .min(1, "Unit must not be less than 1")
    .max(50000, "Unit must not be greater than 50000")
    .required("Unit is required"),
  investmentAmount: Yup.number()
    .min(1000, "Amount must not be less than 1000")
    .max(100000, "Amount must not be greater than 100000")
    .required("Amount is required"),
  investmentStartDate: Yup.string().required("Start Date is required"),
  investmentEndDate: Yup.string().required("End Date is required"),
  investmentClaim: Yup.string().required("Claim is required"),
  investmentStatus: Yup.string().required("Status is required"),
  image: Yup.mixed().nullable().required("Image is required"),
});

const CreatePlan: React.FC<MyComponentProps> = ({
  onBack,
  record,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [isImageSelected, setIsImageSelected] = useState<boolean>(true);

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    field: string
  ) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^0-9.]/g, "");
    setFieldValue(field, filteredValue);
  };

  const handleSubmit = async (
    values: InvestData,
    { setSubmitting, setErrors, resetForm }: any
  ) => {
    const parsedValues = {
      ...values,
      investmentRate: parseFloat(values.investmentRate) || 0,
      investmentDurationNumber:
        parseInt(values.investmentDurationNumber, 10) || 0,
      investmentUnits: parseInt(values.investmentUnits, 10) || 0,
      investmentAmount: parseFloat(values.investmentAmount) || 0,
    };

    const investData = new FormData();
    for (const key in values) {
      if (key === "image" && values.image) {
        investData.append("investmentPreviewImage", values.image);
      } else {
        investData.append(
          key,
          values[key as keyof InvestData] as string | Blob
        );
      }
    }

    try {
      const url = "/investments/plan";
      const token = localStorage.getItem("token");

      const res = await HttpClient(url, {
        method: "POST",
        headers: {
          "x-auth-token": token,
          "Content-Type": "multipart/form-data",
        },
        data: investData,
      });

      if (!res) {
        ToastComponent.error(
          "Failed to submit investment plan. Please try again."
        );
      } else {
        ToastComponent.success(
          res.data.message || "Investment plan submitted successfully!"
        );
        navigate("/admin/investment/investment_plan");
        resetForm();
        setIsImageSelected(false);
      }
      navigate("/admin/investment/investment_plan");
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="h-full flex">
      <div className=" flex-grow flex flex-col overflow-y-auto bg-gray-100 p-8 w-full md:w-3/4">
        <Formik
          initialValues={{
            investmentTitle: record?.investmentTitle || "",
            investmentDescription: record?.investmentDescription || "",
            investmentDisclaimer: record?.investmentDisclaimer || "",
            investmentCategory: record?.investmentCategory || "",
            investmentRate: record?.investmentRate || "",
            investmentDurationNumber: record?.investmentDurationNumber || "",
            investmentDurationInterval:
              record?.investmentDurationInterval || "",
            investmentUnits: record?.investmentUnits || "",
            investmentAmount: record?.investmentAmount || "",
            investmentStartDate: record?.investmentStartDate
              ? formatDateString(record?.investmentStartDate)
              : formatDateString(new Date().toISOString().split("T")[0]) || "",
            investmentEndDate: record?.investmentEndDate
              ? formatDateString(record?.investmentEndDate)
              : formatDateString(new Date().toISOString().split("T")[0]) || "",
            investmentClaim: record?.investmentClaim || "",
            investmentStatus: record?.investmentStatus || "pending",
            image: record?.image || null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isSubmitting, isValid, dirty }) => (
            <Form className="w-full">
              <div className="mb-4">
                <label
                  htmlFor="investmentTitle"
                  className="block text-primary font-PoppinsLight text-[14px] mb-1"
                >
                  Title
                </label>
                <TextInput
                  type="text"
                  name="investmentTitle"
                  placeholder="Enter investment title"
                  className="w-full h-12 border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="investmentDescription"
                  className="block text-primary font-PoppinsLight text-[14px] mb-1"
                >
                  Description
                </label>
                <TextAreaInput
                  as="textarea"
                  name="investmentDescription"
                  placeholder="Enter Investment Description"
                  className="w-full border border-transactionCard font-PoppinsRegular h-40 px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="investmentDisclaimer"
                  className="block text-primary font-PoppinsLight text-[14px] mb-1"
                >
                  Disclaimer
                </label>
                <TextAreaInput
                  as="textarea"
                  name="investmentDisclaimer"
                  placeholder="Enter Investment Disclaimer"
                  className="w-full border border-transactionCard font-PoppinsRegular h-40 px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="investmentCategory"
                  className="block text-primary font-PoppinsLight text-[14px] mb-1"
                >
                  Category
                </label>
                <Field
                  as="select"
                  name="investmentCategory"
                  className="w-full border border-transactionCard font-PoppinsLight h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  <option value="Fixed Income">Fixed Income</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Transportation">Transportation</option>
                </Field>
                <ErrorMessage
                  name="investmentCategory"
                  component="div"
                  className="font-PoppinsLight text-[13px] text-error"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="investmentRate"
                  className="block text-primary font-PoppinsLight text-[14px] mb-1"
                >
                  Rate
                </label>
                <TextInput
                  type="number"
                  name="investmentRate"
                  placeholder="10"
                  className="w-full border border-transactionCard font-PoppinsRegular h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                  onChange={(e) =>
                    handleNumberChange(e, setFieldValue, "investmentRate")
                  }
                />
              </div>

              <div className="flex gap-4 mb-4">
                <div className="w-full">
                  <label
                    htmlFor="investmentDurationNumber"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Duration Number
                  </label>
                  <TextInput
                    type="number"
                    name="investmentDurationNumber"
                    placeholder="10"
                    className="w-full border border-transactionCard focus:outline-none focus:border-primary font-PoppinsRegualr h-12 px-2 rounded-lg"
                    onChange={(e) =>
                      handleNumberChange(
                        e,
                        setFieldValue,
                        "investmentDurationNumber"
                      )
                    }
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="investmentDurationInterval"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Duration Interval
                  </label>
                  <Field
                    as="select"
                    name="investmentDurationInterval"
                    className="w-full border border-transactionCard font-PoppinsLight h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                  >
                    <option value="" disabled>
                      Select Interval
                    </option>
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                  </Field>
                  <ErrorMessage
                    name="investmentDurationInterval"
                    component="div"
                    className="text-error text-[13px] font-PoppinsLight"
                  />
                </div>
              </div>

              <div className="flex gap-4 mb-4">
                <div className="w-full">
                  <label
                    htmlFor="investmentUnits"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Units
                  </label>
                  <TextInput
                    type="number"
                    name="investmentUnits"
                    placeholder="10"
                    className="w-full border border-transactionCard font-PoppinsLight h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                    onChange={(e) =>
                      handleNumberChange(e, setFieldValue, "investmentUnits")
                    }
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="investmentAmount"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Amount Per Unit
                  </label>
                  <TextInput
                    type="number"
                    name="investmentAmount"
                    placeholder="1000"
                    className="w-full border border-transactionCard font-PoppinsLight h-12 pl-8 pr-2 rounded-lg focus:outline-none focus:border-primary"
                    onChange={(e) =>
                      handleNumberChange(e, setFieldValue, "investmentAmount")
                    }
                  />
                </div>
              </div>

              <div className="flex gap-4 mb-4">
                <div className="w-full">
                  <label
                    htmlFor="investmentStartDate"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Start Date
                  </label>
                  <TextInput
                    type="date"
                    name="investmentStartDate"
                    className="w-full border border-transactionCard font-PoppinsLight h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="investmentEndDate"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    End Date
                  </label>
                  <TextInput
                    type="date"
                    name="investmentEndDate"
                    className="w-full border border-transactionCard font-PoppinsRegular h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                  />
                </div>
              </div>

              <div className="flex gap-4 mb-4">
                <div className="w-full">
                  <label
                    htmlFor="investmentClaim"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Claim
                  </label>
                  <Field
                    as="select"
                    name="investmentClaim"
                    className="w-full border border-transactionCard font-PoppinsLight h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                  >
                    <option value="" disabled>
                      Select Claim
                    </option>
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </Field>
                  <ErrorMessage
                    name="investmentDurationInterval"
                    component="div"
                    className="text-error text-[13px] font-PoppinsLight"
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="investmentStatus"
                    className="block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Status
                  </label>
                  <Field
                    as="select"
                    name="investmentStatus"
                    className="w-full border border-transactionCard font-PoppinsLight h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    <option value="pending">Pending</option>
                    <option value="running">Running</option>
                  </Field>
                  <ErrorMessage
                    name="investmentStatus"
                    component="div"
                    className="text-error text-[13px] font-PoppinsLight"
                  />
                </div>
              </div>

              <div className="flex justify-center items-center mb-4">
                <FileInput
                  label="Kindly Upload Investment Image"
                  name="image"
                  className="w-full border-2 border-dashed p-4 text-center cursor-pointer border-primary rounded-lg"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file = event.target.files?.[0];
                    setFieldValue("image", file);
                    setIsImageSelected(!!file);
                  }}
                />
                <ErrorMessage
                  name="image"
                  component="div"
                  className="text-error text-[13px] font-PoppinsLight"
                />
              </div>

              <div className="flex justify-start mt-8 gap-4">
                <button
                  type="reset"
                  className="py-2 px-6 border border-error text-error font-PoppinsMedium rounded-lg"
                >
                  Cancel
                </button>
                <CreateBtn
                  type="submit"
                  className="py-2 px-6 border border-success text-success font-PoppinsMedium rounded-lg"
                  isImageSelected={isImageSelected}
                  disabled={!isValid || !dirty}
                >
                  {record ? "Update" : "Submit"}
                </CreateBtn>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreatePlan;
