"use client";

import { ErrorMessage, Field } from "formik";
import { FC } from "react";

interface TextInputProps {
  name: string;
  type?: string;
  placeholder?: string;
  as?: string;
  className?: string;
}
const TextAreaInput: FC<TextInputProps> = ({ name, type, placeholder }) => {
  return (
    <div className="pb-4">
      <Field
        as="textarea"
        rows={4}
        name={name}
        type={type}
        placeholder={placeholder}
        className="sm:p-[0.8rem] p-[0.6rem] resize-none w-full text-textcolor dark:text-white dark:border-placeholdercolor focus:border-primary dark:bg-darkPrimary placeholder:text-placeholdercolor text-[14px] outline-none border-[1.5px] rounded-[8px] border-authbordercolor"
      ></Field>
      <ErrorMessage
        name={name}
        component="div"
        className="text-error text-[13px] font-PoppinsLight"
      />
    </div>
  );
};

export default TextAreaInput;
