"use client";

import { ErrorMessage, Field, useField } from "formik";
import { FC, useEffect, useState } from "react";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

interface PasswordInputProps {
  name: string;
  type: string;
  placeholder?: string;
  label?: string;
  updatePass?: string;
  className?: string;
}
const PasswordInput: FC<PasswordInputProps> = ({
  name,
  placeholder,
  type,
  label,
  updatePass,
  ...props
}) => {
  const [seePassword, setSeePassword] = useState(false);
  const [field, meta] = useField({ name, type });
  useEffect(() => {
    if (meta.error) {
      field.onChange({ target: { value: "" } });
    }
  }, [meta.error, field]);
  return (
    <>
      <div className="pb-4 relative">
        <label className="font-PoppinsMedium text-[14px]" htmlFor={name}>
          {label}
        </label>
        <Field
          {...field}
          name={name}
          type={seePassword ? "text" : "password"}
          placeholder={placeholder}
          className="sm:p-3 p-2.5 w-full bg-white dark:bg-darkPrimary text-textcolor dark:text-white focus:border-primary placeholder:text-placeholdercolor text-[14px] outline-none border-[1.5px] rounded-[8px] border-authbordercolor"
        ></Field>
        <div
          className={`absolute ${
            updatePass ? updatePass : "sm:top-3.5 top-3 right-3.5"
          }  text-textcolor dark:text-white cursor-pointer`}
          onClick={() => setSeePassword((prev) => !prev)}
        >
          {seePassword ? <VscEyeClosed size={23} /> : <VscEye size={23} />}
        </div>
        <ErrorMessage
          name={name}
          component="div"
          className="text-error text-[13px] font-PoppinsLight"
        />
      </div>
    </>
  );
};

export default PasswordInput;



