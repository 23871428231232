import { useCallback, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { ScaleLoader } from "react-spinners";
import { Input, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import PageTitle from "../../components/Pagetitle";
import { HttpClient } from "../../api/HttpClient";
import { format } from "date-fns";
import ErrorHandler from "../ErrorHandler";

interface TransactionDetails {
  id: number;
  tx_ref: string;
  flw_ref: string;
  device_fingerprint: string;
  amount: number;
  currency: string;
  charged_amount: number;
  app_fee: number;
  merchant_fee: number;
  processor_response: string;
  auth_model: string;
  ip: string;
  narration: string;
  status: string;
  payment_type: string;
  created_at: string;
  account_id: number;
  meta: {
    __CheckoutInitAddress: string;
    customerId: string;
    originatoraccountnumber: string;
    bankname: string;
  };
  amount_settled: number;
  customer: {
    id: number;
    name: string;
    phone_number: string;
    email: string;
    created_at: string;
  };
}

interface WithdrawalsData {
  _id?: string;
  key?: string;
  transactionDate: string;
  paymentGateway: string;
  transactionId: string;
  amount: number;
  paymentStatus: string;
  paymentMethod: string;
  transactionDetails: TransactionDetails;
  transactionType: string;
}

function Payouts() {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<WithdrawalsData[]>([]);
  const [withdrawals, setWithdrawals] = useState<WithdrawalsData[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    transactionId: "",
  });

  const applyFilters = useCallback(
    (data: WithdrawalsData[]) => {
      let filtered = data;

      if (filters.name) {
        filtered = filtered.filter((item) => {
          return item.transactionDetails.customer.name
            .toLowerCase()
            .includes(filters.name.toLowerCase());
        });
      }
      if (filters.email) {
        filtered = filtered.filter((item) => {
          return item.transactionDetails.customer.email
            .toLowerCase()
            .includes(filters.email.toLowerCase());
        });
      }
      if (filters.transactionId) {
        filtered = filtered.filter((item) => {
          return item.transactionId
            .toLowerCase()
            .includes(filters.transactionId.toLowerCase());
        });
      }

      setFilteredData(filtered);
      setPagination((prev) => ({
        ...prev,
        total: filtered.length,
      }));
    },
    [filters]
  );

  const fetchWithdrawalsData = useCallback(async () => {
    try {
      const withdrawalsRes = await HttpClient.get(`/transactions/history`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: "",
          limit: "",
          search: "withdrawals",
        },
      });

      const withdrawalsData = withdrawalsRes.data.data.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          name: item?.transactionDetails?.customer?.name || "",
          email: item?.transactionDetails?.customer?.email || "",
          transactionDate: item?.transactionDate || "",
          paymentGateway: item?.paymentGateway || "",
          transactionId: item?.transactionId || "",
          amount: item?.amount || "0",
          paymentStatus: item?.paymentStatus || "",
          transactionDetails: item?.transactionDetails,
          transactionType: item?.transactionType || "",
          paymentMethod: item?.paymentMethod || "",
        })
      );

      setWithdrawals(withdrawalsData);
      setPagination((prev) => ({
        ...prev,
        total: withdrawalsRes.data.data.total,
      }));
      applyFilters(withdrawalsData);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [applyFilters]);

  useEffect(() => {
    fetchWithdrawalsData();
  }, [fetchWithdrawalsData]);

  useEffect(() => {
    applyFilters(withdrawals);
  }, [filters, applyFilters, withdrawals]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const columns: ColumnsType<WithdrawalsData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      className: "text-textcolor font-PoppinsRegular",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      className: "capitalize text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="John Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(withdrawals),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      className: "text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="johndoe@example.com"
          value={filters.email}
          onChange={(e) => handleFilterChange("email", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(withdrawals),
    },
    {
      key: "paymentGateway",
      title: "Payment Gateway",
      dataIndex: "paymentGateway",
      className: "capitalize text-textcolor font-PoppinsRegular",
    },
    {
      key: "paymentMethod",
      title: " Payment Method",
      dataIndex: "paymentMethod",
      className: "text-textcolor font-PoppinsRegular capitalize",
    },
    {
      title: "Transaction ID",
      key: "transactionId",
      dataIndex: "transactionId",
      className: "text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="12345"
          value={filters.transactionId}
          onChange={(e) => handleFilterChange("transactionId", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(withdrawals),
    },
    {
      key: "transactionType",
      title: "Transaction Type",
      dataIndex: "transactionType",
      className: "text-textcolor font-PoppinsRegular capitalize",
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      className: "text-textcolor font-PoppinsRegular",
      render: (amount: string) => `₦${Number(amount).toLocaleString()}`,
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (status: string) => {
        const baseClass =
          "text-[0.8em] capitalize rounded-[15px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] text-center inline-block";
        const statusClass =
          status === "successful"
            ? `bg-success text-success ${baseClass}`
            : status === "failed"
            ? `bg-error text-error ${baseClass}`
            : `bg-secondary text-secondary ${baseClass}`;

        return <div className={statusClass}>{status}</div>;
      },
    },
  ];

  return (
    <>
      <PageTitle title="Affluence - Admin: Withdrawals Log" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="p-4 mt-28">
              <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                <span className="text-semibold text-textcolor font-PoppinsBold block mb-4">
                  All Withdrawals
                </span>
                <div className="overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{
                      ...pagination,
                      onChange: (page, pageSize) => setPagination({ ...pagination, current: page, pageSize }),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Payouts;
