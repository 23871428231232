"use client";

import { ErrorMessage, Field } from "formik";
import { FC } from "react";

interface TextInputProps {
  name: string;
  type: string;
  placeholder?: string;
  label: string;
  readOnly?: boolean;
  errorMsgVisible?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccountNumberInput: FC<TextInputProps> = ({
  name,
  type,
  placeholder,
  label,
  readOnly,
  errorMsgVisible = true,
  onChange,
}) => {
  const isNumber = type === "number";
  return (
    <div className="pb-4">
      <label className="font-PoppinsMedium text-[14px]" htmlFor={name}>
        {label}
      </label>
      <Field
        name={name}
        type={type}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={onChange}
        className={`sm:p-3 p-2.5 w-full text-textcolor dark:text-white dark:bg-darkPrimary focus:border-primary placeholder:text-placeholdercolor text-[14px] outline-none border-[1.5px] rounded-[8px] border-authbordercolor ${
          isNumber ? "no-arrows" : ""
        }`}
      ></Field>
      {errorMsgVisible && (
        <ErrorMessage
          name={name}
          component="div"
          className="text-error text-[13px] font-PoppinsLight"
        />
      )}
    </div>
  );
};

export default AccountNumberInput;
