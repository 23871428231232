import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { ScaleLoader } from "react-spinners";
import { Input, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import PageTitle from "../../../components/Pagetitle";
import { HttpClient } from "../../../api/HttpClient";
import { format } from "date-fns";
import Modal from "../../../components/Modal";
import ErrorHandler from "../../ErrorHandler";

interface TransactionDetails {
  id: number;
  tx_ref: string;
  flw_ref: string;
  device_fingerprint: string;
  amount: number;
  currency: string;
  charged_amount: number;
  app_fee: number;
  merchant_fee: number;
  processor_response: string;
  auth_model: string;
  ip: string;
  narration: string;
  status: string;
  payment_type: string;
  created_at: string;
  account_id: number;
  meta: {
    __CheckoutInitAddress: string;
    customerId: string;
    originatoraccountnumber: string;
    bankname: string;
  };
  amount_settled: number;
  customer: {
    id: number;
    name: string;
    phone_number: string;
    email: string;
    created_at: string;
  };
}

interface PaymentGatewayData {
  _id?: string;
  key?: string;
  transactionDate: string;
  paymentGateway: string;
  transactionId: string;
  amount: number;
  paymentStatus: string;

  name: string;
  email: string;
  transactionDetails: TransactionDetails;
  transactionType: string;
}

function PaymentGateway() {
  const [loading, setLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] =
    useState<TransactionDetails | null>(null);
  const [payGateway, setPayGateway] = useState<PaymentGatewayData[]>([]);
  const [filteredData, setFilteredData] = useState<PaymentGatewayData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<PaymentGatewayData | null>(null);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    paymentGateway: "",
    transactionId: "",
  });

  const fetchGatewaysData = async () => {
    try {
      const payGatewayRes = await HttpClient.get(`/transactions/history`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: "",
          limit: "",
        },
      });

      const paymentGateways = payGatewayRes.data.data.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          name: item?.transactionDetails?.customer?.name || "",
          email: item?.transactionDetails?.customer?.email || "",
          transactionDate: item?.transactionDate || "",
          paymentGateway: item?.paymentGateway || "",
          transactionId: item?.transactionId || "",
          amount: item?.amount || "0",
          paymentStatus: item?.paymentStatus || "",
          transactionType: item.transactionType || "",
          transactionDetails: item.transactionDetails,
        })
      );

      setPayGateway(paymentGateways);
      setPagination((prev) => ({
        ...prev,
        total: payGatewayRes.data.data.total,
      }));
      applyFilters(paymentGateways);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };
  const applyFilters = (data: PaymentGatewayData[]) => {
    let filtered = data;

    if (filters.name) {
      filtered = filtered.filter((item) =>
        item.transactionDetails.customer.name
          .toLowerCase()
          .includes(filters.name.toLowerCase())
      );
    }
    if (filters.email) {
      filtered = filtered.filter((item) =>
        item.transactionDetails.customer.email
          .toLowerCase()
          .includes(filters.email.toLowerCase())
      );
    }
    if (filters.paymentGateway) {
      filtered = filtered.filter((item) =>
        item.paymentGateway
          .toLowerCase()
          .includes(filters.paymentGateway.toLowerCase())
      );
    }
    if (filters.transactionId) {
      filtered = filtered.filter((item) =>
        item.transactionId
          .toLowerCase()
          .includes(filters.transactionId.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setPagination((prev) => ({
      ...prev,
      total: filtered.length,
    }));
  };

  useEffect(() => {
    fetchGatewaysData();
  }, []);

  useEffect(() => {
    applyFilters(payGateway);
  }, [filters]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleTransactionClick = async (transactionId: string) => {
    try {
      const paymentRes = await HttpClient.get(
        `/payments/flutterwave/verify-transaction/${transactionId}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      setPaymentDetails(paymentRes.data.data);
      setIsModalVisible(true);
    } catch (error: any) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTransaction(null);
  };

  const columns: ColumnsType<PaymentGatewayData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      className: "text-textcolor font-PoppinsRegular",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      className: "capitalize text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="John Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      className: "text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="johndoe@example.com"
          value={filters.email}
          onChange={(e) => handleFilterChange("email", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "paymentGateway",
      title: "Payment Gateway",
      dataIndex: "paymentGateway",
      className: "capitalize text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="Flutterwave"
          value={filters.paymentGateway}
          onChange={(e) => handleFilterChange("paymentGateway", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      title: "Transaction ID",
      key: "transactionId",
      dataIndex: "transactionId",
      className: "text-investment font-PoppinsRegular cursor-pointer",
      render: (transactionId: string) => (
        <span onClick={() => handleTransactionClick(transactionId)}>
          {transactionId}
        </span>
      ),
      filterDropdown: () => (
        <Input
          placeholder="Search Transaction ID"
          value={filters.transactionId}
          onChange={(e) => handleFilterChange("transactionId", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      className: "text-textcolor font-PoppinsRegular",
      render: (amount: string) => `₦${Number(amount).toLocaleString()}`,
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (status: string) => {
        const baseClass =
          "text-[0.8em] capitalize rounded-[15px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] text-center inline-block";
        const statusClass =
          status === "successful"
            ? `bg-success text-success ${baseClass}`
            : status === "failed"
            ? `bg-error text-error ${baseClass}`
            : `bg-secondary text-secondary ${baseClass}`;

        return <div className={statusClass}>{status}</div>;
      },
    },
  ];

  return (
    <>
      <PageTitle title="Affluence - Admin: Transaction Log" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="p-4 mt-28">
              <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                <span className="text-semibold text-textcolor font-PoppinsBold block mb-4">
                  Payment Gateway
                </span>
                <div className="overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal isOpenModal={isModalVisible} closeModal={handleCancel}>
        {isModalVisible && paymentDetails && (
          <div className="text-textcolor p-4 mt-28">
            <div
              className="text-textcolor font-PoppinsSemiBold mb-4"
              style={{
                display: "inline-block",
                width: "100%",
                borderBottom: "1px solid text-textcolor",
              }}
            >
              <p style={{ margin: 0 }}>Payment Details</p>
            </div>

            <p className="font-PoppinsSemiBold mb-2">
              Name:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.customer?.name}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Email:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.customer?.email}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Phone Number:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.customer?.phone_number}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Bank Name:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.meta?.bankname}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Account Number:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.meta?.originatoraccountnumber}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Amount:{" "}
              <span className="font-PoppinsLight">
                ₦{paymentDetails.amount}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Status:{" "}
              <span className="capitalize font-PoppinsLight">
                {paymentDetails?.status}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Payment Type:{" "}
              <span className="font-PoppinsLight capitalize">
                {paymentDetails?.payment_type
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Transaction Ref:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.tx_ref}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Narration:{" "}
              <span className="font-PoppinsLight">
                {paymentDetails?.narration}
              </span>
            </p>
            <p className="font-PoppinsSemiBold mb-2">
              Date:{" "}
              <span className="font-PoppinsLight">
                {new Date(paymentDetails?.created_at).toLocaleString()}
              </span>
            </p>
          </div>
        )}
      </Modal>
    </>
  );
}

export default PaymentGateway;
