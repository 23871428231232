"use client";

import { ErrorMessage, Field } from "formik";
import { FC } from "react";

interface TextInputProps {
  name: string;
  type: string;
  placeholder?: string;
  className?: string;
  onInput?: (e: any) => void;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}
const LightAuthTextInput: FC<TextInputProps> = ({
  name,
  type,
  placeholder,
}) => {
  return (
    <div className="pb-4">
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        className="sm:p-3 p-2.5 w-full text-textcolor focus:border-primary placeholder:text-placeholdercolor text-[14px] outline-none border-[1.5px] rounded-[8px] border-authbordercolor"
      ></Field>
      <ErrorMessage
        name={name}
        component="div"
        className="text-error text-[13px] font-PoppinsLight"
      />
    </div>
  );
};

export default LightAuthTextInput;
