import { GrClose } from "react-icons/gr";

const Modal = ({ children, isOpenModal, closeModal }: any) => {
  if (!isOpenModal) {
    return null;
  }
  return (
    <div className="fixed inset-0 bg-[black] bg-opacity-[50%] flex items-center justify-end z-50">
      <div className="bg-white dark:bg-darkPrimary h-full w-full max-w-[500px] overflow-y-auto relative pb-10">
        <div className="flex justify-end py-9 pr-5 fixed top right-0 z-30 max-w-[500px] bg-white dark:bg-darkPrimary shadow-sm dark:shadow-textcolor border border-authbordercolor w-full">
          <button
            onClick={closeModal}
            className="focus:outline-none dark:text-white"
          >
            <GrClose size={24} />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
