import { ScaleLoader } from "react-spinners";
import PageTitle from "../../../components/Pagetitle";
import Sidebar from "../Sidebar";
import { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../../api/HttpClient";
import ErrorHandler from "../../ErrorHandler";
import { useParams } from "react-router-dom";

interface UserDetails {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface InvestmentDetails {
  title: string;
}
interface Investment {
  _id: string;
  user_details: UserDetails;
  investment_details: InvestmentDetails;
}

function ViewInvestors() {
  const [loading, setLoading] = useState(true);
  const { investmentId } = useParams();
  const [viewInvestors, setViewInvestors] = useState<Investment[]>([]);
  const [investmentTitle, setInvestmentTitle] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvestmentDetails = async () => {
      if (!investmentId) {
        console.error("Investment ID is not available");
        return;
      }

      try {
        const listInvestRes = await HttpClient.get(`/investments/booking`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: { investmentId },
        });

        const investHis = listInvestRes.data.data.map(
          (item: any, index: number) => ({
            key: item._id,
            serialNumber: index + 1,
            name:
              `${item?.user_details?.firstName} ${item?.user_details?.lastName}` ||
              "",
            email: item?.user_details?.email || "",
            phone: item?.user_details?.phone || "",
            title: item?.investment_details?.title,
            userId: item?.user_details?._id,
          })
        );

        setViewInvestors(investHis);
        setInvestmentTitle(investHis.title);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvestmentDetails();
  }, [investmentId]);

  const handleNavigateToInvestmentPlan = () => {
    navigate("/admin/investment/investment_plan");
  };

  const handleRowClick = (record: any) => {
    navigate(`/admin/allCustomers/oneCustomer/${record.userId}`); // Navigate to the investor's page
  };

  const viewColumns: ColumnsType = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },

    {
      title: "Names of Investors",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      key: "phone",
      dataIndex: "phone",
    },
  ];

  return (
    <>
      <PageTitle title="Investment Category" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="flex-1 p-4 mt-28 shadow-md border border-transactionCard rounded-lg mx-4">
              <div className="flex justify-between">
                <span className="text-semibold text-textcolor font-PoppinsBold block mb-4">
                  {investmentTitle || "Investment Details"}
                </span>
                <button
                  className="border text-center cursor-pointer border-primary text-primary px-4 py-2 rounded-md align-middle"
                  onClick={handleNavigateToInvestmentPlan}
                >
                  <IoArrowBackOutline size={20} className="inline mr-2" />
                  Back to Investment Plan
                </button>
              </div>
              <div className="overflow-x-auto">
                <Table
                  dataSource={viewInvestors}
                  columns={viewColumns}
                  className="mt-6"
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                    className: "cursor-pointer",
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewInvestors;
