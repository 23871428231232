export const highlightText = (text: any, query: any) => {
  if (!query) return text;

  const parts = text.split(new RegExp(`(${query})`, "gi"));
  return parts.map((part: any, index: any) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="text-white bg-primary">
        {part}
      </span>
    ) : (
      part
    )
  );
};
