import React, { useState } from "react";
import { useField, ErrorMessage } from "formik";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface CustomSelectProps {
  name: string;
  options: { value: string; label: string }[];
  placeholder: string;
}

const SelectInput: React.FC<CustomSelectProps> = ({
  name,
  options,
  placeholder,
}) => {
  const [field, meta, helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value: string) => {
    helpers.setValue(value);
    setIsOpen(false);
  };

  const isPlaceholder = !field.value;

  return (
    <div className="relative pb-4">
      <div className="relative" onClick={() => setIsOpen(!isOpen)}>
        <div
          className={`sm:p-[0.8rem] p-[0.6rem] w-full ${
            isOpen ? "border-primary" : "border-placeholdercolor"
          } text-[14px] outline-none border-[1.5px] rounded-[8px] cursor-pointer ${
            isPlaceholder
              ? "text-placeholdercolor"
              : "text-textcolor dark:text-white"
          }`}
        >
          {field.value || placeholder}
        </div>
        <div className="absolute top-4 right-4 dark:text-white">
          {isOpen ? <IoIosArrowUp size={22} /> : <IoIosArrowDown size={22} />}
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white dark:text-white dark:bg-darkPrimary border border-authbordercolor rounded-[8px] mt-1 shadow-lg">
          {options.map((option, index) => (
            <div
              key={option.value}
              className={`p-2 hover:bg-disabled dark:hover:bg-placeholdercolor dark:hover:text-white cursor-pointer ${
                index === 0 ? "rounded-t-[8px]" : ""
              } ${index === options.length - 1 ? "rounded-b-[8px]" : ""}`}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      <ErrorMessage
        name={name}
        component="div"
        className="text-error text-[13px] font-PoppinsLight"
      />
    </div>
  );
};

export default SelectInput;
