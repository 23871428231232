import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Account, InvestmentItem, MyInvestmentItem } from "../assets/Typeprops";
import InvestmentBtn from "../components/InvestmentBtn";
import SelectInputWithLabelAndPlaceholder from "../components/SelectInputWithLabelAndPlaceholder";
import SelectInputWithLabel from "../components/SelectInputWithLabel";
import { Form, Formik } from "formik";
import Modal from "../components/Modal";
import { formatDate } from "../components/DateFormat";
import { formatCurrency } from "../components/CurrencyFormat";
import ToastComponent from "../components/ToastComponent";
import { isAxiosError } from "axios";
import { HttpClient } from "../api/HttpClient";
import Logo from "../assets/img/affluence-logo-small.png";
import { ScaleLoader } from "react-spinners";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import AuthButton from "../components/AuthButton";
import PasswordInput from "../components/PasswordInput";
import * as Yup from "yup";
import GlowingButton from "../components/GlowBtn";
import Img from "../assets/img/invest.webp";

interface MyInvestmentsProps {
  data: InvestmentItem[];
  userData: Account;
}
const MyInvestmentMobile: FC<MyInvestmentsProps> = ({ data, userData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<MyInvestmentItem | null>(null);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [investmentData, setInvestmentData] = useState<MyInvestmentItem[]>([]);
  const [filterCategory, setFilterCategory] = useState<string | null>(null);
  const [selectedInvestmentIndex, setSelectedInvestmentIndex] = useState<
    string | null
  >(null);
  const [showEnterTransactionPin, setShowTransactionPin] = useState(false);
  const [pin, setPin] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const navigate = useNavigate();
  const openShowInvestmentModal = (
    investment: MyInvestmentItem,
    index: string
  ) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const openInvestNowModal = (investment: MyInvestmentItem, index: string) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const investmentRes = await HttpClient.get("/investments/booking", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setInvestmentData(investmentRes?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const units = Array.from(
    { length: selectedInvestment?.investment_details.unitsRemaining ?? 0 },
    (_, i) => {
      const value = i + 1;
      return { value: value, label: `${value} units` };
    }
  );
  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookInvestRes = await HttpClient.post(
        "/investments/booking",
        { ...values, id: selectedInvestment?._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investment/my_investments");
      ToastComponent.success(bookInvestRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const filteredData = useMemo(() => {
    let result = investmentData;
    if (filterCategory) {
      result = result.filter(
        (item) => item.investment_details.status === filterCategory
      );
    }
    return result;
  }, [filterCategory, investmentData]);
  const wallet = [
    {
      value: "wallet",
      label: `Wallet Balance (${
        userData?.account?.balance
          ? formatCurrency(userData.account.balance)
          : "₦0"
      })`,
    },
  ];
  const setPinSchema = Yup.object().shape({
    pin: Yup.string().required("Enter transaction pin"),
  });
  const handleClaimProfit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/investments/claim-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setIsModalOpen(false);
      setShowTransactionPin(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };
  return (
    <div className="text-textcolor">
      <div className="flex text-[10px] font-PoppinsMedium justify-between items-center gap-10 mx-[3%] pt-2">
        <div
          onClick={() => setFilterCategory(null)}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === null ? "bg-primary text-white" : ""
          }`}
        >
          All
        </div>
        <div
          onClick={() => setFilterCategory("running")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "running" ? "bg-primary text-white" : ""
          }`}
        >
          Running
        </div>
        <div
          onClick={() => setFilterCategory("followed")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "followed" ? "bg-primary text-white" : ""
          }`}
        >
          Followed
        </div>
        <div
          onClick={() => setFilterCategory("matured")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "matured" ? "bg-primary text-white" : ""
          }`}
        >
          Matured
        </div>
      </div>

      <div className="flex gap-3 pl-[3%] pt-5 overflow-x-scroll hide-scroll">
        {isLoading ? (
          <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center relative">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
          </div>
        ) : filteredData && filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <div
              key={index}
              className="inline-block cursor-pointer w-[150px]"
              onClick={() => openShowInvestmentModal(item, item._id)}
            >
              <div className="w-[150px] h-[100px] relative">
                <img
                  src={item.investment_details.previewImage}
                  className="w-full h-full bg-cover object-cover rounded-[10px]"
                  alt="investment-img"
                />
                <div
                  className={`${
                    item.investment_details.status === "running"
                      ? "bg-referral"
                      : item.investment_details.status === "matured"
                      ? "bg-success"
                      : "bg-error"
                  } text-white rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2`}
                >
                  {item.investment_details.status === "running"
                    ? "RUNNING"
                    : item.investment_details.status === "matured"
                    ? "MATURED"
                    : "SOLD OUT"}
                </div>
              </div>
              <div className="pt-2">
                {item.investment_details.status === "pending" ? (
                  <h1 className="text-textcolor dark:text-white font-PoppinsRegular text-[10px]">
                    UNIT BOUGHT:{" "}
                    <span className="font-PoppinsSemiBold">
                      {`${item.investment_details.unitsSold} / ${item.investment_details.units}`}
                    </span>
                  </h1>
                ) : item.investment_details.status === "running" ? (
                  <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                    NUMBER OF INVESTORS:{" "}
                    <span className="font-PoppinsSemiBold">
                      {item.investment_details.numberOfInvestors}
                    </span>
                  </h1>
                ) : item.investment_details.status === "sold out" ? (
                  <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                    NUMBER OF INVESTORS:{" "}
                    <span className="font-PoppinsSemiBold">
                      {item.investment_details.numberOfInvestors}
                    </span>
                  </h1>
                ) : null}
                <h1 className="text-textcolor dark:text-white uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                  {item.investment_details.title}
                </h1>
                <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                  Interest rate at{" "}
                  <span className="font-PoppinsSemiBold text-success">
                    {`${item.investment_details.rate}%`}
                  </span>
                </h1>
                <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                  {`Returns in ${item.investment_details.durationNumber} ${item.investment_details.durationInterval}`}
                </h1>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center w-full pt-[40px]">
            <div>
              <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                You have no investment currently!
              </h1>
              <h1 className="font-PoppinsRegular pb-2 px-[3%] dark:text-white text-[12px]">
                Start earning interest by investing in our various investment
                options.
              </h1>
              <div className="flex items-center gap-4 justify-center pt-5">
                <button className="bg-primary border-[1.5px] text-[12px] border-primary text-white font-PoppinsSemiBold sm:px-8 xs:px-6 px-4 sm:py-2 py-1 rounded-[8px]">
                  Invest Now
                </button>
                <button className="border-[1.5px] border-primary text-[12px] text-primary font-PoppinsSemiBold sm:px-8 xs:px-6 px-4 sm:py-2 py-1 rounded-[8px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen &&
        selectedInvestment &&
        selectedInvestmentIndex !== null && (
          <Modal
            isOpenModal={openShowInvestmentModal}
            closeModal={closeShowInvestmentModal}
          >
            <div
              className="flex items-center mt-[90px] justify-center"
              style={{
                backgroundImage: `url(${selectedInvestment.investment_details.previewImage})`,
                height: "250px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="bg-white inline-block p-4 rounded-[8px]">
                <img src={Logo} className="w-[30px]" alt="" />
              </div>
            </div>
            <div className="px-4">
              <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                VERIFIED INVESTMENT
              </div>
              <div className="flex justify-between">
                <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                  {selectedInvestment.investment_details.title}
                </h1>
                <div>
                  <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                    {formatCurrency(selectedInvestment.amount)}
                  </h1>
                  <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                    per unit
                  </h1>
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-5">
                {selectedInvestment.investment_details.status === "sold out" ? (
                  <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    SOLD OUT
                  </button>
                ) : selectedInvestment.investment_details.status ===
                  "pending" ? (
                  <button
                    onClick={() =>
                      openInvestNowModal(
                        selectedInvestment,
                        selectedInvestmentIndex
                      )
                    }
                    className="bg-investment border-[1.5px] border-investment text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                  >
                    INVEST NOW
                  </button>
                ) : selectedInvestment.investment_details.status ===
                  "running" ? (
                  <button className="bg-referral cursor-not-allowed border-[1.5px] border-referral bg-opacity-10 text-referral font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    RUNNING
                  </button>
                ) : (
                  <GlowingButton
                    onClick={() => setShowTransactionPin(true)}
                    isLoading={false}
                    disabled={false}
                  >
                    CLAIM
                  </GlowingButton>
                )}
                {selectedInvestment.investment_details.status === "sold out" ? (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] border-error text-error font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                  >
                    ABOUT THIS INVESTMENT
                  </button>
                ) : (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                  >
                    ABOUT THIS INVESTMENT
                  </button>
                )}
              </div>
              <div
                className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                  selectedInvestment.investment_details.status === "sold out"
                    ? "text-error"
                    : selectedInvestment.investment_details.status === "pending"
                    ? "text-success"
                    : selectedInvestment.investment_details.status === "running"
                    ? "text-referral"
                    : "text-success"
                }`}
              >
                {selectedInvestment.investment_details.status === "sold out"
                  ? "This investment is currently sold out to Investors. Please look for other investment plans."
                  : selectedInvestment.investment_details.status === "pending"
                  ? "This investment plan is still available for purchase."
                  : selectedInvestment.investment_details.status === "running"
                  ? "This investment plan is already running for all Investors."
                  : "This investment plan is still available for purchase."}
              </div>
              <hr className="text-authbordercolor my-10" />
              <div>
                <div className="flex justify-center gap-7 mb-5">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Investment Type
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      {selectedInvestment.investment_details.category}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Investment Close Date
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      {formatDate(
                        selectedInvestment.investment_details.startDate
                      )}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center gap-7">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Maturity Date
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      {formatDate(
                        selectedInvestment.investment_details.endDate
                      )}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Payout Type
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      Capital + profit to be paid
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      {investNowModal && selectedInvestment && (
        <Modal
          isOpenModal={openInvestNowModal}
          closeModal={closeInvestNowModal}
        >
          <div className="mt-[120px] px-4">
            <h1 className="text-primary text-[25px] font-PoppinsSemiBold">
              Invest Now
            </h1>
            <div className="bg-investment rounded-[8px] mt-4 p-4 text-white">
              <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
              <h1 className="font-PoppinsRegular text-[14px]">
                Fill the form below to purchase units from this investment.
              </h1>
            </div>
            <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor">
              Number of units to purchase @ &#8358;
              {selectedInvestment.amount} per unit in{" "}
              <span className="uppercase">
                {selectedInvestment.investment_details.title}
              </span>
            </h1>
            <Formik
              initialValues={{ units: "", fundingSource: "" }}
              onSubmit={handleBookInvestment}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <SelectInputWithLabel
                    name="units"
                    options={units}
                    label={false}
                  />
                  <SelectInputWithLabelAndPlaceholder
                    name="fundingSource"
                    options={wallet}
                    placeholder="Select a funding source"
                    label="Choose a Source of Funds"
                  />
                  <div className="absolute left-4 right-4 bottom-4">
                    <InvestmentBtn
                      isLoading={isBooking}
                      disabled={!isValid || !dirty}
                    >
                      INVEST NOW
                    </InvestmentBtn>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      {showEnterTransactionPin && (
        <Modal
          isOpenModal={() => setShowTransactionPin(true)}
          closeModal={() => setShowTransactionPin(false)}
        >
          <div className="px-4 pt-[120px]">
            <div className="pb-[60px]">
              <h1 className="text-[20px] font-PoppinsBold">Transaction Pin</h1>
              <h1 className="font-PoppinsLight pt-3 text-[14px]">
                Enter Transaction pin to claim profit from investment
              </h1>
            </div>
            <div>
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={setPinSchema}
                onSubmit={handleClaimProfit}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <PasswordInput
                      name="pin"
                      type="password"
                      placeholder="4-digit pin"
                      label="Enter Transaction Pin"
                      updatePass="top-[35px] right-[13px]"
                    />
                    <AuthButton isLoading={pin} disabled={!isValid || !dirty}>
                      CLAIM
                    </AuthButton>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
      {aboutModal && (
        <Modal
          isOpenModal={() => setAboutModal(true)}
          closeModal={() => setAboutModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div>
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                About Investment
              </h1>
              <div className="py-2">
                <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                  Read about this investment below
                </p>
              </div>
            </div>
            <div>
              <img src={Img} className="my-7" alt="img" />
              <p className="text-white text-[13px]">
                Affluence Fund II is an investment fund designed to provide
                investors with regular income and long-term capital growth
                through a diversified portfolio of high-quality investment
                managers across various asset classes. The fund targets a
                minimum distribution yield of 5% per annum, paid monthly, while
                also seeking to exceed inflation by 5% over rolling three-year
                periods. One of the key features of Affluence Fund II is its
                diversification, which involves collaborating with 20 to 35
                investment managers to balance risk and return effectively. The
                fund is structured to exhibit low volatility compared to
                traditional equity investments, making it an attractive option
                for those seeking stability alongside growth. Additionally, the
                performance-based fee structure aligns the interests of fund
                managers with those of investors, ensuring that fees are only
                charged when performance targets are met.
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyInvestmentMobile;
