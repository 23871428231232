import { Table, Dropdown, Menu, Input } from "antd";
import { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import { FaRegEye } from "react-icons/fa6";
import {
  MdBlock,
  MdDeleteOutline,
  MdOutlineManageAccounts,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";
import { useEffect, useState } from "react";
import { HttpClient } from "../../../api/HttpClient";
import ToastComponent from "../../../components/ToastComponent";
import { ScaleLoader } from "react-spinners";
import PageTitle from "../../../components/Pagetitle";
import ErrorHandler from "../../ErrorHandler";

interface CustomerData {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  userAccountDetails: {
    balance: number;
    deposit: number;
    investments: number;
    profit: number;
    withdrawal: number;
  };
  serialNumber: number;
}

const Customers = () => {
  const [showManageCustomer, setShowManageCustomer] = useState(false);
  const [allCustomers, setAllCustomers] = useState<CustomerData[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerData[]>(
    []
  );
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const allUserdataRes = await HttpClient.get("/admin/view-users", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            dir: "desc",
          }
        });
        const data = allUserdataRes.data.data;
        const dataWithSerialNumbers = data.map((item: any, index: number) => ({
          ...item,
          serialNumber: index + 1,
        }));

        setAllCustomers(dataWithSerialNumbers);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const filteredData = allCustomers.filter((item: CustomerData) => {
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      const email = item.email.toLowerCase();
      return (
        fullName.includes(searchName.toLowerCase()) &&
        email.includes(searchEmail.toLowerCase())
      );
    });
    setFilteredCustomers(filteredData);
  }, [searchName, searchEmail, allCustomers]);

  const handleMenuClick = (e: any, customerId: string) => {
    if (e.key === "manageCustomer") {
      navigate(`/admin/allCustomers/oneCustomer/${customerId}`);
    }
  };

  const handleBlock = async (customerId: string) => {
    try {
      const blockRes = await HttpClient.get(
        `/admin/block-unblock-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (blockRes.data.statusCode === 200) {
        return ToastComponent.success(
          blockRes.data.message || "User blocked Successfully"
        );
      }
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const handleDelete = async (customerId: string) => {
    try {
      const blockRes = await HttpClient.delete(
        `/admin/delete-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (blockRes.data.statusCode === 200) {
        return ToastComponent.success(
          blockRes.data.message || "User Deleted Successfully"
        );
      }
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const handleToggleView = () => {
    setShowManageCustomer((prev) => !prev);
    navigate("/admin/allCustomers");
  };

  const menu = (customer: CustomerData) => (
    <Menu>
      <Menu.Item
        onClick={() => handleMenuClick({ key: "manageCustomer" }, customer._id)}
        key="manageCustomer"
        className="relative group"
      >
        <Link
          to={`/admin/customers/oneCustomer/customerId`}
          className="flex gap-2 items-center"
        >
          <MdOutlineManageAccounts size={20} />
          <span className="text-textcolor font-PoppinsRegular">
            Manage Customer
          </span>
        </Link>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>

      <Menu.Item
        // onClick={(e) => handleMenuClick(e, customerId)}
        key="sendMail"
        className="relative group"
      >
        <a
          href={`mailto:${customer.email}?subject=Important%20Information&body=Dear%20${customer.firstName}%20${customer.lastName},%0D%0A%0D%0AYour%20message%20here.`}
          className="flex gap-2 items-center"
        >
          <MdOutlineMarkEmailUnread size={20} />
          <span className="text-textcolor font-PoppinsRegular">Send Mail</span>
        </a>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>

      <Menu.Item
        key="block"
        className="relative group"
        onClick={() => handleBlock(customer._id)}
      >
        <div className="flex gap-2 items-center">
          <MdBlock size={20} />
          <span className="text-textcolor font-PoppinsRegular">Block</span>
        </div>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>

      <Menu.Item
        key="delete"
        className="relative group"
        onClick={() => handleDelete(customer._id)}
      >
        <div className="flex gap-2 items-center">
          <MdDeleteOutline size={20} />
          <span className="text-textcolor font-PoppinsRegular">Delete</span>
        </div>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>
    </Menu>
  );

  const columns: ColumnsType<CustomerData> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text: string, record: CustomerData) => (
        <div className="flex items-center gap-6">
          <span>{text}</span>
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <div
              onClick={(e) => e.preventDefault()}
              className="hover:text-primary border rounded-full text-xxs p-0.2"
            >
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
      ),
    },

    {
      title: "Name",
      key: "name",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      key: "balance",
      title: "Balance",
      dataIndex: ["userAccountDetails", "balance"],
      render: (balance: number) => `₦${Number(balance).toLocaleString()}`,
    },
    {
      key: "deposit",
      title: "Deposit",
      dataIndex: ["userAccountDetails", "deposit"],
      render: (deposit: number) => `₦${Number(deposit).toLocaleString()}`,
    },
    {
      title: "Investments",
      key: "investments",
      dataIndex: ["userAccountDetails", "investments"],
      render: (investments: number) =>
        `₦${Number(investments).toLocaleString()}`,
    },
    {
      key: "profit",
      title: "Profit",
      dataIndex: ["userAccountDetails", "profit"],
      render: (profit: number) => `₦${Number(profit).toLocaleString()}`,
    },
    {
      key: "withdrawal",
      title: "Withdrawal",
      dataIndex: ["userAccountDetails", "withdrawal"],
      render: (withdrawal: number) => `₦${Number(withdrawal).toLocaleString()}`,
    },
  ];

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      <PageTitle title={`Affluence - Admin: All Customers`} />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-full bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="flex-1">
              <div className="flex justify-end">
                <button
                  className={`py-2 px-4 rounded-md align-middle ${
                    showManageCustomer
                      ? "border text-primary border-primary"
                      : ""
                  }`}
                  onClick={() => handleToggleView()}
                >
                  {showManageCustomer ? (
                    <>
                      <FaRegEye size={20} className="inline mr-2" />
                      View all customers
                    </>
                  ) : null}
                </button>
              </div>
              {!showManageCustomer && (
                <div className="mt-28 p-4">
                  <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                    <div className="flex gap-4 items-center mb-4">
                      <span className="font-PoppinsBold text-textcolor text-semibold">
                        All Customers
                      </span>
                      <Input
                        placeholder="Search Name"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        style={{ width: 200, marginRight: 8 }}
                      />
                      <Input
                        placeholder="Search Email"
                        value={searchEmail}
                        onChange={(e) => setSearchEmail(e.target.value)}
                        style={{ width: 200, marginRight: 8 }}
                      />
                    </div>

                    <div className="mt-4 overflow-x-auto">
                      <Table
                        columns={columns}
                        dataSource={filteredCustomers}
                        rowKey="_id"
                        pagination={pagination}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Customers;
