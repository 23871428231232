import NoDataFound from "../assets/img/not-found.jpg";

interface NoDataIllustrationProps {
  paragraph: string;
}

const NoDataIllustration: React.FC<NoDataIllustrationProps> = ({ paragraph }) => {
  return (
    <div className="flex items-center justify-center py-5">
      <div>
        <div className="flex items-center justify-center">
          <img src={NoDataFound} className="w-[200px]" alt="No Data!" />
        </div>
        <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
          {paragraph}
        </h1>
      </div>
    </div>
  );
};

export default NoDataIllustration;
