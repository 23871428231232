import { useState } from "react";
import Illustrator from "../assets/img/forgotpass-illustrator.png";
import Logo from "../assets/img/affluence.png";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../components/TextInput";
import AuthButton from "../components/AuthButton";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { HttpClient } from "../api/HttpClient";
import ToastComponent from "../components/ToastComponent";
import { isAxiosError } from "axios";
import PageTitle from "../components/Pagetitle";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Enter your Email Address"),
  });
  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const res = await HttpClient.post("/users/generate-token", values);
      navigate("/reset", { state: { email: values.email } });
      ToastComponent.success(res.data.message);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <PageTitle title="Affluence - Forgot Password" />
      <div className=" font-PoppinsRegular text-textcolor">
        <div className="h-screen flex xmd:flex-row flex-col justify-center xmd:items-stretch items-center">
          <div className="bg-primary h-screen xmd:flex w-[55%] hidden justify-center items-center bg-opacity-10">
            <img src={Illustrator} className="" alt="img" />
          </div>
          <div className="xmd:w-[45%] w-full bg-white sm:px-[3%] px-[30px] flex justify-center items-center">
            <div className="xmd:w-[90%] w-full max-w-[500px]">
              <div className="flex justify-center">
                <img src={Logo} className="sm:w-[120px] w-[100px]" alt="img" />
              </div>
              <h1 className="text-center flex gap-2 justify-center text-primary pt-2 font-PoppinsLight">
                <span className=" font-PoppinsMedium">Save</span>
                <span className="text-secondary">|</span>
                <span className=" font-PoppinsMedium">Invest</span>
                <span className="text-secondary">|</span>
                <span className=" font-PoppinsMedium">Earn</span>
              </h1>
              <div className="pt-3 pb-5 sm:text-start text-center">
                <h1 className="sm:text-[28px] text-[black] flex items-center sm:justify-start justify-center gap-2 text-[24px] font-PoppinsBold pb-1">
                  Forgot Password
                </h1>
                <p className="font-PoppinsRegular text-[black] sm:text-[15px] text-[14px]">
                  Please enter your Email Address
                </p>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={userSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <TextInput
                      name="email"
                      type="email"
                      placeholder="Email Address"
                    />

                    <AuthButton
                      disabled={!isValid || !dirty}
                      isLoading={loading}
                    >
                      Send
                    </AuthButton>
                    <Link
                      to="/login"
                      className="flex items-center gap-2 justify-center mt-3 hover:text-primary"
                    >
                      <FaArrowLeftLong size={20} />
                      <h1 className="text-[15px]">Back to Login</h1>
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
