"use client";
import { FC } from "react";
interface AuthButtonProps {
  isLoading?: boolean;
  disabled: boolean;
  isImageSelected?: boolean;
  children: any;
  type: string;
  className: string;
}
const CreateBtn: FC<AuthButtonProps> = ({ isLoading, isImageSelected, disabled, children }) => {
  const baseStyles =
    "py-2 px-6 rounded-lg font-PoppinsMedium text-[14px] flex justify-center items-center";
  const enabledStyles = "text-success border border-success";
  const disabledStyles =
    "bg-disabled text-disabledtext border border-authbordercolor cursor-not-allowed";
  const isDisabled = disabled || isLoading || !isImageSelected;

  return (
    <button
      type="submit"
      className={`${baseStyles} ${isDisabled ? disabledStyles : enabledStyles}`}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};


export default CreateBtn;
