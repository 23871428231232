import { FC, useState } from "react";
import SelectInputWithLabelAndPlaceholder from "../components/SelectInputWithLabelAndPlaceholder";
import InvestmentBtn from "../components/InvestmentBtn";
import SelectInputWithLabel from "../components/SelectInputWithLabel";
import { Form, Formik } from "formik";
import Modal from "../components/Modal";
import Logo from "../assets/img/affluence-logo-small.png";
import { Account, InvestmentItem } from "../assets/Typeprops";
import { formatCurrency } from "../components/CurrencyFormat";
import { formatDate } from "../components/DateFormat";
import { HttpClient } from "../api/HttpClient";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../components/ToastComponent";
import { isAxiosError } from "axios";

interface ActiveInvestmentsProps {
  data: InvestmentItem[];
  userData: Account;
}
const ActiveInvestmentMobile: FC<ActiveInvestmentsProps> = ({
  data,
  userData,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentItem | null>(null);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const navigate = useNavigate();
  const openShowInvestmentModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const openInvestNowModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const units = Array.from(
    { length: selectedInvestment?.unitsRemaining ?? 0 },
    (_, i) => {
      const value = i + 1;
      return { value: value, label: `${value} units` };
    }
  );
  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookInvestRes = await HttpClient.post(
        "/investments/booking",
        { ...values, id: selectedInvestment?._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investment/my_investments");
      ToastComponent.success(bookInvestRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };
  const wallet = [
    {
      value: "wallet",
      label: `Wallet Balance (${
        userData?.account?.balance
          ? formatCurrency(userData.account.balance)
          : "₦0"
      })`,
    },
  ];

  return (
    <>
      <div className="flex gap-3 pl-[3%] overflow-x-scroll hide-scroll">
        {data.map((item: any, index: any) => (
          <div
            key={index}
            className="inline-block cursor-pointer w-[150px]"
            onClick={() => openShowInvestmentModal(item)}
          >
            <div className="w-[150px] relative">
              <img
                src={item.previewImage}
                className="w-[150px] bg-cover rounded-[10px]"
                alt="investment-img"
              />
              <div className="text-white bg-referral rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2">
                {item.status === "running" && "RUNNING"}
              </div>
            </div>
            <div className="pt-2">
              <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                NUMBER OF INVESTORS:{" "}
                <span className="font-PoppinsSemiBold">
                  {item.numberOfInvestors}
                </span>
              </h1>
              <h1 className="text-textcolor dark:text-white  uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                {item.title}
              </h1>
              <h1 className="font-PoppinsRegular text-[11px] dark:text-white  text-textcolor">
                Interest rate at{" "}
                <span className="font-PoppinsSemiBold text-success">{`${item.rate}%`}</span>
              </h1>
              <h1 className="font-PoppinsRegular text-[11px] dark:text-white  text-textcolor">
                {` Returns in ${item.durationNumber} ${item.durationInterval}`}
              </h1>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && selectedInvestment && (
        <Modal
          isOpenModal={openShowInvestmentModal}
          closeModal={closeShowInvestmentModal}
        >
          <div
            className="flex items-center mt-[90px] justify-center"
            style={{
              backgroundImage: `url(${selectedInvestment.previewImage})`,
              height: "250px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="bg-white inline-block p-4 rounded-[8px]">
              <img src={Logo} className="w-[30px]" alt="" />
            </div>
          </div>
          <div className="px-4">
            <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
              VERIFIED INVESTMENT
            </div>
            <div className="flex justify-between">
              <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                {selectedInvestment.title}
              </h1>
              <div>
                <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                  {formatCurrency(selectedInvestment.amount)}
                </h1>
                <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                  per unit
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-5">
              <button className="bg-referral cursor-not-allowed border-[1.5px] border-referral bg-opacity-10 text-referral font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                RUNNING
              </button>
              <button className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]">
                ABOUT THIS INVESTMENT
              </button>
            </div>
            <div className="text-[14px] font-PoppinsRegular text-center mt-3 text-referral">
              This investment plan is already running for all Investors.
            </div>
            <hr className="text-authbordercolor my-10" />
            <div>
              <div className="flex justify-center gap-7 mb-5">
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Investment Type
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {selectedInvestment.category}
                  </h1>
                </div>
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Investment Close Date
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {formatDate(selectedInvestment.startDate)}
                  </h1>
                </div>
              </div>
              <div className="flex justify-center gap-7">
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Maturity Date
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {formatDate(selectedInvestment.endDate)}
                  </h1>
                </div>
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Payout Type
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    Capital + profit to be paid
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {investNowModal && selectedInvestment && (
        <Modal
          isOpenModal={openInvestNowModal}
          closeModal={closeInvestNowModal}
        >
          <div className="mt-[120px] px-4">
            <h1 className="text-primary text-[25px] font-PoppinsSemiBold">
              Invest Now
            </h1>
            <div className="bg-investment rounded-[8px] mt-4 p-4 text-white">
              <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
              <h1 className="font-PoppinsRegular text-[14px]">
                Fill the form below to purchase units from this investment.
              </h1>
            </div>
            <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor">
              Number of units to purchase @ &#8358;
              {selectedInvestment.amount} per unit in{" "}
              <span className="uppercase">{selectedInvestment.title}</span>
            </h1>
            <Formik
              initialValues={{ units: "", fundingSource: "" }}
              onSubmit={handleBookInvestment}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <SelectInputWithLabel
                    name="units"
                    options={units}
                    label={false}
                  />
                  <SelectInputWithLabelAndPlaceholder
                    name="fundingSource"
                    options={wallet}
                    placeholder="Select a funding source"
                    label="Choose a Source of Funds"
                  />
                  <div className="absolute left-4 right-4 bottom-4">
                    <InvestmentBtn
                      isLoading={isBooking}
                      disabled={!isValid || !dirty}
                    >
                      INVEST NOW
                    </InvestmentBtn>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActiveInvestmentMobile;
