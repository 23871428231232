import { ScaleLoader } from "react-spinners";

const FundWalletLoader = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0">
      <div className="w-full h-screen bg-[#808080] flex flex-col items-center justify-center">
        <ScaleLoader color="#6B006B" />
        <h1 className="text-textcolor font-PoppinsMedium bg-white py-4 px-7 mt-4">
          Fund Wallet Processing...
        </h1>
      </div>
    </div>
  );
};

export default FundWalletLoader;
