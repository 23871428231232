"use client";
import { FC } from "react";
import { MoonLoader } from "react-spinners";
interface InvestmentBtnProps {
  isLoading: boolean;
  disabled: boolean;
  children: any;
  onClick: () => void;
}
const GlowingButton: FC<InvestmentBtnProps> = ({
  isLoading,
  disabled,
  children,
  onClick,
}) => {
  const baseStyles =
    "sm:p-3.5 p-3 rounded-[8px] w-full pulse font-PoppinsBold text-[14px] flex justify-center items-center";
  const enabledStyles = "bg-primary text-white border border-primary";
  const disabledStyles =
    "bg-disabled text-disabledtext border border-authbordercolor cursor-not-allowed";
  return (
    <button
      type="submit"
      onClick={onClick}
      className={`${baseStyles} ${disabled ? disabledStyles : enabledStyles}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>
          <MoonLoader color="#ffffff" size={17} />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default GlowingButton;
