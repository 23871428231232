import PageTitle from "../components/Pagetitle";
import Img from "../assets/img/Mask group.png";
import { AiOutlineBank } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { LiaPercentageSolid } from "react-icons/lia";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import { ScaleLoader } from "react-spinners";
import { RiMenu2Fill } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";
import { formatCurrency } from "../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Account } from "../assets/Typeprops";
type SavingsParam = {
  tab?: string;
};

interface AuthenticatedLayoutContext {
  toggleNav: () => void;
}
const Savings = () => {
  const { tab } = useParams<SavingsParam>();
  const [savingsTab, setSavingsTab] = useState(tab || "");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (tab && tab === savingsTab) {
      setSavingsTab(tab);
    }
  }, [tab, savingsTab]);
  const navigate = useNavigate();
  const handleTabChange = (tabName: string) => {
    setSavingsTab(tabName);
    navigate(`/savings/${tabName}`);
  };
  const [data, setData] = useState<Account | null>(null);
  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const userDataRes = await HttpClient.get("/users/me", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setData(userDataRes?.data?.data);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  return (
    <>
      <PageTitle title="Affluence - Savings" />
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="text-textcolor bg-white dark:bg-darkPrimary dark:text-white pb-[70px] pt-[5rem]">
          <button onClick={toggleNav} className="md:hidden block px-[3%]">
            <RiMenu2Fill size={30} className="text-primary" />
          </button>
          <div className="pb-7 px-[3%] flex items-center justify-between">
            <div className="md:text-[24px] sm:text-[22px] text-[20px]">
              <h1 className=" font-PoppinsBold">My Savings</h1>
            </div>
            <ProfilePicture profilePics={data?.photo} />
          </div>
          <div className="flex gap-3 justify-between items-center px-[3%] xs:pb-0 pb-4">
            <div className="bg-primary bg-opacity-5 h-[120px] border-[1.5px] xs:w-[65%] w-full border-primary dark:border-realSecondary p-7 rounded-[8px]">
              <h1 className="font-PoppinsMedium xs:text-[14px] text-[12px]">
                TOTAL BALANCE
              </h1>
              <h1 className="md:text-[28px] text-[24px] font-PoppinsBold dark:text-realSecondary text-primary">
                {data?.account
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(
                        data.account.referral +
                          data.account.balance +
                          data.account.investments +
                          data.account.savings +
                          data.account.profit
                      )
                  : "₦0"}
              </h1>
            </div>
            <div className=" p-7 rounded-[8px] xs:block hidden">
              <img src={Img} className="w-[300px]" alt="img" />
            </div>
          </div>
          <div className="grid xs:grid-cols-3 grid-cols-2 gap-3 px-[3%]">
            <div
              onClick={() => handleTabChange("wallet")}
              className="bg-primary cursor-pointer text-white p-5 rounded-[8px]"
            >
              <MdOutlineAccountBalanceWallet size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Wallet Balance
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Aggregate available balance across all accounts
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {data?.account.balance
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.balance)
                  : "₦0"}
              </h1>
            </div>

            <div
              className="bg-investment cursor-pointer text-white p-5 rounded-[8px]"
              onClick={() => handleTabChange("investment")}
            >
              <AiOutlineBank size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Investment
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Total of deposits currently available.
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.investments
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.investments)
                  : "₦0"}
              </h1>
            </div>
            <div
              onClick={() => handleTabChange("profit")}
              className="bg-secondary text-white p-5 rounded-[8px]"
            >
              <LiaPercentageSolid size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Profit
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Aggregate profit balance accrued from investments
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.profit
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.profit)
                  : "₦0"}
              </h1>
            </div>
            <div
              onClick={() => handleTabChange("deposit")}
              className="bg-deposit text-white cursor-pointer p-5 rounded-[8px]"
            >
              <PiHandDepositBold size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Deposit
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Total of deposits currently available.
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.deposit
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.deposit)
                  : "₦0"}
              </h1>
            </div>
            <div
              onClick={() => handleTabChange("withdrawal")}
              className="bg-primary text-white p-5 rounded-[8px]"
            >
              <PiHandWithdrawLight size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Withdrawal
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Total amount withdrawn
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.withdrawal
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.withdrawal)
                  : "₦0"}
              </h1>
            </div>
            <div
              onClick={() => handleTabChange("referral")}
              className="bg-referral text-white p-5 rounded-[8px]"
            >
              <PiHandWithdrawLight size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Referral
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Aggregate available balance across all accounts
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {data?.account.referral
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.referral)
                  : "₦0"}
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Savings;
