import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";

interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
      errors?: { [key: string]: string };
    };
  };
}

const errorHandler = (error: ErrorResponse | Error) => {
  if (isAxiosError(error)) {
    ToastComponent.error(error.response?.data?.message || "An error occurred");
  } else if (error instanceof Error) {
    ToastComponent.error(error.message);
  } else {
    ToastComponent.error("An unknown error occurred");
  }
};

export default errorHandler;
