import { useEffect, useState } from "react";
import PageTitle from "../../components/Pagetitle";
import Sidebar from "./Sidebar";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { HttpClient } from "../../api/HttpClient";
import ErrorHandler from "../ErrorHandler";
import { ScaleLoader } from "react-spinners";

interface AuditHistory {
  _id?: string;
  key?: string;
  action: string;
  description: string;
  deviceInfo: string;
  createdAt: string;
  updatedAt: number;
}

function Audit() {
  const [auditHistory, setAuditHistory] = useState<AuditHistory[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchAudit = async () => {
      try {
        const auditHisRes = await HttpClient.get(`/users/audit-log`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });

        const auditHis = auditHisRes.data.data.map(
          (item: any, index: number) => ({
            key: item._id,
            serialNumber: index + 1,
            createdAt: item?.createdAt || "",
            updatedAt: item?.updatedAt || "",
            action: item?.action || "",
            description: item?.description || "",
            deviceInfo: item?.deviceInfo || "",
          })
        );

        setAuditHistory(auditHis);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAudit();
  }, []);

  const auditColumns: ColumnsType<AuditHistory> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Created Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated Date",
      dataIndex: "updatedAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "capitalize",
      render: (text: string) =>
        text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "deviceInfo",
      title: "Device Information",
      dataIndex: "deviceInfo",
    },
  ];
  return (
    <>
      <PageTitle title="Affluence - Admin: Audit" />
      <div className="flex h-screen">
        <Sidebar />
        {loading ? (
          <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
            <ScaleLoader color="#6B006B" />
          </div>
        ) : (
          <div className="flex-1 mt-28 mx-4 overflow-y-auto">
            <div className="border border-transactionCard rounded-md p-5">
              <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
               Audit Logs
              </span>
              <Table
                dataSource={auditHistory}
                columns={auditColumns}
                pagination={{ pageSize: 5 }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Audit;
