import { FC, useState } from "react";
import { IconType } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface FAQCardProps {
  question: string;
  answer: string;
  icon?: IconType;
}

const FaqCard: FC<FAQCardProps> = ({
  question,
  answer,
  icon: IconComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFaq = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-2 cursor-pointer">
      <div
        className="p-4 rounded-[4px] border-[1.5px] border-transactionCard"
        onClick={toggleFaq}
      >
        <div className=" flex items-center justify-between ">
          <div className="flex items-center space-x-5">
            {IconComponent && (
              <IconComponent className="md:text-[22px] sm:text-[20px] text-primary text-[18px]" />
            )}
            <h1 className="font-PoppinsRegular text-[14px]">{question}</h1>
          </div>
          <div className="md:text-[22px] sm:text-[20px] text-[18px]">
            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>
        </div>
        {isOpen && (
          <div className="p-4 text-[14px] font-PoppinsLight text-primary dark:text-secondary">
            <p className="font-PoppinsRegular text-[14px]">{answer}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqCard;
