import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { AiOutlineBank } from "react-icons/ai";
import { LiaPercentageSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import Modal from "../components/Modal";
import { Form, Formik } from "formik";
import NumberInputWithLabel from "../components/NumberInputWithLabel";
import NoTransaction from "../assets/img/Wavy_Tech-31_Single-03.jpg";
import AuthButton from "../components/AuthButton";
import * as Yup from "yup";
import Flutter from "../assets/img/flutter.png";
import PayStack from "../assets/img/paystack.png";
import SelectInputWithLogo from "../components/SelectInputWithLogo";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import { IoAdd } from "react-icons/io5";
import { ScaleLoader } from "react-spinners";
import Zenith from "../assets/img/zenith.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { FaArrowLeft } from "react-icons/fa6";
import { TbCopy, TbCopyCheckFilled } from "react-icons/tb";
import TransactionCard from "../components/TransactionCard";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillArrowDownLeftCircleFill,
  BsFillArrowUpRightCircleFill,
} from "react-icons/bs";
import ReactPaginate from "react-paginate";
import DateFilter from "../components/DateFilter";
import { formatCurrency } from "../components/CurrencyFormat";
import ProfilePicture from "../components/ProfilePicture";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Account } from "../assets/Typeprops";
import TextInputWithLabel from "../components/TextInputWithLabel";
type SavingsParam = {
  tab?: string;
};
const TabbedPage = () => {
  const { tab } = useParams<SavingsParam>();
  const [currentTab, setCurrentTab] = useState(tab || "wallet");

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab);
    }
  }, [tab]);
  const navigate = useNavigate();
  const renderContent = () => {
    switch (currentTab) {
      case "wallet":
        return <WalletContent />;
      case "deposit":
        return <DepositContent />;
      case "investment":
        return <InvestmentContent />;
      case "profit":
        return <ProfitContent />;
      case "withdrawal":
        return <WithdrawalContent />;
      case "referral":
        return <ReferralContent />;
      default:
        return <WalletContent />;
    }
  };
  const handleTabClick = (tabName: string) => {
    setCurrentTab(tabName);
    navigate(`/savings/${tabName}`);
  };
  const location = useLocation();
  const history = useNavigate();
  const handleGoback = () => {
    history(-1);
  };
  const [data, setData] = useState<Account | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const userDataRes = await HttpClient.get("/users/me", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setData(userDataRes?.data?.data);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  return (
    <div className="text-textcolor px-[3%] dark:bg-darkPrimary dark:text-white pt-[7rem]">
      <div className="md:text-[24px] flex items-center justify-between sm:text-[22px] text-[20px]">
        <div className="flex gap-3">
          <button className="minilg:hidden block" onClick={handleGoback}>
            <FaArrowLeft size={25} />
          </button>
          <h1 className="font-PoppinsBold">
            {currentTab.charAt(0).toUpperCase() + currentTab.slice(1)}
          </h1>
        </div>
        {isLoading ? null : <ProfilePicture profilePics={data?.photo} />}
      </div>
      <div className="font-PoppinsRegular mt-8 text-[16px] minilg:flex hidden gap-7 px-2 w-full border-b-[1.5px] border-transactionCard">
        <div>
          <button
            className={`flex items-center gap-2 ${
              location.pathname === "/savings/wallet"
                ? "text-primary dark:text-realSecondary font-PoppinsSemiBold pb-3"
                : "pb-4"
            }`}
            onClick={() => handleTabClick("wallet")}
          >
            <MdOutlineAccountBalanceWallet size={20} />
            Wallet
          </button>
          {currentTab === "wallet" ? (
            <div className="h-1 bg-primary dark:bg-realSecondary w-full rounded-full"></div>
          ) : null}
        </div>

        <div>
          <button
            onClick={() => handleTabClick("investment")}
            className={`flex items-center gap-2 ${
              location.pathname === "/savings/investment"
                ? "text-investment font-PoppinsSemiBold pb-3"
                : "pb-4"
            }`}
          >
            <AiOutlineBank size={20} />
            Investment
          </button>
          {currentTab === "investment" ? (
            <div className="h-1 bg-investment w-full rounded-full"></div>
          ) : null}
        </div>
        <div>
          <button
            onClick={() => handleTabClick("profit")}
            className={`flex items-center gap-2 ${
              location.pathname === "/savings/profit"
                ? "text-secondary font-PoppinsSemiBold pb-3"
                : "pb-4"
            }`}
          >
            <LiaPercentageSolid size={20} />
            Profit
          </button>
          {currentTab === "profit" ? (
            <div className="h-1 bg-secondary w-full rounded-full"></div>
          ) : null}
        </div>
        <div>
          <button
            onClick={() => handleTabClick("deposit")}
            className={`flex items-center gap-2 ${
              location.pathname === "/savings/deposit"
                ? "text-deposit font-PoppinsSemiBold pb-3"
                : "pb-4"
            }`}
          >
            <PiHandDepositBold size={20} />
            Deposit
          </button>
          {currentTab === "deposit" ? (
            <div className="h-1 bg-deposit w-full rounded-full"></div>
          ) : null}
        </div>
        <div>
          <button
            onClick={() => handleTabClick("withdrawal")}
            className={`flex items-center gap-2 ${
              location.pathname === "/savings/withdrawal"
                ? "text-primary dark:text-realSecondary font-PoppinsSemiBold pb-3"
                : "pb-4"
            }`}
          >
            <PiHandWithdrawLight size={20} />
            Withdrawal
          </button>
          {currentTab === "withdrawal" ? (
            <div className="h-1 bg-primary dark:bg-realSecondary w-full rounded-full"></div>
          ) : null}
        </div>
        <div>
          <button
            onClick={() => handleTabClick("referral")}
            className={`flex items-center gap-2 ${
              location.pathname === "/savings/referral"
                ? "text-referral font-PoppinsSemiBold pb-3"
                : "pb-4"
            }`}
          >
            <GiTakeMyMoney size={20} />
            Referral
          </button>
          {currentTab === "referral" ? (
            <div className="h-1 bg-referral w-full rounded-full"></div>
          ) : null}
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

const WalletContent = () => {
  type VirtualAccount = {
    accountNumber: string;
    bankName: string;
    accountName: string;
  };
  type WalletData = {
    amount: number;
    transactionDate: string;
    isInFlow: boolean;
  };

  const [showWalletModal, setShowWalletModal] = useState(false);
  const [createNubanModal, setCreateNubanModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Account | null>(null);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [fetchVirtualAccount, setFetchVirtualAccount] =
    useState<VirtualAccount | null>(null);
  const [viewWalletTransactions, setViewWalletTransactions] = useState(false);
  const [walletData, setwalletData] = useState<WalletData[]>([]);
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);
  const [modalTransactions, setModalTransactions] = useState<WalletData[]>([]);
  const [isinflow, setIsInFlow] = useState<boolean | undefined>(undefined);
  const paymentType = [
    { value: "flutterwave", label: "Flutterwave (Recommended)", logo: Flutter },
    { value: "paystack", label: "Paystack", logo: PayStack },
  ];
  const fundWalletSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .min(100, "Amount must be at least N100")
      .max(10000, "Amount must be at most N10,000"),
    paymentGateway: Yup.string().required("Payment type is required"),
  });
  const handleFundWallet = async (values: any) => {
    const getToken = () => {
      return localStorage.getItem("token");
    };
    const token = getToken();
    setIsLoading(true);
    try {
      const res = await HttpClient.post(
        "/payments/flutterwave/payment",
        values,
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      if (
        res.data &&
        res.data.statusCode === 200 &&
        res.data.data.data &&
        res.data.data.data.link
      ) {
        window.location.href = res.data.data.data.link;
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const virtualAccountSchema = Yup.object().shape({
    accountName: Yup.string().required("Account Name is required"),
  });

  const fetchUserData = useCallback(async () => {
    setPageIsLoading(true);
    try {
      const [userDataRes, virtualAccountRes, transactionRes] =
        await Promise.all([
          HttpClient.get("/users/me", {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }),
          HttpClient.get("/payments/providus/reserved-account", {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }),
          HttpClient.get("/transactions/history", {
            params: {
              page: 1,
              limit: "",
              dir: "desc",
              sort: "created_at",
              search: "wallet",
            },
            headers: { "x-auth-token": localStorage.getItem("token") },
          }),
        ]);
      setData(userDataRes?.data?.data);
      if (virtualAccountRes.data && virtualAccountRes.data.data) {
        setFetchVirtualAccount(virtualAccountRes.data.data);
      }
      const transactions = transactionRes?.data?.data?.data || [];
      setwalletData(transactions);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setPageIsLoading(false);
    }
  }, []);
  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "created_at",
          search: "wallet",
          isInFlow: isinflow,
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewWalletTransactions) {
      fetchViewAllTransactions();
    }
  }, [modalFilter, currentPage, viewWalletTransactions, totalPages, isinflow]);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData, viewWalletTransactions]);
  const handleCreateVirtualAccount = async (values: any) => {
    setIsLoading(true);
    try {
      const res = await HttpClient.post(
        "/payments/providus/reserved-account",
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data?.message);
      fetchUserData();
      // setCreateNubanModal(false);
      // setFetchVirtualAccount((prev) => [...prev, res.data.virtualAccount]); //Confirm if its it
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  const filteredTransactions = modalTransactions.filter((item) => {
    if (isinflow === undefined) {
      return true;
    }
    if (isinflow === false) {
      return item.isInFlow !== true;
    }
    return item.isInFlow === isinflow;
  });
  const filteredData = walletData.filter((item) => {
    if (filter === "All") return true;
    if (filter === "Credit") return item.isInFlow === true;
    if (filter === "Debit") {
      return item.isInFlow === false || item.isInFlow === undefined;
    }

    return true;
  });

  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  return (
    // Wallet
    <>
      {pageIsLoading ? (
        <div className="w-full min-h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="mb-10 min-h-screen bg-white dark:bg-darkPrimary">
          <div>
            <div className="flex justify-between gap-3 mt-7">
              <div className="h-[120px] flex justify-between border-[1.5px] minilg:w-[65%] w-full border-transactionCard p-7 rounded-[8px]">
                <div>
                  <h1 className="font-PoppinsMedium text-[14px]">
                    TOTAL BALANCE (12%)
                  </h1>
                  <h1 className="text-[28px] font-PoppinsBold text-primary dark:text-realSecondary">
                    {data?.account.balance
                      ? data?.hideBalance
                        ? "*******"
                        : formatCurrency(data.account.balance)
                      : "₦0"}
                  </h1>
                </div>
                {/* <button
                  onClick={() => setShowWalletModal(true)}
                  className="bg-white flex font-PoppinsMedium items-center border-[1.5px] h-[50px] sm:px-5 px-3 text-primary border-primary sm:text-base text-[13px] justify-center gap-2 rounded-[8px]"
                >
                  <PiHandDepositBold className="sm:text-[20px] text-[17px]" />
                  Fund Wallet
                </button> */}
              </div>
              {fetchVirtualAccount ? (
                <div className="minilg:flex flex-col hidden font-PoppinsMedium text-primary gap-4 w-[35%] rounded-[8px]">
                  <div
                    key={fetchVirtualAccount.accountNumber}
                    className="border-[1.5px] border-transactionCard rounded-[8px]"
                  >
                    <div className="flex items-center pt-4 justify-between px-4 pb-2">
                      <div>
                        <h1 className="text-[14px] text-textcolor dark:text-disabled">
                          {fetchVirtualAccount.accountName}
                        </h1>
                        <input
                          className="text-primary dark:text-white p-1 outline-none my-1 border-[1.5px] bg-white dark:bg-darkPrimary border-authbordercolor rounded-[5px]"
                          value={fetchVirtualAccount.accountNumber}
                          readOnly
                        />
                        <h1 className="text-textcolor  dark:text-disabled">
                          {fetchVirtualAccount.bankName}
                        </h1>
                      </div>
                      <CopyToClipboard
                        text={fetchVirtualAccount.accountNumber}
                        onCopy={() => setCopied(true)}
                      >
                        <button
                          className={`text-[20px] font-PoppinsRegular rounded-[8px] h-[40px] px-4 ${
                            copied ? "text-referral" : "text-textcolor"
                          }`}
                        >
                          {copied ? <TbCopyCheckFilled /> : <TbCopy />}
                        </button>
                      </CopyToClipboard>
                    </div>
                    <button className="bg-white flex w-full border-t-[1.5px] border-transactionCard text-realSecondary items-center justify-center gap-2 py-3">
                      <PiHandWithdrawLight size={20} />
                      Withdrawal Funds
                    </button>
                  </div>
                </div>
              ) : (
                <div className="minilg:flex hidden flex-col font-PoppinsMedium text-primary gap-4 w-[35%] rounded-[8px]">
                  <button
                    onClick={() => setCreateNubanModal(true)}
                    className="bg-white flex items-center border-[1.5px] border-primary justify-center gap-2 py-3 rounded-[8px]"
                  >
                    <IoAdd size={20} />
                    Create a Nuban
                  </button>
                  <button className="bg-white flex border-[1.5px] border-realSecondary text-realSecondary items-center justify-center gap-2 py-3 rounded-[8px]">
                    <PiHandWithdrawLight size={20} />
                    Withdrawal Funds
                  </button>
                </div>
              )}
            </div>
            <div className="pt-2">
              <div className="flex items-center minilg:gap-0 gap-3 justify-between">
                <div className="border minilg:w-[31.5%] w-full border-transactionCard rounded-[8px] p-4">
                  <h1 className=" font-PoppinsRegular text-[12px] pb-1">
                    Interest rate at 12% p.a
                  </h1>
                  <h1 className=" font-PoppinsSemiBold text-[14px]">
                    &#8358;500
                  </h1>
                </div>
                <div className="border minilg:w-[31.5%] w-full border-transactionCard rounded-[8px] p-4">
                  <h1 className=" font-PoppinsRegular text-[12px] pb-1">
                    Withdrawals
                  </h1>
                  <h1 className=" font-PoppinsSemiBold text-[14px]">0</h1>
                </div>
                <div className="w-[35%] minilg:block hidden"></div>
              </div>
            </div>
            {fetchVirtualAccount ? (
              <div className="minilg:hidden flex-col pt-2 flex font-PoppinsMedium text-primary gap-4 w-full rounded-[8px]">
                <div className="border-[1.5px] border-transactionCard rounded-[8px]">
                  <div className="flex items-center pt-4 justify-between px-4 pb-2">
                    <div>
                      <h1 className="text-[14px] text-textcolor  dark:text-disabled">
                        {fetchVirtualAccount.accountName}
                      </h1>
                      <input
                        className="text-primary dark:text-white py-2 outline-none border bg-white dark:bg-darkPrimary dark:text-disabled border-authbordercolor rounded-[5px]"
                        value={fetchVirtualAccount?.accountNumber}
                        readOnly
                      />
                      <h1 className="text-textcolor  dark:text-disabled">
                        {fetchVirtualAccount?.bankName}
                      </h1>
                    </div>
                    <CopyToClipboard
                      text={fetchVirtualAccount.accountNumber}
                      onCopy={() => setCopied(true)}
                    >
                      <button
                        className={`text-[20px] font-PoppinsRegular rounded-[8px] h-[40px] px-4 ${
                          copied ? "text-referral" : "text-textcolor"
                        }`}
                      >
                        {copied ? <TbCopyCheckFilled /> : <TbCopy />}
                      </button>
                    </CopyToClipboard>
                  </div>
                  <button className="bg-white flex w-full border-t-[1.5px] border-transactionCard text-realSecondary items-center justify-center gap-2 py-3">
                    <PiHandWithdrawLight size={20} />
                    Withdrawal Funds
                  </button>
                </div>
              </div>
            ) : (
              <div className="minilg:hidden flex flex-col pt-2 font-PoppinsMedium text-primary gap-4 w-full rounded-[8px]">
                <button
                  onClick={() => setCreateNubanModal(true)}
                  className="bg-white flex items-center border-[1.5px] border-primary justify-center gap-2 py-3 rounded-[8px]"
                >
                  <IoAdd size={20} />
                  Create a Nuban
                </button>
                <button className="bg-white flex border-[1.5px] border-realSecondary text-realSecondary items-center justify-center gap-2 py-3 rounded-[8px]">
                  <PiHandWithdrawLight size={20} />
                  Withdrawal Funds
                </button>
              </div>
            )}
            <div className="w-full minilg:max-w-[685px] pt-10">
              <div className="flex items-center pb-4 justify-between">
                <h1 className=" font-PoppinsSemiBold text-[16px]">
                  Wallet Transactions
                </h1>

                <h1
                  onClick={() => setViewWalletTransactions(true)}
                  className="font-PoppinsSemiBold flex items-center gap-2 dark:text-white cursor-pointer text-primary"
                >
                  View all
                  <MdArrowForwardIos />
                </h1>
              </div>
              <div className="min-h-[300px] border-[1.5px] border-transactionCard rounded-[8px]">
                <div className="flex items-center gap-4 font-PoppinsMedium text-[12px] p-4">
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "All"
                        ? "bg-primary text-white border-primary dark:bg-white dark:border-white dark:text-darkPrimary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("All")}
                  >
                    All
                  </button>
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "Credit"
                        ? "bg-primary text-white border-primary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("Credit")}
                  >
                    Credit
                  </button>
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "Debit"
                        ? "bg-primary text-white border-primary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("Debit")}
                  >
                    Debit
                  </button>
                </div>
                <div className="px-4 pb-4">
                  <div className="">
                    <div>
                      {filteredData.length > 0 ? (
                        <div>
                          {filteredData.slice(0, 5).map((item, index) => (
                            <div key={index}>
                              <TransactionCard
                                transactionActivity={
                                  item.isInFlow
                                    ? "Credit - Wallet Funding"
                                    : "Debit - Wallet"
                                }
                                transactionTime={formatTransactionDate(
                                  item.transactionDate
                                )}
                                isInFlow={item.isInFlow}
                                transactionAmount={formatCurrency(item.amount)}
                                icon={
                                  item.isInFlow
                                    ? BsFillArrowUpRightCircleFill
                                    : BsFillArrowDownLeftCircleFill
                                }
                                iconClassname={
                                  item.isInFlow ? "text-success" : "text-error"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center py-5">
                          <div>
                            <div className="flex items-center justify-center">
                              <img
                                src={NoTransaction}
                                className="w-[200px]"
                                alt="illustrator"
                              />
                            </div>
                            <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                              No wallet transaction currently!
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {viewWalletTransactions && (
              <Modal
                isOpenModal={() => setViewWalletTransactions(true)}
                closeModal={() => setViewWalletTransactions(false)}
              >
                <div className="px-4 pt-[120px]">
                  <div className="pb-5">
                    <h1 className="text-[22px] text-primary dark:text-realSecondary font-PoppinsSemiBold">
                      Wallet Transaction History
                    </h1>
                    <div className="py-2">
                      <p className="text-[13px] text-disabledtext dark:text-disabled">
                        Below are the recent wallet transaction activities in
                        your Affluence account
                      </p>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="overflow-x-auto">
                      <div className="flex items-center gap-4 text-[12px] w-max">
                        <button
                          className={`py-2.5 px-4 rounded-[8px] ${
                            isinflow === undefined
                              ? "bg-primary text-white border-primary dark:bg-white dark:text-darkPrimary dark:border-white"
                              : "border border-transactionCard"
                          }`}
                          onClick={() => setIsInFlow(undefined)}
                        >
                          All
                        </button>
                        <button
                          className={`py-2.5 px-4 rounded-[8px] ${
                            isinflow === true
                              ? "bg-primary text-white border-primary"
                              : "border border-transactionCard"
                          }`}
                          onClick={() => setIsInFlow(true)}
                        >
                          Credit
                        </button>

                        <button
                          className={`py-2.5 px-4 rounded-[8px] ${
                            isinflow === false
                              ? "bg-primary text-white border-primary"
                              : "border border-transactionCard"
                          }`}
                          onClick={() => setIsInFlow(false)}
                        >
                          Debit
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="pb-2 ">
                    <DateFilter onFilter={handleFilter} />
                  </div>
                  {viewAllIsLoading ? (
                    <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                      <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                    </div>
                  ) : (
                    <>
                      {filteredTransactions.length > 0 ? (
                        <div className="pt-4">
                          {filteredTransactions.map((item, index) => (
                            <div key={index}>
                              <TransactionCard
                                transactionActivity={
                                  item.isInFlow
                                    ? "Credit - Wallet Funding"
                                    : "Debit - Wallet"
                                }
                                transactionTime={formatTransactionDate(
                                  item.transactionDate
                                )}
                                isInFlow={item.isInFlow}
                                transactionAmount={formatCurrency(item.amount)}
                                icon={
                                  item.isInFlow
                                    ? BsFillArrowUpRightCircleFill
                                    : BsFillArrowDownLeftCircleFill
                                }
                                iconClassname={
                                  item.isInFlow ? "text-success" : "text-error"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center py-5">
                          <div>
                            <div className="flex items-center justify-center">
                              <img
                                src={NoTransaction}
                                className="w-[200px]"
                                alt="illustrator"
                              />
                            </div>
                            <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                              No wallet transaction currently!
                            </h1>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <ReactPaginate
                    breakLabel={<span className="mr-4">...</span>}
                    nextLabel={
                      currentPage < totalPages - 1 && (
                        <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                          <BsChevronRight />
                        </span>
                      )
                    }
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={
                      currentPage > 0 && (
                        <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                          <BsChevronLeft />
                        </span>
                      )
                    }
                    renderOnZeroPageCount={null}
                    containerClassName="flex items-center justify-center mt-8 mb-4"
                    pageClassName="block border border-primary hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 h-8 dark:border-authbordercolor flex items-center justify-center rounded-md mr-3"
                    activeClassName="bg-primary text-white"
                  />
                </div>
              </Modal>
            )}
          </div>
          {showWalletModal && (
            <Modal
              isOpenModal={() => setShowWalletModal(true)}
              closeModal={() => setShowWalletModal(false)}
            >
              <div className="mt-[120px] px-4">
                <h1 className="text-[25px] font-PoppinsSemiBold">
                  Fund Wallet
                </h1>
                <div className="pt-10">
                  <Formik
                    initialValues={{ amount: "", paymentGateway: "" }}
                    validationSchema={fundWalletSchema}
                    onSubmit={(values) => handleFundWallet(values)}
                    validateOnChange={true}
                    validateOnBlur={true}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <NumberInputWithLabel
                          name="amount"
                          type="number"
                          placeholder="e.g 20,000"
                          label="Enter Amount in Naira"
                        />
                        <SelectInputWithLogo
                          name="paymentGateway"
                          label="Select preferred option"
                          options={paymentType}
                          placeholder="Select Payment Type"
                        />
                        <div className="absolute left-4 right-4 bottom-4">
                          <AuthButton
                            isLoading={isLoading}
                            disabled={!isValid || !dirty}
                          >
                            CONTINUE
                          </AuthButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
          {createNubanModal && (
            <Modal
              isOpenModal={() => setCreateNubanModal(true)}
              closeModal={() => setCreateNubanModal(false)}
            >
              <div className="mt-[120px] px-4">
                <h1 className="text-[25px] font-PoppinsSemiBold">
                  Create a Virtual Account
                </h1>
                <p className=" font-PoppinsRegular text-[12px]">
                  Generate a new account number for depositing funds into your
                  Wallet.
                </p>
                <div className="pt-10">
                  <Formik
                    initialValues={{ accountName: "" }}
                    validationSchema={virtualAccountSchema}
                    onSubmit={(values) => handleCreateVirtualAccount(values)}
                    validateOnChange={true}
                    validateOnBlur={true}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <TextInputWithLabel
                          name="accountName"
                          type="text"
                          label="Account Name"
                        />

                        <div className="absolute left-4 right-4 bottom-4">
                          <AuthButton
                            isLoading={isLoading}
                            disabled={!isValid || !dirty}
                          >
                            CREATE NUBAN
                          </AuthButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const InvestmentContent = () => {
  type InvestmentData = {
    amount: number;
    transactionDate: string;
    isInFlow: boolean;
  };
  const [investmentData, setInvestmentData] = useState<InvestmentData[]>([]);

  const [viewInvestmentsTransactions, setViewInvestmentsTransactions] =
    useState(false);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const [filter, setFilter] = useState("All");
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [modalTransactions, setModalTransactions] = useState<InvestmentData[]>(
    []
  );
  const [isinflow, setIsInFlow] = useState<boolean | undefined>(undefined);
  // const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  const fetchInvestmentTransactions = useCallback(async () => {
    setPageIsLoading(true);
    try {
      const res = await HttpClient.get("/transactions/history", {
        params: {
          page: 1,
          limit: "",
          dir: "desc",
          sort: "created_at",
          search: "investments",
        },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      const transactions = res?.data?.data.data;
      setInvestmentData(transactions);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setPageIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchInvestmentTransactions();
  }, [fetchInvestmentTransactions]);
  const filteredTransactions = modalTransactions.filter((item) => {
    if (isinflow === undefined) {
      return true;
    }
    if (isinflow === false) {
      return item.isInFlow !== true;
    }
    return item.isInFlow === isinflow;
  });
  const filteredData = investmentData.filter((item) => {
    if (filter === "All") return true;
    if (filter === "Credit") return item.isInFlow === true;
    if (filter === "Debit") {
      return item.isInFlow === false || item.isInFlow === undefined;
    }

    return true;
  });
  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "created_at",
          search: "investments",
          isInFlow: isinflow,
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewInvestmentsTransactions) {
      fetchViewAllTransactions();
    }
  }, [
    modalFilter,
    currentPage,
    viewInvestmentsTransactions,
    totalPages,
    isinflow,
  ]);
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <>
      {pageIsLoading ? (
        <div className="w-full min-h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="mb-10">
          <div>
            <div className="w-full minilg:max-w-[685px] pt-10">
              <div className="flex items-center pb-4 justify-between">
                <h1 className=" font-PoppinsSemiBold text-[16px]">
                  Investments Transactions
                </h1>
                <h1
                  onClick={() => setViewInvestmentsTransactions(true)}
                  className="font-PoppinsSemiBold dark:text-white flex items-center gap-2 text-investment cursor-pointer"
                >
                  View all
                  <MdArrowForwardIos />
                </h1>
              </div>
              <div className="min-h-[300px] border-[1.5px] border-transactionCard rounded-[8px]">
                <div className="flex items-center gap-4 font-PoppinsMedium text-[12px] p-4">
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "All"
                        ? "bg-investment text-white border-investment dark:bg-white dark:border-white dark:text-darkPrimary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("All")}
                  >
                    All
                  </button>
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "Credit"
                        ? "bg-investment text-white border-investment"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("Credit")}
                  >
                    Credit
                  </button>
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "Debit"
                        ? "bg-investment text-white border-investment"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("Debit")}
                  >
                    Debit
                  </button>
                </div>
                <div className="px-4 pb-4">
                  <div className="">
                    <div>
                      {filteredData.length > 0 ? (
                        <div>
                          {filteredData.slice(0, 5).map((item, index) => (
                            <div key={index}>
                              <TransactionCard
                                transactionActivity={
                                  item.isInFlow
                                    ? "Credit - Wallet Funding"
                                    : "Debit - Wallet"
                                }
                                transactionTime={formatTransactionDate(
                                  item.transactionDate
                                )}
                                isInFlow={item.isInFlow}
                                transactionAmount={formatCurrency(item.amount)}
                                icon={
                                  item.isInFlow
                                    ? BsFillArrowUpRightCircleFill
                                    : BsFillArrowDownLeftCircleFill
                                }
                                iconClassname={
                                  item.isInFlow ? "text-success" : "text-error"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center py-5">
                          <div>
                            <div className="flex items-center justify-center">
                              <img
                                src={NoTransaction}
                                className="w-[200px]"
                                alt="illustrator"
                              />
                            </div>
                            <h1 className="font-PoppinsBold pb-3 text-[20px] text-investment">
                              No wallet transaction currently!
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewInvestmentsTransactions && (
            <Modal
              isOpenModal={() => setViewInvestmentsTransactions(true)}
              closeModal={() => setViewInvestmentsTransactions(false)}
            >
              <div className="px-4 pt-[120px]">
                <div className="pb-5">
                  <h1 className="text-[22px] text-investment font-PoppinsSemiBold">
                    Investments Transaction History
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] text-disabledtext">
                      Below are the recent investments transaction activities in
                      your Affluence account
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div className="overflow-x-auto">
                    <div className="flex items-center gap-4 text-[12px] w-max">
                      <button
                        className={`py-2.5 px-4 rounded-[8px] ${
                          isinflow === undefined
                            ? "bg-investment text-white border-investment"
                            : "border border-transactionCard"
                        }`}
                        onClick={() => setIsInFlow(undefined)}
                      >
                        All
                      </button>
                      <button
                        className={`py-2.5 px-4 rounded-[8px] ${
                          isinflow === true
                            ? "bg-investment text-white border-investment"
                            : "border border-transactionCard"
                        }`}
                        onClick={() => setIsInFlow(true)}
                      >
                        Credit
                      </button>

                      <button
                        className={`py-2.5 px-4 rounded-[8px] ${
                          isinflow === false
                            ? "bg-investment text-white border-investment"
                            : "border border-transactionCard"
                        }`}
                        onClick={() => setIsInFlow(false)}
                      >
                        Debit
                      </button>
                    </div>
                  </div>
                </div>

                <div className="pb-2 ">
                  <DateFilter onFilter={handleFilter} />
                </div>
                {viewAllIsLoading ? (
                  <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                    <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                  </div>
                ) : (
                  <>
                    {filteredTransactions.length > 0 ? (
                      <div className="pt-4">
                        {filteredTransactions.map((item, index) => (
                          <div key={index}>
                            <TransactionCard
                              transactionActivity={
                                item.isInFlow
                                  ? "Credit - Wallet Funding"
                                  : "Debit - Wallet"
                              }
                              transactionTime={formatTransactionDate(
                                item.transactionDate
                              )}
                              isInFlow={item.isInFlow}
                              transactionAmount={formatCurrency(item.amount)}
                              icon={
                                item.isInFlow
                                  ? BsFillArrowUpRightCircleFill
                                  : BsFillArrowDownLeftCircleFill
                              }
                              iconClassname={
                                item.isInFlow ? "text-success" : "text-error"
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center py-5">
                        <div>
                          <div className="flex items-center justify-center">
                            <img
                              src={NoTransaction}
                              className="w-[200px]"
                              alt="illustrator"
                            />
                          </div>
                          <h1 className="font-PoppinsBold pb-3 text-[20px] text-investment">
                            No wallet transaction currently!
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    currentPage < totalPages - 1 && (
                      <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronRight />
                      </span>
                    )
                  }
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={
                    currentPage > 0 && (
                      <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronLeft />
                      </span>
                    )
                  }
                  renderOnZeroPageCount={null}
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="block border border-primary hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 h-8 dark:border-authbordercolor flex items-center justify-center rounded-md mr-3"
                  activeClassName="bg-primary text-white"
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const ProfitContent = () => {
  type ProfitsData = {
    amount: number;
    transactionDate: string;
    isInFlow: boolean;
  };
  const [profitsData, setProfitsData] = useState<ProfitsData[]>([]);

  const [viewProfitsTransactions, setViewProfitsTransactions] = useState(false);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const [filter, setFilter] = useState("All");
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [modalTransactions, setModalTransactions] = useState<ProfitsData[]>([]);
  // const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  const fetchProfitsTransactions = useCallback(async () => {
    setPageIsLoading(true);
    try {
      const res = await HttpClient.get("/transactions/history", {
        params: {
          page: 1,
          limit: "",
          dir: "desc",
          sort: "created_at",
          search: "profits",
        },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      const transactions = res?.data?.data.data;
      setProfitsData(transactions);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setPageIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchProfitsTransactions();
  }, [fetchProfitsTransactions]);
  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "created_at",
          search: "profits",
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewProfitsTransactions) {
      fetchViewAllTransactions();
    }
  }, [modalFilter, currentPage, viewProfitsTransactions, totalPages]);
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <>
      {pageIsLoading ? (
        <div className="w-full min-h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="mb-10">
          <div>
            <div className="w-full minilg:max-w-[685px] pt-10">
              <div className="flex items-center pb-4 justify-between">
                <h1 className=" font-PoppinsSemiBold text-[16px]">
                  Profits Transactions
                </h1>
                <h1
                  onClick={() => setViewProfitsTransactions(true)}
                  className="font-PoppinsSemiBold dark:text-white flex items-center gap-2 text-secondary cursor-pointer"
                >
                  View all
                  <MdArrowForwardIos />
                </h1>
              </div>
              <div className="min-h-[300px] border-[1.5px] border-transactionCard rounded-[8px]">
                <div className="flex items-center gap-4 font-PoppinsMedium text-[12px] p-4">
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "All"
                        ? "bg-secondary text-white border-secondary dark:bg-white dark:border-white dark:text-darkPrimary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("All")}
                  >
                    All
                  </button>
                </div>
                <div className="px-4 pb-4">
                  <div className="">
                    <div>
                      {profitsData.length > 0 ? (
                        <div>
                          {profitsData.slice(0, 5).map((item, index) => (
                            <div key={index}>
                              <TransactionCard
                                transactionActivity={"Credit - Profit"}
                                transactionTime={formatTransactionDate(
                                  item.transactionDate
                                )}
                                isInFlow={item.isInFlow}
                                transactionAmount={formatCurrency(item.amount)}
                                icon={BsFillArrowUpRightCircleFill}
                                iconClassname={"text-success"}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center py-5">
                          <div>
                            <div className="flex items-center justify-center">
                              <img
                                src={NoTransaction}
                                className="w-[200px]"
                                alt="illustrator"
                              />
                            </div>
                            <h1 className="font-PoppinsBold pb-3 text-[20px] text-secondary">
                              No wallet transaction currently!
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewProfitsTransactions && (
            <Modal
              isOpenModal={() => setViewProfitsTransactions(true)}
              closeModal={() => setViewProfitsTransactions(false)}
            >
              <div className="px-4 pt-[120px]">
                <div className="pb-5">
                  <h1 className="text-[22px] text-secondary font-PoppinsSemiBold">
                    Investments Transaction History
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] text-disabledtext">
                      Below are the recent profits transaction activities in
                      your Affluence account
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div className="overflow-x-auto">
                    <div className="flex items-center gap-4 text-[12px] w-max">
                      <button className="py-2.5 px-4 rounded-[8px] bg-secondary text-white border-secondary">
                        All
                      </button>
                    </div>
                  </div>
                </div>

                <div className="pb-2 ">
                  <DateFilter onFilter={handleFilter} />
                </div>
                {viewAllIsLoading ? (
                  <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                    <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                  </div>
                ) : (
                  <>
                    {modalTransactions.length > 0 ? (
                      <div className="pt-4">
                        {modalTransactions.map((item, index) => (
                          <div key={index}>
                            <TransactionCard
                              transactionActivity={
                                item.isInFlow
                                  ? "Credit - Wallet Funding"
                                  : "Debit - Wallet"
                              }
                              transactionTime={formatTransactionDate(
                                item.transactionDate
                              )}
                              isInFlow={item.isInFlow}
                              transactionAmount={formatCurrency(item.amount)}
                              icon={
                                item.isInFlow
                                  ? BsFillArrowUpRightCircleFill
                                  : BsFillArrowDownLeftCircleFill
                              }
                              iconClassname={
                                item.isInFlow ? "text-success" : "text-error"
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center py-5">
                        <div>
                          <div className="flex items-center justify-center">
                            <img
                              src={NoTransaction}
                              className="w-[200px]"
                              alt="illustrator"
                            />
                          </div>
                          <h1 className="font-PoppinsBold pb-3 text-[20px] text-secondary">
                            No profit transaction currently!
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    currentPage < totalPages - 1 && (
                      <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronRight />
                      </span>
                    )
                  }
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={
                    currentPage > 0 && (
                      <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronLeft />
                      </span>
                    )
                  }
                  renderOnZeroPageCount={null}
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="block border border-primary hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 h-8 dark:border-authbordercolor flex items-center justify-center rounded-md mr-3"
                  activeClassName="bg-primary text-white"
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const DepositContent = () => {
  type DepositStatData = {
    failed_value: number;
    failed_value_percentage: string;
    failed_volume: number;
    failed_volume_percentage: string;
    success_value: number;
    success_value_percentage: string;
    success_volume: number;
    success_volume_percentage: string;
    total_value: number;
    total_volume: number;
  };
  const [data, setData] = useState<Account | null>(null);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(amount);
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({});

  type DepositsData = {
    amount: number;
    transactionDate: string;
    isInFlow: boolean;
  };

  const [depositsData, setDepositsData] = useState<DepositsData[]>([]);
  const [depositStatData, setDepositStatData] =
    useState<DepositStatData | null>(null);

  const [modalTransactions, setModalTransactions] = useState<DepositsData[]>(
    []
  );
  const fetchDepositsTransactions = useCallback(async () => {
    setPageIsLoading(true);
    try {
      const [res, transStatRes, userDataRes] = await Promise.all([
        HttpClient.get("/transactions/history", {
          params: {
            page: 1,
            limit: "",
            dir: "desc",
            sort: "created_at",
            search: "deposits",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }),
        HttpClient.get("/transactions/statistics", {
          params: {
            page: 1,
            limit: "",
            dir: "desc",
            sort: "created_at",
            search: "deposits",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }),
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);
      setData(userDataRes?.data?.data);
      const transactions = res?.data?.data.data;
      setDepositsData(transactions);
      setDepositStatData(transStatRes?.data?.data);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setPageIsLoading(false);
    }
  }, []);
  console.log(depositStatData);
  useEffect(() => {
    const successfulDeposits = depositStatData?.success_value || 0;
    const failedDeposits = depositStatData?.failed_value || 0;
    const totalDeposits = depositStatData?.total_value || 0;
    const data = {
      labels: ["Successful Deposits", "Failed Deposits"],
      datasets: [
        {
          data: [successfulDeposits, failedDeposits],
          backgroundColor: ["#009306", "#E80404"],
          hoverBackgroundColor: ["#00b309", "#ff4d4d"],
          borderWidth: 0,
        },
      ],
    };
    const options = {
      cutout: "60%",
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const value = tooltipItem.raw;
              const percentage = ((value / totalDeposits) * 100).toFixed(2);
              const formattedValue = formatCurrency(value);
              return `${tooltipItem.label}: ${formattedValue} (${percentage}%)`;
            },
          },
        },
        legend: {
          labels: {
            color: isDarkMode ? "#FFFFFF" : "#3D3C3A",
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [isDarkMode]);
  useEffect(() => {
    fetchDepositsTransactions();
  }, [fetchDepositsTransactions]);
  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  const [viewDepositTransactions, setViewDepositTransactions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "created_at",
          search: "deposits",
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewDepositTransactions) {
      fetchViewAllTransactions();
    }
  }, [modalFilter, currentPage, viewDepositTransactions, totalPages]);
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <>
      {pageIsLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="pb-10 pt-7">
          <div>
            <div className="flex justify-between gap-3 items-center">
              <div className="h-[120px] flex justify-between border-[1.5px] minilg:w-[65%] w-1/2 border-transactionCard p-7 rounded-[8px]">
                <div>
                  <h1 className="font-PoppinsMedium text-[14px]">
                    TOTAL BALANCE
                  </h1>
                  <h1 className="text-[28px] font-PoppinsBold text-deposit">
                    {depositStatData?.total_value
                      ? data?.hideBalance
                        ? "*******"
                        : formatCurrency(depositStatData?.total_value)
                      : "₦0"}
                  </h1>
                </div>
              </div>
              <div className="h-[120px] flex justify-between items-center border-[1.5px] minilg:w-[35%] w-1/2 border-transactionCard p-7 rounded-[8px]">
                <div>
                  <h1 className=" font-PoppinsRegular text-[12px] pb-1">
                    Total Deposits
                  </h1>
                  <h1 className=" text-[28px] font-PoppinsBold text-deposit">
                    {depositStatData?.total_volume}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex minilg:pt-0 pt-1 justify-between">
              <div className="extra:w-[64.2%] w-full">
                <div className="pt-2">
                  <div className="flex gap-3 items-center justify-between">
                    <div className="border w-full border-transactionCard rounded-[8px] p-4">
                      <h1 className=" font-PoppinsRegular text-[12px] pb-1">
                        Total Successful Deposits
                      </h1>
                      <h1 className=" font-PoppinsSemiBold text-[14px]">
                        {depositStatData?.success_volume}
                      </h1>
                    </div>
                    <div className="border w-full border-transactionCard rounded-[8px] p-4">
                      <h1 className=" font-PoppinsRegular text-[12px] pb-1">
                        Total Failed Deposits
                      </h1>
                      <h1 className=" font-PoppinsSemiBold text-[14px]">
                        {depositStatData?.failed_volume}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-full minilg:max-w-[685px] pt-5">
                  <div className="flex items-center pb-4 justify-between">
                    <h1 className=" font-PoppinsSemiBold text-[16px]">
                      Transaction History
                    </h1>
                    <h1
                      onClick={() => setViewDepositTransactions(true)}
                      className="font-PoppinsSemiBold dark:text-white flex items-center gap-2 text-primary cursor-pointer"
                    >
                      View all
                      <MdArrowForwardIos />
                    </h1>
                  </div>
                  <div className="min-h-[300px] border-[1.5px] border-transactionCard rounded-[8px]">
                    <div className="flex items-center gap-4 font-PoppinsMedium text-[12px] p-4">
                      <button className="bg-deposit border py-2.5 px-4 border-deposit text-white rounded-[8px]">
                        All
                      </button>
                    </div>
                    <div className="px-4 pb-4">
                      <div className="">
                        <div>
                          {depositsData.length > 0 ? (
                            <div>
                              {depositsData.slice(0, 5).map((item, index) => (
                                <div key={index}>
                                  <TransactionCard
                                    transactionActivity={
                                      item.isInFlow
                                        ? "Credit - Wallet Funding"
                                        : "Debit - Wallet"
                                    }
                                    transactionTime={formatTransactionDate(
                                      item.transactionDate
                                    )}
                                    isInFlow={item.isInFlow}
                                    transactionAmount={formatCurrency(
                                      item.amount
                                    )}
                                    icon={
                                      item.isInFlow
                                        ? BsFillArrowUpRightCircleFill
                                        : BsFillArrowDownLeftCircleFill
                                    }
                                    iconClassname={
                                      item.isInFlow
                                        ? "text-success"
                                        : "text-error"
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="flex items-center justify-center py-5">
                              <div>
                                <div className="flex items-center justify-center">
                                  <img
                                    src={NoTransaction}
                                    className="w-[200px]"
                                    alt="illustrator"
                                  />
                                </div>
                                <h1 className="font-PoppinsBold pb-3 text-[20px] text-deposit">
                                  No wallet transaction currently!
                                </h1>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="minilg:block w-[34.5%] hidden">
                <div className="mt-3 shadow-md rounded-[8px] border border-transactionCard p-7">
                  <h1 className="font-PoppinsSemiBold text-center">
                    Summary of transactions
                  </h1>
                  <div className=" flex justify-center">
                    <div className="w-[300px] h-[300px]">
                      <Chart
                        type="doughnut"
                        data={chartData}
                        options={chartOptions}
                        className="w-full md:w-30rem"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewDepositTransactions && (
            <Modal
              isOpenModal={() => setViewDepositTransactions(true)}
              closeModal={() => setViewDepositTransactions(false)}
            >
              <div className="px-4 pt-[120px]">
                <div className="pb-5">
                  <h1 className="text-[22px] text-deposit font-PoppinsSemiBold">
                    Deposit Transaction History
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] text-disabledtext">
                      Below are the recent deposit transaction activities in
                      your Affluence account
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div className="overflow-x-auto">
                    <div className="flex items-center gap-4 text-[12px] w-max">
                      <button className="py-2.5 px-4 rounded-[8px] bg-deposit text-white border-deposit">
                        All
                      </button>
                    </div>
                  </div>
                </div>

                <div className="pb-2 ">
                  <DateFilter onFilter={handleFilter} />
                </div>
                {viewAllIsLoading ? (
                  <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                    <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                  </div>
                ) : (
                  <>
                    {modalTransactions.length > 0 ? (
                      <div className="pt-4">
                        {modalTransactions.map((item, index) => (
                          <div key={index}>
                            <TransactionCard
                              transactionActivity={"Debit - Wallet"}
                              transactionTime={formatTransactionDate(
                                item.transactionDate
                              )}
                              isInFlow={item.isInFlow}
                              transactionAmount={formatCurrency(item.amount)}
                              icon={BsFillArrowDownLeftCircleFill}
                              iconClassname={"text-error"}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center py-5">
                        <div>
                          <div className="flex items-center justify-center">
                            <img
                              src={NoTransaction}
                              className="w-[200px]"
                              alt="illustrator"
                            />
                          </div>
                          <h1 className="font-PoppinsBold pb-3 text-[20px] text-deposit">
                            No deposit transaction currently!
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    currentPage < totalPages - 1 && (
                      <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronRight />
                      </span>
                    )
                  }
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={
                    currentPage > 0 && (
                      <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronLeft />
                      </span>
                    )
                  }
                  renderOnZeroPageCount={null}
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="block border border-primary hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 h-8 dark:border-authbordercolor flex items-center justify-center rounded-md mr-3"
                  activeClassName="bg-primary text-white"
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const WithdrawalContent = () => {
  type WithdrawalsData = {
    amount: number;
    transactionDate: string;
    isInFlow: boolean;
  };
  const [withdrawalsData, setWithdrawalsData] = useState<WithdrawalsData[]>([]);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const [filter, setFilter] = useState("All");
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [modalTransactions, setModalTransactions] = useState<WithdrawalsData[]>(
    []
  );
  const fetchWithdrawalsTransactions = useCallback(async () => {
    setPageIsLoading(true);
    try {
      const res = await HttpClient.get("/transactions/history", {
        params: {
          page: 1,
          limit: "",
          dir: "desc",
          sort: "created_at",
          search: "withdrawals",
        },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      const transactions = res?.data?.data.data;
      setWithdrawalsData(transactions);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setPageIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchWithdrawalsTransactions();
  }, [fetchWithdrawalsTransactions]);
  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  const [viewWithdrawalTransactions, setViewWithdrawalTransactions] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "created_at",
          search: "withdrawals",
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewWithdrawalTransactions) {
      fetchViewAllTransactions();
    }
  }, [modalFilter, currentPage, viewWithdrawalTransactions, totalPages]);
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <>
      {pageIsLoading ? (
        <div className="w-full min-h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="mb-10">
          <div>
            <div className="w-full minilg:max-w-[685px] pt-10">
              <div className="flex items-center pb-4 justify-between">
                <h1 className=" font-PoppinsSemiBold text-[16px]">
                  Withdrawal Transactions
                </h1>
                <h1
                  onClick={() => setViewWithdrawalTransactions(true)}
                  className="font-PoppinsSemiBold dark:text-white flex items-center gap-2 text-primary cursor-pointer"
                >
                  View all
                  <MdArrowForwardIos />
                </h1>
              </div>
              <div className="min-h-[300px] border-[1.5px] border-transactionCard rounded-[8px]">
                <div className="flex items-center gap-4 font-PoppinsMedium text-[12px] p-4">
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "All"
                        ? "bg-primary text-white border-primary dark:bg-white dark:border-white dark:text-darkPrimary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("All")}
                  >
                    All
                  </button>
                </div>
                <div className="px-4 pb-4">
                  <div className="">
                    <div>
                      {withdrawalsData.length > 0 ? (
                        <div>
                          {withdrawalsData.slice(0, 5).map((item, index) => (
                            <div key={index}>
                              <TransactionCard
                                transactionActivity={
                                  "Debit - Wallet To Bank Account"
                                }
                                transactionTime={formatTransactionDate(
                                  item.transactionDate
                                )}
                                isInFlow={item.isInFlow}
                                transactionAmount={formatCurrency(item.amount)}
                                icon={BsFillArrowDownLeftCircleFill}
                                iconClassname={"text-error"}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center py-5">
                          <div>
                            <div className="flex items-center justify-center">
                              <img
                                src={NoTransaction}
                                className="w-[200px]"
                                alt="illustrator"
                              />
                            </div>
                            <h1 className="font-PoppinsBold pb-3 text-[20px] dark:text-realSecondary text-primary">
                              No withdrawal transaction currently!
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewWithdrawalTransactions && (
            <Modal
              isOpenModal={() => setViewWithdrawalTransactions(true)}
              closeModal={() => setViewWithdrawalTransactions(false)}
            >
              <div className="px-4 pt-[120px]">
                <div className="pb-5">
                  <h1 className="text-[22px] text-primary dark:text-realSecondary font-PoppinsSemiBold">
                    Withdrawal Transaction History
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] text-disabledtext">
                      Below are the recent withdrawal transaction activities in
                      your Affluence account
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div className="overflow-x-auto">
                    <div className="flex items-center gap-4 text-[12px] w-max">
                      <button className="py-2.5 px-4 rounded-[8px] bg-primary text-white border-primary dark:bg-realSecondary dark:border-realSecondary">
                        All
                      </button>
                    </div>
                  </div>
                </div>

                <div className="pb-2 ">
                  <DateFilter onFilter={handleFilter} />
                </div>
                {viewAllIsLoading ? (
                  <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                    <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                  </div>
                ) : (
                  <>
                    {modalTransactions.length > 0 ? (
                      <div className="pt-4">
                        {modalTransactions.map((item, index) => (
                          <div key={index}>
                            <TransactionCard
                              transactionActivity={
                                "Debit - Wallet To Bank Account"
                              }
                              transactionTime={formatTransactionDate(
                                item.transactionDate
                              )}
                              isInFlow={item.isInFlow}
                              transactionAmount={formatCurrency(item.amount)}
                              icon={BsFillArrowDownLeftCircleFill}
                              iconClassname={"text-error"}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center py-5">
                        <div>
                          <div className="flex items-center justify-center">
                            <img
                              src={NoTransaction}
                              className="w-[200px]"
                              alt="illustrator"
                            />
                          </div>
                          <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary dark:text-realSecondary">
                            No deposit transaction currently!
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    currentPage < totalPages - 1 && (
                      <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronRight />
                      </span>
                    )
                  }
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={
                    currentPage > 0 && (
                      <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronLeft />
                      </span>
                    )
                  }
                  renderOnZeroPageCount={null}
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="block border border-primary hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 h-8 dark:border-authbordercolor flex items-center justify-center rounded-md mr-3"
                  activeClassName="bg-primary text-white"
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

const ReferralContent = () => {
  type ReferralsData = {
    amount: number;
    transactionDate: string;
    isInFlow: boolean;
  };
  const [data, setData] = useState<Account | null>(null);
  const [pageIsLoading, setPageIsLoading] = useState(false);

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(amount);
  };
  const [viewReferralTransactions, setViewReferralTransactions] =
    useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [copyVal, setCopyVal] = useState(
    "www.affluence.com/refer/emmanuel-8899"
  );

  const [referralsData, setReferralsData] = useState<ReferralsData[]>([]);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const [filter, setFilter] = useState("All");
  const [modalTransactions, setModalTransactions] = useState<ReferralsData[]>(
    []
  );
  const [isinflow, setIsInFlow] = useState<boolean | undefined>(undefined);
  const fetchInvestmentTransactions = useCallback(async () => {
    setPageIsLoading(true);
    try {
      const [userDataRes, transactionRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/transactions/history", {
          params: {
            page: 1,
            limit: "",
            dir: "desc",
            sort: "created_at",
            search: "referrals",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }),
      ]);

      setData(userDataRes?.data?.data);
      const transactions = transactionRes?.data?.data.data;
      setReferralsData(transactions);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setPageIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchInvestmentTransactions();
  }, [fetchInvestmentTransactions]);
  const filteredTransactions = modalTransactions.filter((item) => {
    if (isinflow === undefined) {
      return true;
    }
    if (isinflow === false) {
      return item.isInFlow !== true;
    }
    return item.isInFlow === isinflow;
  });
  const filteredData = referralsData.filter((item) => {
    if (filter === "All") return true;
    if (filter === "Credit") return item.isInFlow === true;
    if (filter === "Debit") {
      return item.isInFlow === false || item.isInFlow === undefined;
    }

    return true;
  });
  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);

  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "created_at",
          search: "referrals",
          isInFlow: isinflow,
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewReferralTransactions) {
      fetchViewAllTransactions();
    }
  }, [
    modalFilter,
    currentPage,
    viewReferralTransactions,
    totalPages,
    isinflow,
  ]);
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <>
      {pageIsLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="mb-10">
          <div>
            <div className="flex justify-between mt-7 gap-3 items-center">
              <div className="h-[120px] flex justify-between border-[1.5px] minilg:w-[62%] w-full border-transactionCard p-7 rounded-[8px]">
                <div>
                  <h1 className="font-PoppinsMedium text-[14px]">
                    REFERRAL BALANCE
                  </h1>
                  <h1 className="text-[28px] font-PoppinsBold text-referral">
                    {data?.account.referral
                      ? data?.hideBalance
                        ? "*******"
                        : formatCurrency(data.account.referral)
                      : "₦0"}
                  </h1>
                </div>
                <button className="flex font-PoppinsMedium items-center px-5 text-white bg-referral justify-center gap-2 h-[50px] rounded-[8px]">
                  Withdraw
                </button>
              </div>
              <div className="h-[120px] minilg:flex hidden items-center justify-center font-PoppinsMedium gap-4 w-[38%] border p-7 rounded-[8px] border-transactionCard">
                <div>
                  <h1 className=" font-PoppinsSemiBold text-[15px]">
                    Refer and Earn
                  </h1>
                  <p className="text-[10px] font-PoppinsRegular">
                    Refer friends and you both earn ₦100 per successful
                    referral. Terms and conditions apply.
                  </p>
                  <div className="flex justify-between w-full items-center">
                    <input
                      className="text-referral p-1 w-[80%] outline-none my-1 border-[1.5px] text-[10px] font-PoppinsItalic border-authbordercolor rounded-[5px]"
                      value={copyVal}
                      readOnly
                    />
                    <CopyToClipboard
                      text={copyVal}
                      onCopy={() => setCopied(true)}
                    >
                      <button
                        className={`text-[20px] font-PoppinsRegular rounded-[8px] h-[40px] px-4 ${
                          copied
                            ? "text-referral"
                            : "text-textcolor dark:text-white"
                        }`}
                      >
                        {copied ? <TbCopyCheckFilled /> : <TbCopy />}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full minilg:max-w-[685px] pt-10">
              <div className="flex items-center pb-4 justify-between">
                <h1 className=" font-PoppinsSemiBold text-[16px]">
                  Referral Transactions
                </h1>
                <h1
                  onClick={() => setViewReferralTransactions(true)}
                  className="font-PoppinsSemiBold text-referral dark:text-white flex items-center gap-2 cursor-pointer"
                >
                  View all
                  <MdArrowForwardIos />
                </h1>
              </div>
              <div className="min-h-[300px] border-[1.5px] border-transactionCard rounded-[8px]">
                <div className="flex items-center gap-4 font-PoppinsMedium text-[12px] p-4">
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "All"
                        ? "bg-referral text-white border-referral dark:bg-white dark:border-white dark:text-darkPrimary"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("All")}
                  >
                    All
                  </button>
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "Credit"
                        ? "bg-referral text-white border-referral"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("Credit")}
                  >
                    Credit
                  </button>
                  <button
                    className={`py-2.5 px-4 rounded-[8px] ${
                      filter === "Debit"
                        ? "bg-referral text-white border-referral"
                        : "border border-transactionCard"
                    }`}
                    onClick={() => setFilter("Debit")}
                  >
                    Debit
                  </button>
                </div>
                <div className="px-4 pb-4">
                  <div className="">
                    <div>
                      {filteredData.length > 0 ? (
                        <div>
                          {filteredData.slice(0, 5).map((item, index) => (
                            <div key={index}>
                              <TransactionCard
                                transactionActivity={
                                  item.isInFlow
                                    ? "Credit - Referral Bonus"
                                    : "Debit - Withdrawal To Bank Account"
                                }
                                transactionTime={formatTransactionDate(
                                  item.transactionDate
                                )}
                                isInFlow={item.isInFlow}
                                transactionAmount={formatCurrency(item.amount)}
                                icon={
                                  item.isInFlow
                                    ? BsFillArrowUpRightCircleFill
                                    : BsFillArrowDownLeftCircleFill
                                }
                                iconClassname={
                                  item.isInFlow ? "text-success" : "text-error"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center py-5">
                          <div>
                            <div className="flex items-center justify-center">
                              <img
                                src={NoTransaction}
                                className="w-[200px]"
                                alt="illustrator"
                              />
                            </div>
                            <h1 className="font-PoppinsBold pb-3 text-[20px] text-referral">
                              No referral transaction currently!
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewReferralTransactions && (
            <Modal
              isOpenModal={() => setViewReferralTransactions(true)}
              closeModal={() => setViewReferralTransactions(false)}
            >
              <div className="px-4 pt-[120px]">
                <div className="pb-5">
                  <h1 className="text-[22px] text-referral font-PoppinsSemiBold">
                    Referral Transaction History
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] text-disabledtext">
                      Below are the recent referral transaction activities in
                      your Affluence account
                    </p>
                  </div>
                </div>
                <div className="relative">
                  <div className="overflow-x-auto">
                    <div className="flex items-center gap-4 text-[12px] w-max">
                      <button
                        className={`py-2.5 px-4 rounded-[8px] ${
                          isinflow === undefined
                            ? "bg-referral text-white border-referral"
                            : "border border-transactionCard"
                        }`}
                        onClick={() => setIsInFlow(undefined)}
                      >
                        All
                      </button>
                      <button
                        className={`py-2.5 px-4 rounded-[8px] ${
                          isinflow === true
                            ? "bg-referral text-white border-referral"
                            : "border border-transactionCard"
                        }`}
                        onClick={() => setIsInFlow(true)}
                      >
                        Credit
                      </button>

                      <button
                        className={`py-2.5 px-4 rounded-[8px] ${
                          isinflow === false
                            ? "bg-referral text-white border-referral"
                            : "border border-transactionCard"
                        }`}
                        onClick={() => setIsInFlow(false)}
                      >
                        Debit
                      </button>
                    </div>
                  </div>
                </div>

                <div className="pb-2 ">
                  <DateFilter onFilter={handleFilter} />
                </div>
                {viewAllIsLoading ? (
                  <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                    <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                  </div>
                ) : (
                  <>
                    {filteredTransactions.length > 0 ? (
                      <div className="pt-4">
                        {filteredTransactions.map((item, index) => (
                          <div key={index}>
                            <TransactionCard
                              transactionActivity={
                                item.isInFlow
                                  ? "Credit - Referral Bonus"
                                  : "Debit - Withdrawal To Bank Account"
                              }
                              transactionTime={formatTransactionDate(
                                item.transactionDate
                              )}
                              isInFlow={item.isInFlow}
                              transactionAmount={formatCurrency(item.amount)}
                              icon={
                                item.isInFlow
                                  ? BsFillArrowUpRightCircleFill
                                  : BsFillArrowDownLeftCircleFill
                              }
                              iconClassname={
                                item.isInFlow ? "text-success" : "text-error"
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center py-5">
                        <div>
                          <div className="flex items-center justify-center">
                            <img
                              src={NoTransaction}
                              className="w-[200px]"
                              alt="illustrator"
                            />
                          </div>
                          <h1 className="font-PoppinsBold pb-3 text-[20px] text-referral">
                            No referral transaction currently!
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    currentPage < totalPages - 1 && (
                      <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronRight />
                      </span>
                    )
                  }
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={
                    currentPage > 0 && (
                      <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor bg-opacity-25 rounded-[4px]">
                        <BsChevronLeft />
                      </span>
                    )
                  }
                  renderOnZeroPageCount={null}
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="block border border-primary hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 h-8 dark:border-authbordercolor flex items-center justify-center rounded-md mr-3"
                  activeClassName="bg-primary text-white"
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default TabbedPage;
