import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./authPages/Login";
import Signup from "./authPages/Signup";
import ForgotPassword from "./authPages/ForgotPassword";
import ResetPassword from "./authPages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailVerification from "./authPages/EmailVerification";
import KYC from "./dashboard/KYC";
import HomePage from "./dashboard/Homepage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/store";
import AuthenticatedLayout from "./AuthenticatedLayout";
import Investment from "./dashboard/Investment";
// import TokenExpirationHandler from "./api/TokenExpirationHandler";
import FundWallet from "./dashboard/FundWallet";
import Savings from "./dashboard/Savings";
import TabbedPage from "./savings/SavingsTabContent";
import AccountPage from "./dashboard/AccountPage";
import { useEffect } from "react";
import { setTheme } from "./store/themeSlice";

// ADMIN URLs
import AdminDashboard from "./admin/dashboard/Home";
import InvestmentPlan from "./admin/dashboard/investment/InvestmentPlan";
import InvestmentLog from "./admin/dashboard/investment/InvestmentLog";
import AllCustomers from "./admin/dashboard/customers/AllCustomers";
import OneCustomer from "./admin/dashboard/customers/OneCustomer";
import TransactionLog from "./admin/dashboard/transaction/TransactionLog";
import PaymentGateway from "./admin/dashboard/transaction/PaymentGateway";
import InvestmentCategory from "./admin/dashboard/investment/InvestmentCategory";
import ViewInvestors from "./admin/dashboard/investment/ViewInvestors";
import Deposits from "./admin/dashboard/Deposits";
import Payouts from "./admin/dashboard/Payouts";
import Audit from "./admin/dashboard/Audit";
import Saving from "./admin/dashboard/Savings";
import { HttpClient } from "./api/HttpClient";
import { setIsAuthenticated, setRole } from "./store/authSlice";
import TokenExpirationHandler from "./api/TokenExpirationHandler";

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      dispatch(setTheme(theme === "dark"));
    } else {
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      dispatch(setTheme(prefersDark));
    }
  }, [dispatch]);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        const data = response.data;
        if (data) {
          dispatch(setIsAuthenticated(true));
          dispatch(setRole(data.role)); // Set the user's role in the Redux store
        }
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchUserRole();
  }, [dispatch]);
  return (
    <>
      <BrowserRouter>
        {/* <TokenExpirationHandler /> */}
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/sign_up" element={<Signup />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/verify" element={<EmailVerification />} />

          {isAuthenticated ? (
            <Route element={<AuthenticatedLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/kyc" element={<KYC />} />
              <Route path="/my_account" element={<AccountPage />} />
              <Route path="/fund_wallet" element={<FundWallet />} />
              <Route path="/savings" element={<Savings />} />
              <Route
                path="/investment/myinvestments"
                element={<Investment />}
              />
              <Route path="/investment/:tab" element={<Investment />} />
              <Route
                path="/investment/:tab/:minorTab"
                element={<Investment />}
              />
              <Route path="/savings/:tab" element={<TabbedPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}

          {/* ADMIN ROUTES */}
          <Route path="/admin/home" element={<AdminDashboard />} />

          <Route
            path="/admin/investment/investment_plan"
            element={<InvestmentPlan />}
          />
          <Route
            path="/admin/investment/investment_log"
            element={<InvestmentLog />}
          />
          <Route
            path="/admin/investment/add_category"
            element={<InvestmentCategory />}
          />
          <Route
            path="/admin/investment/investment_plan/view_investors/:investmentId"
            element={<ViewInvestors />}
          />
          <Route path="/admin/allCustomers" element={<AllCustomers />} />
          <Route
            path="/admin/allCustomers/oneCustomer/:customerId"
            element={<OneCustomer />}
          />
          <Route path="/admin/deposits" element={<Deposits />} />
          <Route path="/admin/payouts" element={<Payouts />} />
          <Route path="/admin/savings" element={<Saving />} />
          <Route
            path="/admin/transactions/transaction_log"
            element={<TransactionLog />}
          />
          <Route
            path="/admin/transactions/payment_gateway"
            element={<PaymentGateway />}
          />
          <Route path="/admin/audit" element={<Audit />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
