import React, { useEffect, useState } from "react";
import { AiOutlineBank } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import PageTitle from "../../../components/Pagetitle";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "chart.js/auto";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { LiaPercentageSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { HttpClient } from "../../../api/HttpClient";
import ToastComponent from "../../../components/ToastComponent";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { FaRegEye } from "react-icons/fa6";
import { CiImageOff } from "react-icons/ci";
import ProfilePicture from "../../../components/ProfilePicture";
import Modal from "react-modal";
import OneUserTransactChart from "./OneUserTransact";
import { ScaleLoader } from "react-spinners";
import { format } from "date-fns";
import ErrorHandler from "../../ErrorHandler";
import NoTransaction from "../../../assets/img/Wavy_Tech-31_Single-03.jpg";

ChartJS.register(ArcElement, Tooltip, Legend);

type OneUserAccount = {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  photo: string;
  emailVerificationStatus: boolean;
  account: {
    balance: number;
    investments: number;
    profit: number;
    referral: number;
    savings: number;
    deposit: number;
    withdrawal: number;
  };
  kyc: {
    _id: string;
    kycApprovalStatus?: boolean;

    kycLevelOneStatus: string;
    homeAddress: string;
    purposeOfInvestment: string;

    kycLevelTwoStatus: string;
    nextOfKinFullName: string;
    nextOfKinAddress: string;
    nextOfKinPhone: string;
    nextOfKinRelationship: string;

    kycLevelThreeStatus: string;
    identificationType: string;
    identificationNumber: string;
    uploadSelectedId: string;
    uploadProofOfAddress: string;
    uploadSelfieWithId: string;

    kycLevel: number;
  };
};

interface TransactionHistory {
  _id?: string;
  key?: string;
  transactionDate: string;
  transactionType: string;
  transactionRefCode: string;
  transactionId: string;
  amount: number;
  paymentStatus: string;
  isInFlow: string;
}
interface AuditHistory {
  _id?: string;
  key?: string;
  action: string;
  description: string;
  deviceInfo: string;
  createdAt: string;
  updatedAt: number;
}

interface InvestData {
  key: string;
  serialNumber: number;
  createdAt: string;
  name: string;
  roiExpected: number;
  description: string;
  totalPayable: number;
  numberOfUnits: number;
  amount: number;
  maturityDate: string;
  investmentTitle: string;
  investmentUnits: number;
  investmentDurationInDays: number;
}

interface InvestmentDetails {
  _id: string;
  title: string;
  description: string;
  units: number;
  amount: number;
  rate: number;
  durationNumber: number;
  durationInterval: string;
  durationInDays: number;
  unitsSold: number;
  unitsRemaining: number;
  numberOfInvestors: number;
  startDate: string;
  endDate: string;
  status: string;
  previewImage: string;
  claim: string;
  createdAt: string;
}

interface UserDetails {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface Investment {
  _id: string;
  investment_details: InvestmentDetails;
  user_details: UserDetails;
  amount: number;
  fundingSource: string;
  isRoiSettled: boolean;
  startDate: string;
  maturityDate: string;
  numberOfUnits: number;
  roiExpected: number;
  totalPayable: number;
  createdAt: string;
}

const OneCustomer = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const { customerId } = useParams<{
    customerId: string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<OneUserAccount | null>(null);
  const [kycStatus, setKycStatus] = useState<OneUserAccount | null>(null);
  const [transactHistory, setTransactHistory] = useState<TransactionHistory[]>(
    []
  );
  const [auditHistory, setAuditHistory] = useState<AuditHistory[]>([]);
  const [investHistory, setInvestHistory] = useState<InvestData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [comment, setComment] = useState("");
  const [paymentTypes, setPaymentTypes] = useState<string[]>([]);

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const userDataRes = await HttpClient.get(
        `/admin/view-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      const userAccountDetails = userDataRes.data.data.account;
      const userID = userAccountDetails?.user;

      const transHisRes = await HttpClient.get(
        `/transactions/user-history/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            page: currentPage,
            limit: pageSize,
            startdate: "",
            enddate: "",
            dir: "desc",
            sort: "amount",
            search: "",
          },
        }
      );

      const investDataRes = await HttpClient.get("/investments/booking", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          userId: userID,
        },
      });

      const auditHisRes = await HttpClient.get(`/users/audit-log`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          user: userID,
        },
      });

      const auditHis = auditHisRes.data.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          createdAt: item?.createdAt || "",
          updatedAt: item?.updatedAt || "",
          action: item?.action || "",
          description: item?.description || "",
          deviceInfo: item?.deviceInfo || "",
        })
      );

      const { data, total } = transHisRes.data.data;
      const transHis = data.map((item: any, index: number) => ({
        key: item._id,
        serialNumber: index + 1,
        transactionDate: item?.transactionDate || "",
        transactionType: item?.transactionType || "",
        transactionRefCode: item?.transactionRefCode || "",
        transactionId: item?.transactionId || "",
        amount: item?.amount || "",
        paymentStatus: item?.paymentStatus || "",
        isInFlow: item?.isInFlow || "",
      }));

      const allInvestments: Investment[] = investDataRes.data.data;
      const filteredInvestments = allInvestments.filter(
        (investment: Investment) => investment.user_details._id === userID
      );

      const investLog: InvestData[] = filteredInvestments.map(
        (item: Investment, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          createdAt: item?.createdAt,
          name:
            item?.user_details?.firstName + " " + item?.user_details?.lastName,
          roiExpected: item?.roiExpected,
          description: item?.investment_details?.description,
          totalPayable: item?.totalPayable,
          numberOfUnits: item?.numberOfUnits,
          amount: item?.amount,
          maturityDate: item?.maturityDate,
          investmentTitle: item?.investment_details?.title,
          investmentUnits: item?.investment_details?.units,
          investmentDurationInDays: item?.investment_details?.durationInDays,
        })
      );

      setAuditHistory(auditHis);
      setAccountData(userDataRes.data.data);
      setTransactHistory(transHis);
      setInvestHistory(investLog);
      setTotalRecords(total);
      setPaymentTypes(transHisRes.data.data.transactionType);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, customerId, pageSize]);

  const transactionColumns: ColumnsType<TransactionHistory> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "HH:mm:ss");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "transactionType",
      title: "Type",
      dataIndex: "transactionType",
      className: "capitalize",
      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder="Deposits"
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => {
      //         confirm();
      //       }}
      //       style={{ marginBottom: 8, display: "block" }}
      //     />
      //     <div style={{ display: "flex", justifyContent: "space-between" }}>
      //       <button
      //         onClick={() => {
      //           if (clearFilters) {
      //             clearFilters();
      //           }
      //           confirm();
      //         }}
      //         style={{ width: "48%" }}
      //       >
      //         Reset
      //       </button>
      //       <button
      //         onClick={() => {
      //           confirm();
      //         }}
      //         style={{ width: "48%" }}
      //       >
      //         Apply
      //       </button>
      //     </div>
      //   </div>
      // ),
      // onFilter: (value, record) =>
      //   record.transactionType.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "transactionRefCode",
      title: "Description",
      dataIndex: "transactionRefCode",
    },
    {
      key: "isInFlow",
      title: "DR/CR",
      dataIndex: "isInFlow",
      render: (isInFlow: boolean) => (
        <span
          className={`font-PoppinsRegular ${
            isInFlow ? "text-success" : "text-error"
          }`}
        >
          {isInFlow ? "Credit" : "Debit"}
        </span>
      ),
    },
    {
      key: "transactionId",
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (paymentStatus: string) => (
        <span
          className={`px-[0.9rem] py-[0.35rem] capitalize rounded-[30px] bg-opacity-[28%] text-center ${
            paymentStatus === "pending"
              ? "bg-realSecondary"
              : paymentStatus === "successful"
              ? "bg-success"
              : "bg-error"
          } ${
            paymentStatus === "pending"
              ? "text-realSecondary"
              : paymentStatus === "successful"
              ? "text-success"
              : "text-error"
          }`}
        >
          {paymentStatus}
        </span>
      ),
    },
  ];

  const auditColumns: ColumnsType<AuditHistory> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Created Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "HH:mm:ss");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated Date",
      dataIndex: "updatedAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "HH:mm:ss");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "capitalize",
      render: (text: string) =>
        text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "deviceInfo",
      title: "Device Information",
      dataIndex: "deviceInfo",
    },
  ];

  const investColumns: ColumnsType<InvestData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "HH:mm:ss");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "investmentTitle",
      title: "Title",
      dataIndex: "investmentTitle",
    },
    {
      key: "investmentUnits",
      title: "Units",
      dataIndex: "investmentUnits",
    },
    {
      key: "numberOfUnits",
      title: "Units Purchased",
      dataIndex: "numberOfUnits",
    },
    {
      key: "maturityDate",
      title: "Maturity Date",
      dataIndex: "maturityDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
          </div>
        );
      },
    },

    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => `₦${Number(amount).toLocaleString()}`,
    },
    {
      key: "roiExpected",
      title: "ROI",
      dataIndex: "roiExpected",
      render: (roiExpected: number) =>
        `₦${Number(roiExpected).toLocaleString()}`,
    },
    {
      key: "totalPayable",
      title: "Total Payable",
      dataIndex: "totalPayable",
      render: (totalPayable: number) =>
        `₦${Number(totalPayable).toLocaleString()}`,
    },
  ];

  const handleNavigateToAllCustomers = () => {
    navigate("/admin/allCustomers");
  };

  const handleImageClick = (initialValues: any) => {
    setModalImage(initialValues);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage("");
  };

  // KYC APPROVAL
  const handleApproval = async (val: string) => {
    setLoading(true);
    try {
      const userDataRes = await HttpClient.get(
        `/admin/view-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      const userData = userDataRes.data.data as OneUserAccount;
      const kycDetails = userData.kyc;
      const kycID = kycDetails?._id;

      if (!kycID) {
        throw new Error("No KYC available for approval.");
      }

      const approveRes = await HttpClient.patch(
        `/admin/kyc-approval/${kycID}/${val}`,
        {},
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      setKycStatus(approveRes.data.data.kyc);
      ToastComponent.success(approveRes.data.message);
      await fetchData();
    } catch (error: any) {
      if (error.message === "No KYC available for approval.") {
        ToastComponent.error(error.message);
      } else {
        ErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  //  KYC REJECTION
  const handleRejection = async (val: string) => {
    setLoading(true);
    try {
      const userDataRes = await HttpClient.get(
        `/admin/view-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const kycDetails = userDataRes.data.data.kyc;
      const kycID = kycDetails?._id;

      if (!kycID) {
        throw new Error("No KYC available for rejection.");
      }

      const approveRes = await HttpClient.patch(
        `/admin/kyc-rejection/${kycID}/${val}`,
        { comment: comment },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      setKycStatus(approveRes.data.data.kyc);
      ToastComponent.success(approveRes.data.message);
      await fetchData();
    } catch (error: any) {
      if (error.message === "No KYC available for rejection.") {
        ToastComponent.error(error.message);
      } else {
        ErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRejectReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const generateOTP = async () => {
    try {
      const res = await HttpClient.post("/users/generate-token", {
        email: accountData?.email,
      });
      ToastComponent.success(res.data.message);
    } catch (error) {}
  };

  const pagination = {
    current: currentPage,
    pageSize: pageSize,
    total: totalRecords,
  };

  return (
    <>
      <PageTitle
        title={`Affluence - Admin: ${accountData?.firstName} ${accountData?.lastName}`}
      />
      <div className="flex h-screen">
        <Sidebar />
        {loading ? (
          <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
            <ScaleLoader color="#6B006B" />
          </div>
        ) : (
          <div className="flex-1 m-4 overflow-y-auto">
            <div className="flex justify-end mt-10">
              <button
                className={`py-2 px-4 rounded-md align-middle border text-primary border-primary`}
                onClick={handleNavigateToAllCustomers}
              >
                <FaRegEye size={20} className="inline mr-2" />
                View all customers
              </button>
            </div>
            <div className="flex items-center justify-between mt-8">
              <span className="text-bold text-textcolor font-PoppinsBold">
                {accountData?.firstName} {accountData?.lastName}
              </span>
              <ProfilePicture profilePics={accountData?.photo || ""} />
            </div>

            <div className="grid grid-cols-3 gap-4 mt-8">
              {/* Wallet Balance Card */}
              <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                  <div className="flex items-center gap-2">
                    <MdOutlineAccountBalanceWallet size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Wallet Balance
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.account?.balance
                          ? formatCurrency(accountData.account.balance)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              {/* Total Investment Card */}
              <div className="bg-investment bg-opacity-5 p-5 rounded-[8px] border border-investment">
                <div className="bg-investment text-white p-5 h-[120px] block rounded-[8px]">
                  <div className="flex items-center gap-2">
                    <AiOutlineBank size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Total Investment
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.account?.investments
                          ? formatCurrency(accountData.account.investments)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              {/* Profit */}
              <div className="bg-secondary bg-opacity-5 p-5 rounded-[8px] border border-secondary">
                <div className="bg-secondary text-white p-5 h-[120px] block rounded-[8px]">
                  <div className="flex items-center gap-2">
                    <LiaPercentageSolid size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Total Profit
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.account?.profit
                          ? formatCurrency(accountData.account.profit)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-8">
              {/* Total Deposit Card */}
              <div className="bg-deposit bg-opacity-5 p-5 rounded-[8px] border border-deposit">
                <div className="bg-deposit text-white p-5 h-[120px] block rounded-[8px]">
                  <div className="flex items-center gap-2">
                    <PiHandDepositBold size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Total Deposit
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.account?.deposit
                          ? formatCurrency(accountData.account.deposit)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              {/* Total Withdrawal */}
              <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                  <div className="flex items-center gap-2">
                    <PiHandWithdrawLight size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Total Withdrawal
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.account?.withdrawal
                          ? formatCurrency(accountData.account.withdrawal)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              {/* Total Referral */}
              <div className="bg-referral bg-opacity-5 p-5 rounded-[8px] border border-referral">
                <div className="bg-referral text-white p-5 h-[120px] block rounded-[8px]">
                  <div className="flex items-center gap-2">
                    <GiTakeMyMoney size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Total Referral
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.account?.referral
                          ? formatCurrency(accountData.account.referral)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Transaction History */}
            <OneUserTransactChart />

            {/* Bio Data and Wallet Fund */}
            <div className="grid grid-cols-2 gap-4 mt-8">
              <div className="border border-transactionCard rounded-md p-5">
                <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  Bio Data
                </span>
                <form className="w-full">
                  <div className="mb-6">
                    <label
                      htmlFor="username"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={accountData?.username || ""}
                      className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="fullName"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      value={`${accountData?.firstName || ""} ${
                        accountData?.lastName || ""
                      }`}
                      className="w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={accountData?.email || ""}
                      className="w-full h-12 border border-transactionCard font-PoppinsRegular text-textcolor px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="phone"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={accountData?.phone || ""}
                      className="w-full h-12 border border-transactionCard font-PoppinsRegular text-textcolor px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-4 flex items-center gap-8">
                    <label
                      htmlFor="status"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Email Verification
                    </label>

                    <div className="flex">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          name="emailVerification"
                          checked={
                            accountData?.emailVerificationStatus === true
                              ? true
                              : false
                          }
                          className="w-6 h-6 bg-transactionCard border border-transactionCard rounded-lg checked:bg-success checked:border-success"
                        />
                        <span className="ml-2 text-textcolor font-PoppinsRegular">
                          Email verified
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* Wallet Funding */}
              <div className="border border-transactionCard rounded-md p-5">
                <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  Wallet Funding
                </span>

                <form className="w-full">
                  <div className="mb-6">
                    <label
                      htmlFor="amount"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      name="amount"
                      placeholder="₦0.00"
                      // value=""
                      className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="amount"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Choose Funding Destination
                    </label>
                    <select
                      name="amount"
                      className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    >
                      <option value="wallet">Balance</option>
                      <option value="investment">Investment</option>
                      <option value="deposits">Deposits</option>
                      <option value="savings">Savings</option>
                      <option value="referral">Referrals</option>
                    </select>
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="amount"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Choose Action
                    </label>
                    <select
                      name="amount"
                      className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    >
                      <option value="credit">Credit</option>
                      <option value="debit">Debit</option>
                    </select>
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="phone"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Enter Account Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="********"
                      // value=""
                      className="w-full h-12 border border-transactionCard font-PoppinsRegular text-textcolor px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Enter Transaction Pin
                    </label>
                    <input
                      type="pin"
                      name="transactionPin"
                      placeholder="****"
                      // value=""
                      className="w-full h-12 border border-transactionCard font-PoppinsRegular text-textcolor px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="fullName"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Enter OTP
                    </label>
                    <input
                      type="pin"
                      name="otp"
                      placeholder="1W2E3RR"
                      // value=""
                      className="w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />

                    <button
                      // onClick={generateOTP}
                      type="button"
                      className="mb-5 font-PoppinsLight hover:text-primary text-textcolor text-[13px] underline"
                    >
                      Click to generate OTP
                    </button>
                  </div>

                  <button
                    type="submit"
                    className="py-2 px-4 border border-success text-success font-PoppinsMedium rounded-lg"
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>

            {/* KYC 1 Verification */}
            <div className="border border-transactionCard rounded-md p-5 mt-8">
              <div className="flex gap-20">
                <div className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  KYC Level 1 Verification
                </div>
                <div>
                  <div
                    className={`text-[0.8em] rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] capitalize ${
                      accountData?.kyc?.kycLevelOneStatus === "rejected"
                        ? "bg-error text-error"
                        : accountData?.kyc?.kycLevelOneStatus === "approved"
                        ? "bg-success text-success"
                        : accountData?.kyc?.kycLevelOneStatus === "pending"
                        ? "bg-secondary text-secondary"
                        : ""
                    } `}
                  >
                    {accountData?.kyc?.kycLevelOneStatus}
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-20">
                <div className="block">
                  <div className="mb-6">
                    <label
                      htmlFor="homeAddress"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Home Address
                    </label>
                    <input
                      type="text"
                      name="homeAddress"
                      value={accountData?.kyc?.homeAddress || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="purposeOfInvestment"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Investment Purpose
                    </label>
                    <input
                      type="text"
                      name="purposeOfInvestment"
                      value={accountData?.kyc?.purposeOfInvestment || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>
                </div>

                <div className="block">
                  <div className="mb-6">
                    <label
                      htmlFor="comment"
                      className="block text-textcolor font-PoppinsLight text-[14px] mb-1"
                    >
                      Reason(s) for Rejection
                    </label>
                    <input
                      type="text"
                      name="comment"
                      onChange={handleRejectReasonChange}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="flex gap-4">
                    <button
                      type="submit"
                      onClick={() => handleApproval("1")}
                      className="py-2 px-4 border border-success text-success font-PoppinsMedium rounded-lg"
                    >
                      Approve
                    </button>
                    <button
                      type="submit"
                      onClick={() => handleRejection("1")}
                      className="py-2 px-6 border border-error text-error font-PoppinsMedium rounded-lg"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* KYC 2 Verification */}
            <div className="border border-transactionCard rounded-md p-5 mt-8">
              <div className="flex gap-x-20">
                <div className="text-semibold text-textcolor font-PoppinsSemiBold mb-4">
                  KYC Level 2 Verification
                </div>
                <div>
                  <div
                    className={`text-[0.8em] rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] capitalize ${
                      accountData?.kyc?.kycLevelTwoStatus === "rejected"
                        ? "bg-error text-error"
                        : accountData?.kyc?.kycLevelTwoStatus === "approved"
                        ? "bg-success text-success"
                        : accountData?.kyc?.kycLevelTwoStatus === "pending"
                        ? "bg-secondary text-secondary"
                        : ""
                    } `}
                  >
                    {accountData?.kyc?.kycLevelTwoStatus}
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-20">
                <div className="block">
                  <div className="mb-6">
                    <label
                      htmlFor="nextOfKinFullName"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Name of Next-of-kin
                    </label>
                    <input
                      type="text"
                      name="nextOfKinFullName"
                      value={accountData?.kyc?.nextOfKinFullName || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="nextOfKinAddress"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Contact Address
                    </label>
                    <input
                      type="text"
                      name="nextOfKinAddress"
                      value={accountData?.kyc?.nextOfKinAddress || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="nextOfKinPhone"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="nextOfKinPhone"
                      value={accountData?.kyc?.nextOfKinPhone || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="nextOfKinRelationship"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Relationship
                    </label>
                    <input
                      type="text"
                      name="nextOfKinRelationship"
                      value={accountData?.kyc?.nextOfKinRelationship || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>
                </div>

                <div className="block">
                  <div className="mb-6">
                    <label
                      htmlFor="comment"
                      className="block text-textcolor font-PoppinsLight text-[14px] mb-1"
                    >
                      Reason(s) for Rejection
                    </label>
                    <input
                      type="text"
                      name="comment"
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="flex gap-4">
                    <button
                      type="submit"
                      onClick={() => handleApproval("2")}
                      className="py-2 px-4 border border-success text-success font-PoppinsMedium rounded-lg"
                    >
                      Approve
                    </button>
                    <button
                      type="submit"
                      className="py-2 px-6 border border-error text-error font-PoppinsMedium rounded-lg"
                      onClick={() => handleRejection("2")}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* KYC 3 Verification */}
            <div className="border border-transactionCard rounded-md p-5 mt-8">
              <div className="flex gap-x-20">
                <div className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  KYC Level 3 Verification
                </div>
                <div>
                  <div
                    className={`text-[0.8em] rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] capitalize ${
                      accountData?.kyc?.kycLevelThreeStatus === "rejected"
                        ? "bg-error text-error"
                        : accountData?.kyc?.kycLevelThreeStatus === "approved"
                        ? "bg-success text-success"
                        : accountData?.kyc?.kycLevelThreeStatus === "pending"
                        ? "bg-secondary text-secondary"
                        : ""
                    } `}
                  >
                    {accountData?.kyc?.kycLevelThreeStatus}
                  </div>
                </div>
              </div>

              <div className="flex gap-20 justify-between">
                {/* Image Column */}
                <div className="flex flex-col gap-6">
                  {/* ID card */}
                  <div className="block">
                    <div className="mb-4">
                      <span className="block text-primary font-PoppinsLight text-sm mb-1">
                        ID Card-{" "}
                        <span className="text-primary font-PoppinsRegular text-sm">
                          {accountData?.kyc?.identificationType ||
                            "Not available"}
                        </span>
                      </span>
                    </div>
                    <div className="w-[300px] h-[200px] p-2 border border-transactionCard rounded-lg flex items-center justify-center cursor-pointer">
                      {accountData?.kyc?.uploadSelectedId ? (
                        <img
                          src={accountData?.kyc?.uploadSelectedId}
                          alt="ID card"
                          className="w-full h-full object-cover p-2 rounded-lg"
                          onClick={() =>
                            handleImageClick(accountData?.kyc?.uploadSelectedId)
                          }
                        />
                      ) : (
                        <div className="flex text-textcolor justify-center items-center">
                          <CiImageOff size={20} />
                          <p>ID card Not Available</p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Proof of Address */}
                  <div className="block">
                    <div className="mb-4">
                      <span className="block text-primary font-PoppinsLight text-sm mb-1">
                        Proof of Address
                      </span>
                      <div className="w-[300px] h-[200px] border border-transactionCard p-2 rounded-lg flex items-center justify-center cursor-pointer">
                        {accountData?.kyc?.uploadProofOfAddress ? (
                          <img
                            src={accountData?.kyc?.uploadProofOfAddress}
                            alt="Proof of Address"
                            className="w-full h-full object-cover p-2 rounded-lg"
                            onClick={() =>
                              handleImageClick(
                                accountData?.kyc?.uploadProofOfAddress
                              )
                            }
                          />
                        ) : (
                          <div className="flex p-2 justify-center items-center text-textcolor">
                            <CiImageOff size={20} />
                            <p>Proof of Address Not Available</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* ID with Selfie */}
                  <div className="block">
                    <div className="mb-4">
                      <span className="block text-primary font-PoppinsLight text-sm mb-1">
                        ID Card with Selfie
                      </span>
                      <div className="w-[300px] h-[200px] border border-transactionCard p-2 rounded-lg flex items-center justify-center cursor-pointer">
                        {accountData?.kyc?.uploadSelfieWithId ? (
                          <img
                            src={accountData?.kyc?.uploadSelfieWithId}
                            alt="ID card with a Selfie"
                            className="w-full h-full object-cover p-2 rounded-lg"
                            onClick={() =>
                              handleImageClick(
                                accountData?.kyc?.uploadSelfieWithId
                              )
                            }
                          />
                        ) : (
                          <div className="flex text-textcolor justify-center items-center">
                            <CiImageOff size={20} />
                            <p>ID Card with Selfie Not Available</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form Column */}
                <div className="flex flex-col gap-6">
                  <div className="mb-6">
                    <label
                      htmlFor="identificationType"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Identification Type
                    </label>
                    <input
                      type="text"
                      name="identificationType"
                      value={accountData?.kyc?.identificationType || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="identificationNumber"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Identification Number
                    </label>
                    <input
                      type="text"
                      name="identificationNumber"
                      value={accountData?.kyc?.identificationNumber || ""}
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="comment"
                      className="block text-textcolor font-PoppinsLight text-[14px] mb-1"
                    >
                      Reason(s) for Rejection
                    </label>
                    <input
                      type="text"
                      name="comment"
                      className="w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="flex gap-4">
                    <button
                      type="submit"
                      onClick={() => handleApproval("3")}
                      className="py-2 px-4 border border-success text-success font-PoppinsMedium rounded-lg"
                    >
                      Approve
                    </button>
                    <button
                      type="submit"
                      onClick={() => handleRejection("3")}
                      className="py-2 px-6 border border-error text-error font-PoppinsMedium rounded-lg"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Approve All and Reject All Buttons */}
            <div className="flex gap-4 mt-8">
              <button
                type="submit"
                className="py-2 px-4  bg-success text-white font-PoppinsMedium rounded-md"
                onClick={() => handleApproval("all")}
              >
                Approve All KYC
              </button>
              <button
                type="reset"
                className="py-2 px-6 bg-error text-white font-PoppinsMedium rounded-md"
                onClick={() => handleRejection("all")}
              >
                Reject All KYC
              </button>
            </div>

            {/* Transaction History */}
            <div className="border border-transactionCard rounded-md p-5 mt-8">
              <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                Transaction History
              </span>
              {transactHistory && transactHistory.length > 0 ? (
                <div className="overflow-x-auto">
                  <Table
                    dataSource={transactHistory}
                    columns={transactionColumns}
                    pagination={pagination}
                    loading={loading}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center py-5">
                  <div>
                    <div className="flex items-center justify-center">
                      <img
                        src={NoTransaction}
                        className="w-[200px]"
                        alt="illustrator"
                      />
                    </div>
                    <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                      No Transaction History currently!
                    </h1>
                  </div>
                </div>
              )}
            </div>

            {/* Audit History */}
            <div className="border border-transactionCard rounded-md p-5 mt-8">
              <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                Audit History
              </span>
              {auditHistory && auditHistory.length > 0 ? (
                <div className="overflow-x-auto">
                  <Table
                    dataSource={auditHistory}
                    columns={auditColumns}
                    loading={loading}
                    pagination={{ pageSize: 5 }}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center py-5">
                  <div>
                    <div className="flex items-center justify-center">
                      <img
                        src={NoTransaction}
                        className="w-[200px]"
                        alt="illustrator"
                      />
                    </div>
                    <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                      No Audit History currently!
                    </h1>
                  </div>
                </div>
              )}
            </div>

            {/* Investment History */}
            <div className="border border-transactionCard rounded-md p-5 mt-8">
              <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                Investment History
              </span>
              {investHistory && investHistory.length > 0 ? (
                <div className="overflow-x-auto">
                  <Table
                    dataSource={investHistory}
                    columns={investColumns}
                    pagination={pagination}
                    loading={loading}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center py-5">
                  <div>
                    <div className="flex items-center justify-center">
                      <img
                        src={NoTransaction}
                        className="w-[200px]"
                        alt="illustrator"
                      />
                    </div>
                    <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                      No Investment History currently!
                    </h1>
                  </div>
                </div>
              )}
            </div>

            {/* Modal for image preview */}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className="fixed inset-0 bg-[black] bg-opacity-[50%] flex items-center justify-center"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50"
              ariaHideApp={false}
            >
              <div
                className="p-4 rounded-lg flex items-center justify-center"
                onClick={closeModal}
              >
                <img
                  src={modalImage}
                  alt="Preview"
                  className="w-[50%] h-[50%] object-contain rounded-lg cursor-pointer"
                />
              </div>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};

export default OneCustomer;
