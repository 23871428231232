import { FC } from "react";

interface ProfilePictureProps {
  profilePics: string | undefined;
  profilePicsClass?: string;
  onClick?: () => void;
}
const ProfilePicture: FC<ProfilePictureProps> = ({
  profilePics,
  profilePicsClass,
  onClick,
}) => {
  return (
    <div>
      <div
        className={`h-10 w-10 flex items-center justify-center rounded-full overflow-hidden relative ${profilePicsClass}`}
        onClick={onClick}
      >
        <img
          src={profilePics}
          className="w-full h-full aspect-square object-cover"
          alt="profile-picture"
        />
      </div>
    </div>
  );
};

export default ProfilePicture;

