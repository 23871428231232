import { FC } from "react";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

interface MyInvestmentCardProps {
  onClick?: () => void;
  investmentImg: string;
  title: string;
  amountExpected: string;
  rate: number;
  amount: string;
  units: number;
  status?: string;
  maturityDate?: string;
}
const MyInvestmentCard: FC<MyInvestmentCardProps> = ({
  onClick,
  investmentImg,
  title,
  amount,
  amountExpected,
  rate,
  units,
  status,
  maturityDate,
}) => {
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  return (
    <div
      className="border-y-[1.5px] cursor-pointer w-full border-transactionCard dark:border-textcolor mb-2 py-2 dark:shadow-[#000000] shadow-transactionCard shadow-sm"
      onClick={onClick}
    >
      <div className="flex items-center gap-[50px] px-[3%]">
        <div className="w-[200px] h-[150px] relative">
          <img
            src={investmentImg}
            className="object-cover w-full h-full rounded-[10px]"
            alt="investment-img"
          />
        </div>
        <div>
          <h1 className="font-PoppinsSemiBold uppercase text-[18px] pb-2">
            {title}
          </h1>
          <div className="flex items-center gap-9 py-2">
            <div>
              <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                AMOUNT INVESTED
              </h1>
              <h1 className=" font-PoppinsSemiBold text-[12px] text-primary dark:text-white">
                {` ${amount} (${units} units)`}
              </h1>
            </div>
            <div>
              <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                ROI
              </h1>
              <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                {rate}%
              </h1>
            </div>
          </div>
          <div className="flex items-center gap-9">
            <div>
              <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                AMOUNT EXPECTED
              </h1>
              <h1 className=" font-PoppinsSemiBold text-[12px] text-primary dark:text-white">
                {amountExpected}
              </h1>
            </div>
            <div>
              <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                MATURITY DATE
              </h1>
              <h1 className=" font-PoppinsSemiBold text-[12px] text-primary dark:text-white">
                {maturityDate}
              </h1>
            </div>
          </div>
          <div
            className={`${
              status === "running"
                ? "text-referral bg-referral"
                : status === "matured"
                ? isDarkMode
                  ? "text-[#C8A2C8] bg-[#C8A2C8]"
                  : "text-primary bg-primary"
                : status === "pending"
                ? "text-secondary bg-secondary"
                : "text-error bg-error"
            } bg-opacity-[28%] inline-block py-1 px-4 rounded-[10px] font-PoppinsMedium text-[10px]`}
          >
            {status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInvestmentCard;
