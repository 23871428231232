"use client";
import { FC } from "react";
import { MoonLoader } from "react-spinners";
interface AuthButtonProps {
  isLoading: boolean;
  disabled: boolean;
  children: any;
}
const AuthButton: FC<AuthButtonProps> = ({ isLoading, disabled, children }) => {
  const baseStyles =
    "sm:p-3 p-2.5 rounded-[8px] w-full font-PoppinsBold text-[14px] flex justify-center items-center";
  const enabledStyles = "bg-primary text-white border border-primary";
  const disabledStyles =
    "bg-disabled text-disabledtext border border-authbordercolor cursor-not-allowed";

  return (
    <button
      type="submit"
      className={`${baseStyles} ${disabled ? disabledStyles : enabledStyles}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>
          <MoonLoader color="#ffffff" size={17} />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default AuthButton;
