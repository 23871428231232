import { FC } from "react";
import { IconType } from "react-icons";

interface TransactionCardProps {
  transactionActivity: string;
  transactionTime: string;
  transactionAmount: string;
  icon?: IconType;
  iconClassname: string;
  isInFlow: boolean;
}
const TransactionCard: FC<TransactionCardProps> = ({
  transactionActivity,
  transactionTime,
  transactionAmount,
  icon: IconComponent,
  iconClassname,
  isInFlow,
}) => {
  const amountSign = isInFlow ? "+" : "-";
  return (
    <div className="border border-transactionCard mb-3 rounded-[8px] flex justify-between p-4">
      <div className="flex items-center gap-5">
        <div className={iconClassname}>
          {IconComponent && <IconComponent size={22} className="icon" />}
        </div>
        <div className="font-PoppinsMedium sm:text-[15px] text-[13px]">
          <h1 className="dark:text-white">{transactionActivity}</h1>
          <h1 className="text-disabledtext text-[12px]">{transactionTime}</h1>
        </div>
      </div>
      <div>
        <h1
          className={`font-PoppinsMedium sm:text-[15px] text-[13px] ${
            isInFlow ? "text-success" : "text-error"
          }`}
        >
          {amountSign} {transactionAmount}
        </h1>
      </div>
    </div>
  );
};

export default TransactionCard;
