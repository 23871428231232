import React, { useRef, useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { LiaIdCardSolid } from "react-icons/lia";

interface CustomFileInputProps {
  label: string;
  name: string;
  initialPreviewUrl?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInput: React.FC<CustomFileInputProps> = ({
  label,
  name,
  initialPreviewUrl,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField<File | null>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    initialPreviewUrl || null
  );
  const [fileName, setFileName] = useState<string | null>(null);

  const handleSelectFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files && event.currentTarget.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      helpers.setValue(file);
      setFieldValue(name, file);
      console.log(fileUrl);
      setPreviewUrl(fileUrl);

      if (
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "application/pdf"
      ) {
        setFileName(file.name);
      } else {
        setPreviewUrl(null);
        setFileName(null);
      }
    } else {
      setPreviewUrl(null);
      setFileName(null);
    }
  };

  useEffect(() => {
    if (initialPreviewUrl) {
      setPreviewUrl(initialPreviewUrl);
    }
  }, [initialPreviewUrl]);

  return (
    <div style={{ width: "300px" }}>
      <div
        onClick={handleSelectFile}
        className="border-dashed border relative flex items-center justify-center border-primary bg-primary bg-opacity-[5%]"
        style={{
          cursor: "pointer",
          padding: "10px",
          width: "300px",
          height: "200px",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        {previewUrl ? (
          previewUrl.startsWith("blob:") && fileName ? (
            previewUrl.includes(".pdf") ? (
              <div
                style={{ width: "100%", height: "100%" }}
                className="flex items-center justify-center"
              >
                <div className="text-wrap break-all font-PoppinsLight text-[14px] text-textcolor">
                  {fileName}
                </div>
              </div>
            ) : (
              <img
                src={previewUrl}
                alt="Selected file"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            )
          ) : (
            <img
              src={previewUrl}
              alt="Fetched file"
              style={{ maxWidth: "100%", maxHeight: "200px" }}
            />
          )
        ) : (
          <div className="text-[14px] flex flex-col items-center font-PoppinsLight text-center text-textcolor">
            <LiaIdCardSolid
              size={35}
              className="text-primary opacity-50 mb-3"
            />
            <div>{label}</div>
            <div className="text-disabledtext">JPG, PNG & PDF supported</div>
          </div>
        )}
        <input
          type="file"
          id={name}
          name={name}
          accept=".jpg,.jpeg,.png,.pdf"
          onChange={handleChange}
          ref={inputRef}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

export default FileInput;
