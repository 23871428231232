import { useEffect, useState } from "react";
import { Table, Input, Menu, Dropdown } from "antd";
import Sidebar from "../Sidebar";
import { ColumnsType } from "antd/es/table";
import { IoMdAdd } from "react-icons/io";
import CreatePlan from "./CreatePlan";
import ToastComponent from "../../../components/ToastComponent";
import { FaRegEye } from "react-icons/fa";
import PageTitle from "../../../components/Pagetitle";
import { HttpClient } from "../../../api/HttpClient";
import { ScaleLoader } from "react-spinners";
import { format } from "date-fns";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { PiUsersThree } from "react-icons/pi";
import Errorhandler from "../../ErrorHandler";

interface InvestData {
  _id: string;
  key?: string;
  title: string;
  category: string;
  investmentDisclaimer: string;
  rate: number;
  durationNumber: number;
  durationInterval: string;
  units: number;
  amount: number;
  startDate: string;
  endDate: string;
  status: string;
  numberOfInvestors: number;
}

function InvestmentPlan() {
  const [data, setData] = useState<InvestData[]>([]);
  const [isCreatePlanVisible, setCreatePlanVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState<InvestData | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const [filteredInvest, setFilteredInvest] = useState<InvestData[]>([]);
  const [searchCat, setSearchCat] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [fetchInvest, setFetchInvest] = useState<InvestData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const investRes = await HttpClient.get("/investments/plan", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });

        const investments = investRes.data.data.data.map(
          (item: any, index: number) => ({
            key: item._id,
            serialNumber: index + 1,
            title: item.title,
            category: item.category,
            investmentDisclaimer: item.investmentDisclaimer,
            amount: item.amount,
            rate: item.rate,
            durationNumber: item.durationNumber,
            durationInterval: item.durationInterval,
            units: item.units,
            status: item.status,
            startDate: item.startDate,
            endDate: item.endDate,
            numberOfInvestors: item.numberOfInvestors,
          })
        );

        setData(investments);
        setFetchInvest(investments);
      } catch (error: any) {
        Errorhandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = fetchInvest.filter((item: InvestData) => {
      const category = item.category.toLowerCase();
      const title = item.title.toLowerCase();
      const status = item.status.toLowerCase();
      return (
        category.includes(searchCat.toLowerCase()) &&
        title.includes(searchTitle.toLowerCase()) &&
        status.includes(searchStatus.toLowerCase())
      );
    });
    setFilteredInvest(filteredData);
  }, [searchCat, searchTitle, searchStatus, data]);

  const handleToggleView = () => {
    setCreatePlanVisible((prev) => !prev);
  };

  const handleEdit = (record: InvestData) => {
    setEditingRecord(record);
    setCreatePlanVisible(true);
  };

  const handleViewInvestment = (e: any, investmentId: string) => {
    if (e.key === "viewInvestors") {
      navigate(
        `/admin/investment/investment_plan/view_investors/${investmentId}`
      );
    }
  };

  const menu = (investment: InvestData) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            handleViewInvestment({ key: "viewInvestors" }, investment.key!)
          }
          key="viewInvestors"
          className="relative group"
        >
          <Link
            to={`/admin/investment/investment_plan/view_investors/${investment.key}`}
            className="flex gap-2 items-center"
          >
            <PiUsersThree size={20} />
            <span className="text-textcolor font-PoppinsRegular">
              View Investors {" "} ({investment?.numberOfInvestors || 0} Investors)
            </span>
          </Link>
          <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
        </Menu.Item>

        <Menu.Item key="viewInvestors" className="relative group">
          <div className="flex gap-2 items-center">
            <MdOutlineMarkEmailUnread size={20} />
            <span className="text-textcolor font-PoppinsRegular">
              Send Mail
            </span>
          </div>
          <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<InvestData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      render: (text: string, record: InvestData) => (
        <div className="flex items-center gap-6">
          <span>{text}</span>
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              className="hover:text-primary border rounded-full text-xxs p-0.2"
            >
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      ),
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Apply
            </button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.title.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Category"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Apply
            </button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.category.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "investmentDisclaimer",
      title: "Disclaimer",
      dataIndex: "investmentDisclaimer",
    },
    {
      key: "amount",
      title: "Price",
      dataIndex: "amount",
      render: (amount: number) => `₦${Number(amount).toLocaleString()}`,
    },
    {
      key: "rate",
      title: "Rate",
      dataIndex: "rate",
      render: (rate: number) => `${rate}%`,
    },
    {
      key: "durationNumber",
      title: "Duration",
      dataIndex: "durationNumber",
      render: (number: string, item: any) => {
        const interval = item.durationInterval || "";
        return `${number} ${interval}`;
      },
    },

    {
      key: "units",
      title: "Unit",
      dataIndex: "units",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: 120,
      render: (status: string) => (
        <span
          className={`px-[0.9rem] py-[0.35rem] capitalize rounded-[30px] bg-opacity-[28%] text-center ${
            status === "pending"
              ? "bg-realSecondary"
              : status === "running"
              ? "bg-success"
              : status === "matured"
              ? "bg-primary"
              : "bg-error"
          } ${
            status === "pending"
              ? "text-realSecondary"
              : status === "running"
              ? "text-success"
              : status === "matured"
              ? "text-primary"
              : "text-error"
          }`}
        >
          {status}
        </span>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Status"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Enter
            </button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.status.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: "startDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
          </div>
        );
      },
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: "endDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
          </div>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, record: InvestData) => (
        <div className="flex space-x-2" key={record.key}>
          <button
            className="bg-success text-white  px-5 py-1 rounded-md"
            onClick={() => handleEdit(record)}
          >
            Edit
          </button>
          <button className="bg-error text-white px-3 py-1 rounded-md">
            Delete
          </button>
        </div>
      ),
    },
  ];

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      <PageTitle title="Affluence - Admin: Investment Plan" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="flex-1 p-4 mt-28 shadow-md border border-transactionCard rounded-lg mx-4">
              <div className="flex justify-between">
                <span className="font-PoppinsBold text-textcolor text-semibold">
                  Investment Plans
                </span>
                <button
                  className={`py-2 px-4 rounded-md align-middle ${
                    isCreatePlanVisible
                      ? "border border-primary text-primary"
                      : "bg-primary text-white"
                  }`}
                  onClick={() => handleToggleView()}
                >
                  {isCreatePlanVisible ? (
                    <>
                      <FaRegEye size={20} className="inline mr-2 " />
                      View Investment Plans
                    </>
                  ) : (
                    <>
                      <IoMdAdd className="inline mr-2 text-white" size={20} />
                      Add Investment Plan
                    </>
                  )}
                </button>
              </div>

              {isCreatePlanVisible ? (
                <div className="mb-4">
                  <CreatePlan />
                </div>
              ) : (
                <>
                  <Input
                    placeholder="Search Title"
                    value={searchTitle}
                    onChange={(e) => setSearchTitle(e.target.value)}
                    style={{ width: 200, marginRight: 8 }}
                  />
                  <Input
                    placeholder="Search Category"
                    value={searchCat}
                    onChange={(e) => setSearchCat(e.target.value)}
                    style={{ width: 200, marginRight: 8 }}
                  />
                  <Input
                    placeholder="Search Status"
                    value={searchStatus}
                    onChange={(e) => setSearchStatus(e.target.value)}
                    style={{ width: 200, marginRight: 8 }}
                  />
                  <div className="overflow-x-auto">
                    <Table
                      columns={columns}
                      dataSource={filteredInvest}
                      pagination={pagination}
                      className="font-PoppinsRegular text-textcolor mt-6 min-w-full"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InvestmentPlan;
