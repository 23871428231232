import { useState } from "react";
import Illustrator from "../assets/img/illustrator-aff 1.png";
import Logo from "../assets/img/affluence.png";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import PasswordInput from "../components/PasswordInput";
import AuthButton from "../components/AuthButton";
import { HttpClient } from "../api/HttpClient";
import ToastComponent from "../components/ToastComponent";
import { isAxiosError } from "axios";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "../store/authSlice";
import PageTitle from "../components/Pagetitle";
import LightAuthTextInput from "../components/LightAuthTextInput";
import LightAuthPasswordInput from "../components/LightAuthPasswordInput";
// import bcrypt from "bcryptjs";
// import { fetchUserDetails } from "../components/FetchUserRole";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const userSchema = Yup.object().shape({
    userId: Yup.string().required(
      "Enter your Email Address or phone number or username"
    ),
    password: Yup.string().required("Enter your Password"),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate: boolean
      ) => void;
    }
  ) => {
    setLoading(true);
    try {
      // const hashedPassword = await bcrypt.hash(values.password, 15);
      const PWD_HEADER = process.env.REACT_APP_PWD_HEADER;
      const res = await HttpClient.post(
        "/users/auth",
        { userId: values.userId },
        {
          headers: {
            // [PWD_HEADER || "PWD_HEADER"]: hashedPassword,
            [PWD_HEADER || "PWD_HEADER"]: values.password,
          },
        }
      );
      ToastComponent.success(res.data.message);
      const token = res.data.data.token;
      dispatch(setIsAuthenticated(true));
      // await dispatch(fetchUserDetails() as any);
      localStorage.setItem("token", token);

      const userData = await HttpClient.get("/users/me", {
        headers: {
          "x-auth-token": token,
        },
      });
      const userKYCData = userData.data.data.kyc;
      const emailVerifyData = userData.data.data.emailVerificationStatus;
      console.log(emailVerifyData);
      if (emailVerifyData) {
        if (userKYCData?.kycApprovalStatus && userKYCData.kycApprovalStatus) {
          navigate("/");
        } else {
          navigate("/kyc");
        }
      } else {
        navigate("/verify", {
          state: { email: values.userId },
        });
        await HttpClient.post("users/generate-token", {
          email: values.userId,
        });
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("password", "", false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle title="Affluence - Login" />
      <div className=" font-PoppinsRegular text-textcolor">
        <div className="h-screen flex xmd:flex-row flex-col xmd:justify-center xmd:items-stretch items-center">
          <div className="bg-primary h-screen xmd:flex w-[55%] hidden justify-center items-center bg-opacity-10">
            <img src={Illustrator} className="" alt="img" />
          </div>
          <div className="xmd:w-[45%] w-full bg-white sm:px-[3%] px-[30px] xmd:pt-0 pt-[20vh] flex justify-center items-center">
            <div className="xmd:w-[90%] w-full max-w-[500px]">
              <div className="flex justify-center">
                <img src={Logo} className="sm:w-[120px] w-[100px]" alt="img" />
              </div>
              <h1 className="text-center flex gap-2 justify-center text-primary pt-2 font-PoppinsLight">
                <span className=" font-PoppinsMedium">Save</span>
                <span className="text-secondary">|</span>
                <span className=" font-PoppinsMedium">Invest</span>
                <span className="text-secondary">|</span>
                <span className=" font-PoppinsMedium">Earn</span>
              </h1>
              <div className="pt-3 pb-5 sm:text-start text-center">
                <h1 className="sm:text-[28px] text-[black] text-[24px] font-PoppinsBold pb-1">
                  Log in to Affluence
                </h1>
                <p className="font-PoppinsRegular text-[black] sm:text-[15px] text-[14px]">
                  Please enter your login details
                </p>
              </div>
              <Formik
                initialValues={{
                  userId: "",
                  password: "",
                }}
                validationSchema={userSchema}
                onSubmit={handleSubmit}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <LightAuthTextInput
                      name="userId"
                      type="text"
                      placeholder="Email Address or Phone Number or Username"
                    />
                    <LightAuthPasswordInput
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                    <div className="pb-4">
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            className="mr-2 custom-checkbox"
                            id="remember"
                            name="remember"
                          />
                          <label
                            htmlFor="loggedin"
                            className="font-PoppinsRegular text-[14px]"
                          >
                            Keep me logged in
                          </label>
                        </div>
                        <div>
                          <Link
                            to="/forgot"
                            className="font-PoppinsRegular text-[14px] text-primary"
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <AuthButton
                      disabled={!isValid || !dirty}
                      isLoading={loading}
                    >
                      Login
                    </AuthButton>
                    <h1 className="font-PoppinsRegular text-center pt-3 text-[14px]">
                      Don’t have an account?{" "}
                      <Link
                        to="/sign_up"
                        className="text-primary font-PoppinsMedium"
                      >
                        Sign up
                      </Link>
                    </h1>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
