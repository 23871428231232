import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface titleProps {
  title?: string;
}

const PageTitle: FC<titleProps> = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title || "Not Found";
  }, [location, title]);

  return null;
};

export default PageTitle;
