import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import PageTitle from "../../components/Pagetitle";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { AiOutlineBank } from "react-icons/ai";
import { LiaPercentageSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NoDataIllustration";
import NoTransaction from "../../assets/img/Wavy_Tech-31_Single-03.jpg";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { HttpClient } from "../../api/HttpClient";
import ErrorHandler from "../ErrorHandler";
import { ScaleLoader } from "react-spinners";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  DoughnutController,
  ChartOptions,
  ChartData as ChartJsData,
} from "chart.js";

ChartJS.register(ArcElement, DoughnutController);

type UserAccountType = {
  _id?: string;
  balance: number;
  savings: number;
  investments: number;
  deposit: number;
  withdrawal: number;
  profit: number;
  referral: number;
};
interface DashboardStatusCharts {
  name?: string;
  value?: number;
  fill?: string;
  labels?: string[];
  datasets?: {
    data?: number[];
    backgroundColor?: string[];
    borderColor?: string[];
    borderWidth?: number;
  }[];
}

interface ChartData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }>;
}

interface TransactionLogData {
  _id?: string;
  key?: string;
  amount: number;
  transactionRefCode: string;
  transactionDate: string;
  responseMsg: string;
  transactionType: string;
  isInFlow?: boolean;
  name: string;
}

const doughnutOptions: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
      labels: {
        color: "#3D3C3A",
        font: {
          size: 14,
          family: "PoppinsRegular",
        },
        boxWidth: 10,
        padding: 10,
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const isCurrency = context.dataset.label === "amount";
          const value = context.raw;
          return isCurrency ? `₦${value}` : `${value}`;
        },
      },
    },
  },
  cutout: "80%",
};

function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usersAccount, setUsersAccount] = useState<UserAccountType | null>(
    null
  );
  const [paymentGatewayChart, setPaymentGatewayChart] = useState<
    DashboardStatusCharts[]
  >([]);
  const [investChart, setInvestChart] = useState<DashboardStatusCharts[]>([]);
  const [depositChart, setDepositChart] = useState<DashboardStatusCharts[]>([]);
  const [recentAct, setRecentAct] = useState<TransactionLogData[]>([]);
  const [paymentMthdChart, setPaymentMthdChart] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#009306", "#6B006B", "#0357D1", "#C59832"],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });
  const [savingsChart, setSavingsChart] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "amount",
        data: [],
        backgroundColor: ["#009306", "#E80404"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });
  const [withdrawalChart, setWithdrawalChart] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "amount",
        data: [],
        backgroundColor: ["#009306", "#E80404"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const centerTextPlugin = {
    id: "centerTextPlugin",
    beforeDraw: (chart: any) => {
      const { ctx, chartArea, data } = chart;
      const { top, bottom, left, right } = chartArea;

      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const total = data.datasets[0].data.reduce(
        (acc: number, value: number) => acc + value,
        0
      );

      const isCurrency = data.datasets[0].label === "amount";
      const totalText = isCurrency ? `₦${total.toLocaleString()}` : `${total}`;

      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;

      ctx.font = "14px PoppinsLight";
      ctx.fillStyle = "#3D3C3A";

      ctx.fillText("Total:", centerX, centerY - 10);

      ctx.fillText(totalText, centerX, centerY + 10);

      ctx.restore();
    },
  };

  const fetchUsersData = async () => {
    const usersRes = await HttpClient.get("/admin/view-users", {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    return usersRes.data.data;
  };

  const fetchInvestData = async () => {
    const investRes = await HttpClient.get("/investments/status-statistics", {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
        search: "",
        startdate: "",
        enddate: "",
      },
    });
    return investRes.data.data;
  };

  const fetchTransactionHistory = async (search?: string) => {
    const transRes = await HttpClient.get(`/transactions/history`, {
      headers: { "x-auth-token": localStorage.getItem("token") },
      params: { search: search || "", dir: "desc" },
    });
    return transRes.data.data.data;
  };

  const formatUserAccountData = (userAccountData: UserAccountType[]) => {
    const aggregatedAccountDetails = userAccountData.reduce(
      (acc: any, user: any) => {
        const accountDetails = user.userAccountDetails;

        acc.balance += accountDetails.balance;
        acc.savings += accountDetails.savings;
        acc.investments += accountDetails.investments;
        acc.deposit += accountDetails.deposit;
        acc.withdrawal += accountDetails.withdrawal;
        acc.profit += accountDetails.profit;
        acc.referral += accountDetails.referral;

        return acc;
      },
      {
        balance: 0,
        savings: 0,
        investments: 0,
        deposit: 0,
        withdrawal: 0,
        profit: 0,
        referral: 0,
      }
    );
    return aggregatedAccountDetails;
  };

  const formatInvestData = (investData: any) => [
    {
      name: "All Investments",
      value: investData.all_investment_data,
      fill: "#0357D1",
    },
    {
      name: "Pending",
      value: investData.pending_investment_data,
      fill: "#C59832",
    },
    {
      name: "Running",
      value: investData.running_investment_data,
      fill: "#009306",
    },
    {
      name: "Matured",
      value: investData.matured_investment_data,
      fill: "#6B006B",
    },
    {
      name: "Sold Out",
      value: investData.sold_out_investment_data,
      fill: "#E80404",
    },
  ];

  const formatGatewayData = (gatewayData: any) => {
    const gatewayCounts = gatewayData.reduce((acc: any, transaction: any) => {
      const gateway = transaction.paymentGateway;
      acc[gateway] = (acc[gateway] || 0) + 1;
      return acc;
    }, {});
    return [
      {
        name: "Flutterwave",
        value: gatewayCounts.flutterwave,
        fill: "#EB5634",
      },
      { name: "Paystack", value: gatewayCounts.paystack, fill: "#3FA6DB" },
    ];
  };

  const formatDepositData = (depositData: any) => {
    const depositsCounts = depositData.reduce((acc: any, transaction: any) => {
      const date = new Date(transaction.transactionDate);
      const formattedMonthYear = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      });
      const formattedDate = formattedMonthYear.replace(/(\w+)( \d+)/, "$1,$2");
      acc[formattedDate] = (acc[formattedDate] || 0) + transaction.amount;
      return acc;
    }, {});
    return Object.keys(depositsCounts).map((date) => ({
      name: date,
      value: depositsCounts[date],
      fill: "#A03DF0",
    }));
  };

  const formatSavingsData = (savingsData: any) => {
    const savingsCounts = savingsData.reduce(
      (acc: any, transaction: any) => {
        const status = transaction.paymentStatus;

        if (status === "successful") {
          acc.successful += transaction.amount;
        } else if (status === "failed") {
          acc.failed += transaction.amount;
        }

        return acc;
      },
      { successful: 0, failed: 0 }
    );

    return {
      labels: [
        `₦${savingsCounts.successful.toLocaleString()} Successful`,
        `₦${savingsCounts.failed.toLocaleString()} Failed`,
      ],
      datasets: [
        {
          label: "amount",
          data: [savingsCounts.successful, savingsCounts.failed],
          backgroundColor: ["#009306", "#E80404"],
          borderColor: ["#FFFFFF", "#FFFFFF"],
          borderWidth: 2,
        },
      ],
    };
  };

  const formatWithdrawalData = (withdrawalData: any) => {
    const withdrawalCounts = withdrawalData.reduce(
      (acc: any, transaction: any) => {
        const status = transaction.paymentStatus;

        if (status === "successful") {
          acc.successful += transaction.amount;
        } else if (status === "failed") {
          acc.failed += transaction.amount;
        }

        return acc;
      },
      { successful: 0, failed: 0 }
    );

    return {
      labels: [
        `₦${withdrawalCounts.successful.toLocaleString()} Successful`,

        `₦${withdrawalCounts.failed.toLocaleString()} Failed`,
      ],
      datasets: [
        {
          label: "amount",
          data: [withdrawalCounts.successful, withdrawalCounts.failed],
          backgroundColor: ["#009306", "#E80404"],
          borderColor: ["#FFFFFF", "#FFFFFF"],
          borderWidth: 2,
        },
      ],
    };
  };

  const formatRecentActData = (recentActData: any) => {
    return recentActData.map((transaction: any) => {
      const date = new Date(transaction.transactionDate);
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return {
        key: transaction._id,
        amount: transaction.amount,
        transactionRefCode: transaction.transactionRefCode,
        transactionDate: formattedDate,
        responseMsg: transaction.responseMsg,
        transactionType: transaction.transactionType,
        isInFlow: transaction.isInFlow,
        name: transaction.transactionDetails.customer?.name,
      };
    });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const [
          userAccountData,
          investData,
          depositsData,
          savingsData,
          withdrawalData,
          methodData,
          gatewayData,
          recentActData,
        ] = await Promise.all([
          fetchUsersData(),
          fetchInvestData(),
          fetchTransactionHistory("deposits"),
          fetchTransactionHistory("savings"),
          fetchTransactionHistory("withdrawals"),
          fetchTransactionHistory(),
          fetchTransactionHistory(),
          fetchTransactionHistory(),
        ]);

        setUsersAccount(formatUserAccountData(userAccountData));
        setInvestChart(formatInvestData(investData));
        setPaymentGatewayChart(formatGatewayData(gatewayData));
        setDepositChart(formatDepositData(depositsData));
        setSavingsChart(formatSavingsData(savingsData));
        setWithdrawalChart(formatWithdrawalData(withdrawalData));

        const sortedRecentActData = recentActData
          .sort((a: TransactionLogData, b: TransactionLogData) => {
            const dateA = new Date(a.transactionDate).getTime();
            const dateB = new Date(b.transactionDate).getTime();
            return dateB - dateA;
          })
          .slice(0, 3);

        setRecentAct(formatRecentActData(sortedRecentActData));

        const paymentMethodCounts = methodData.reduce(
          (acc: any, transaction: any) => {
            const method = transaction.paymentMethod;
            acc[method] = (acc[method] || 0) + 1;
            return acc;
          },
          {}
        );

        const labels = Object.keys(paymentMethodCounts).map((label) =>
          label
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        );
        const dataValues = Object.values(paymentMethodCounts).map((value) =>
          Number(value)
        );

        setPaymentMthdChart({
          labels: labels,
          datasets: [
            {
              label: "Payment Methods",
              data: dataValues,
              backgroundColor: ["#6B006B", "#0357D1", "#00936D"],
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
              borderWidth: 1,
            },
          ],
        });
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const handleViewAllInvestment = () => {
    navigate("/admin/investment/investment_log");
  };
  const handleViewAllDeposits = () => {
    navigate("/admin/deposits");
  };

  const handleViewAllSavings = () => {
    navigate("/admin/savings");
  };

  const handleViewAllPayouts = () => {
    navigate("/admin/payout");
  };

  const handleViewAllPaymentGateways = () => {
    navigate("/admin/transactions/payment_gateway");
  };

  const handleViewTransactions = () => {
    navigate("/admin/transactions/transaction_log");
  };

  return (
    <>
      <PageTitle title=" Admin Dashboard" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color="#6B006B" />
            </div>
          ) : (
            <div className="flex-1 mx-4 my-4 mt-28">
              <div className="grid grid-cols-3 gap-4 mt-8">
                {/* Wallet Balance Card */}
                <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                  <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <MdOutlineAccountBalanceWallet size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Wallet Balance
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {formatCurrency(usersAccount?.balance || 0)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Total Investment Card */}
                <div className="bg-investment bg-opacity-5 p-5 rounded-[8px] border border-investment">
                  <div className="bg-investment text-white p-5 h-[120px] block rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <AiOutlineBank size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total Investment
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {formatCurrency(usersAccount?.investments || 0)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Profit */}
                <div className="bg-secondary bg-opacity-5 p-5 rounded-[8px] border border-secondary">
                  <div className="bg-secondary text-white p-5 h-[120px] block rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <LiaPercentageSolid size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total Profit
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {formatCurrency(usersAccount?.profit || 0)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4 mt-8">
                {/* Total Deposit Card */}
                <div className="bg-deposit bg-opacity-5 p-5 rounded-[8px] border border-deposit">
                  <div className="bg-deposit text-white p-5 h-[120px] block rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <PiHandDepositBold size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total Deposit
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {formatCurrency(usersAccount?.deposit || 0)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Total Withdrawal */}
                <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                  <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <PiHandWithdrawLight size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total Withdrawal
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {formatCurrency(usersAccount?.withdrawal || 0)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Total Referral */}
                <div className="bg-referral bg-opacity-5 p-5 rounded-[8px] border border-referral">
                  <div className="bg-referral text-white p-5 h-[120px] block rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <GiTakeMyMoney size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total Referral
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {formatCurrency(usersAccount?.referral || 0)}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* INVESTMENT and DEPOSIT CARDS */}
              <div className="grid grid-cols-2 gap-4 mt-8">
                <div className="border border-transactionCard p-4 rounded-lg">
                  <div className="flex font-PoppinsSemiBold  justify-between">
                    <span className="text-textcolor block">Investments</span>
                    <button
                      className="text-investment text-sm hover:text-opacity-50"
                      onClick={handleViewAllInvestment}
                    >
                      View More
                    </button>
                  </div>
                  {investChart && investChart.length > 0 ? (
                    <div className="overflow-x-auto mt-8">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={investChart} className="text-textcolor">
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip formatter={(value: number) => `${value}`} />
                          <Bar dataKey="value" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>

                <div className="border border-transactionCard p-4 rounded-lg">
                  <div className="flex justify-between font-PoppinsSemiBold">
                    <span className="text-textcolor block">Deposits</span>
                    <button
                      className="text-investment text-sm hover:text-opacity-50"
                      onClick={handleViewAllDeposits}
                    >
                      View More
                    </button>
                  </div>
                  {depositChart && depositChart.length > 0 ? (
                    <div className="overflow-x-auto mt-8">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={depositChart}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis
                            tickFormatter={(value) => formatCurrency(value)}
                          />
                          <Tooltip
                            formatter={(value: number) => formatCurrency(value)}
                          />
                          <Bar dataKey="value" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>
              </div>

              {/* SAVINGS AND WITHDRAWALS */}
              <div className="grid grid-cols-2 gap-4 mt-8">
                <div className="border border-transactionCard p-4 rounded-lg">
                  <div className="flex font-PoppinsSemiBold  justify-between">
                    <span className="text-textcolor block">Savings</span>
                    <button
                      className="text-investment text-sm hover:text-opacity-50"
                      onClick={handleViewAllSavings}
                    >
                      View More
                    </button>
                  </div>
                  {savingsChart &&
                  savingsChart.datasets &&
                  savingsChart.datasets.length > 0 ? (
                    <div className="pt-4 flex justify-center items-center">
                      <div style={{ width: 350, height: 300 }}>
                        <Doughnut
                          data={savingsChart}
                          options={doughnutOptions}
                          plugins={[centerTextPlugin]}
                        />
                      </div>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>

                <div className="border border-transactionCard p-4 rounded-lg">
                  <div className="flex justify-between font-PoppinsSemiBold">
                    <span className="text-textcolor block">Withdrawals</span>
                    <button
                      className="text-investment text-sm hover:text-opacity-50"
                      onClick={handleViewAllPayouts}
                    >
                      View More
                    </button>
                  </div>
                  {withdrawalChart &&
                  withdrawalChart.datasets &&
                  withdrawalChart.datasets.length > 0 ? (
                    <div className="pt-4 flex justify-center items-center">
                      <div className="h-[300px] w-[350px]">
                        <Doughnut
                          data={withdrawalChart}
                          plugins={[centerTextPlugin]}
                          options={doughnutOptions}
                        />
                      </div>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>
              </div>

              {/* PAYMENT GATEWAYS AND PAYMENT METHODS */}
              <div className="grid grid-cols-2 gap-4 mt-8">
                <div className="border border-transactionCard p-4 rounded-lg">
                  <div className="flex font-PoppinsSemiBold  justify-between">
                    <span className="text-textcolor block">
                      Payment Gateways
                    </span>
                    <button
                      className="text-success text-sm hover:text-opacity-50"
                      onClick={handleViewAllPaymentGateways}
                    >
                      View More
                    </button>
                  </div>
                  {paymentGatewayChart && paymentGatewayChart.length > 0 ? (
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={paymentGatewayChart}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          label
                        >
                          {paymentGatewayChart.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value) => `${value}`} />
                        <Legend
                          verticalAlign="middle"
                          align="right"
                          layout="vertical"
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>

                <div className="border border-transactionCard p-4 rounded-lg">
                  <span className="text-textcolor font-PoppinsSemiBold block">
                    Payment Methods
                  </span>
                  {paymentMthdChart &&
                  paymentMthdChart.datasets &&
                  paymentMthdChart.datasets.length > 0 ? (
                    <div className="pt-4 flex justify-center items-center">
                      <div style={{ width: 350, height: 300 }}>
                        <Doughnut
                          data={paymentMthdChart}
                          plugins={[centerTextPlugin]}
                          options={doughnutOptions}
                        />
                      </div>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>
              </div>

              {/* RECENT ACTIVITIES */}
              <div className="border border-transactionCard p-4 rounded-lg mt-8">
                <div className="flex font-PoppinsSemiBold justify-between text-textcolor">
                  <span>Recent Activities</span>
                  <button
                    onClick={handleViewTransactions}
                    className="text-success cursor-pointer hover:text-opacity-50"
                  >
                    View More
                  </button>
                </div>

                {recentAct.length > 0 ? (
                  recentAct.map((transaction) => (
                    <div
                      key={transaction?._id}
                      className="flex justify-between font-PoppinsLight text-textcolor border border-transactionCard px-4 py-4 rounded-lg mt-8"
                    >
                      <div>
                        <p className="font-PoppinsSemiBold">
                          {transaction?.name}
                        </p>
                        <p>{transaction?.transactionRefCode}</p>
                        <p
                          className={`${
                            transaction?.isInFlow
                              ? "text-success"
                              : "text-error"
                          }`}
                        >
                          {transaction?.isInFlow ? "Credit" : "Debit"}
                        </p>
                      </div>
                      <div>
                        <p
                          className={`capitalize ${
                            transaction.transactionType === "wallet"
                              ? "text-primary"
                              : transaction.transactionType === "deposit"
                              ? "text-deposit"
                              : transaction.transactionType === "withdrawal"
                              ? "text-primary"
                              : transaction.transactionType === "savings"
                              ? "text-secondary"
                              : transaction.transactionType === "investment"
                              ? "text-investment"
                              : transaction.transactionType === "referral"
                              ? "text-referral"
                              : "text-textcolor"
                          }`}
                        >
                          {transaction?.transactionType}
                        </p>
                        <div className="flex flex-col">
                          <p>{transaction?.transactionDate}</p>
                        </div>
                        <p>{formatCurrency(transaction?.amount)}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center py-5">
                    <div>
                      <div className="flex items-center justify-center">
                        <img
                          src={NoTransaction}
                          className="w-[200px]"
                          alt="illustrator"
                        />
                      </div>
                      <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                        No transactions currently!
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
