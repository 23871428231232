import { FC } from "react";
import { InvestmentItem } from "../assets/Typeprops";

interface MaturedInvestmentsProps {
  data: InvestmentItem[];
}
const MaturedInvestmentMobile: FC<MaturedInvestmentsProps> = ({ data }) => {
  return (
    <>
      <div className="flex gap-3 pl-[3%] overflow-x-scroll hide-scroll">
        {data?.map((item, index) => (
          <div key={index} className="inline-block cursor-pointer w-[150px]">
            <div className="w-[150px] h-[100px] relative">
              <img
                src={item.previewImage}
                className="w-full h-full bg-cover object-cover rounded-[10px]"
                alt="investment-img"
              />
              <div className="bg-success text-white rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2">
                RUNNING
              </div>
            </div>
            <div className="pt-2">
              <h1 className="text-textcolor dark:text-white uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                {item.title}
              </h1>
              <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                Interest rate at{" "}
                <span className="font-PoppinsSemiBold text-success">
                  {`${item.rate}%`}
                </span>
              </h1>
              <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                {`Returns in ${item.durationNumber} ${item.durationInterval}`}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MaturedInvestmentMobile;
