import { FC } from "react";
import { IconType } from "react-icons";

interface AccountSettingsProps {
  children: any;
  icon1?: IconType;
  onClick?: () => void;
  label?: string;
  description?: string;
}
const SetAccountSettingsCard: FC<AccountSettingsProps> = ({
  children,
  icon1: IconComponent1,
  onClick,
  label,
  description,
}) => {
  return (
    <div className="mb-2">
      <h1 className="text-[12px] font-PoppinsRegular pb-1">{label}</h1>

      <div
        className="p-4 rounded-[4px] cursor-pointer flex items-center justify-between border-[1.5px] border-transactionCard
    "
        onClick={onClick}
      >
        <div className="flex items-center space-x-5">
          <div className="md:text-[22px] sm:text-[20px] text-primary text-[18px]">
            {IconComponent1 && <IconComponent1 />}
          </div>
          <div>
            <h1 className="font-PoppinsRegular text-[14px]">{children}</h1>
            <h1 className="font-PoppinsLight text-[13px] pt-2">
              {description}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetAccountSettingsCard;
