import { useCallback, useEffect, useMemo, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/affluence-logo-small.png";
import Img from "../assets/img/invest.webp";
import Modal from "../components/Modal";
import SelectInputWithLabel from "../components/SelectInputWithLabel";
import TextInputWithLabel from "../components/TextInputWithLabel";
import { Form, Formik } from "formik";
import SelectInputWithLabelAndPlaceholder from "../components/SelectInputWithLabelAndPlaceholder";
import InvestmentBtn from "../components/InvestmentBtn";
import { HttpClient } from "../api/HttpClient";
import { AxiosResponse, isAxiosError } from "axios";
import { formatDate } from "../components/DateFormat";
import { formatCurrency } from "../components/CurrencyFormat";
import ToastComponent from "../components/ToastComponent";
import * as Yup from "yup";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { InvestmentItem } from "../assets/Typeprops";
import { highlightText } from "../components/HighLight";
import { ConfigProvider, Switch } from "antd";
import { TiWarningOutline } from "react-icons/ti";

interface InvestmentRes {
  data: {
    data: InvestmentItem[];
    total: number;
  };
}

const AllInvestments = ({ walletBal }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentItem | null>(null);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [investmentData, setInvestmentData] = useState<InvestmentItem[]>([]);
  const [aboutModal, setAboutModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const navigate = useNavigate();

  const openShowInvestmentModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const openInvestNowModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };

  const wallet = [
    {
      value: "balance",
      label: `Wallet Balance (${
        walletBal?.account?.balance
          ? formatCurrency(walletBal.account.balance)
          : "₦0"
      })`,
    },
    {
      value: "profit",
      label: `Profit Balance (${
        walletBal?.account?.profits
          ? formatCurrency(walletBal.account.profits)
          : "₦0"
      })`,
    },
    {
      value: "referral",
      label: `Referral Balance (${
        walletBal?.account?.referrals
          ? formatCurrency(walletBal.account.referrals)
          : "₦0"
      })`,
    },
  ];

  const fetchAllInvestments = useCallback(async () => {
    setIsLoading(true);
    try {
      const investmentRes: AxiosResponse<InvestmentRes> = await HttpClient.get(
        "/investments/plan",
        {
          params: {
            page: 1,
            limit: 20,
            dir: "desc",
            sort: "created_at",
            search: "",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      setInvestmentData(investmentRes?.data?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchAllInvestments();
  }, [fetchAllInvestments]);
  const units = Array.from(
    { length: selectedInvestment?.unitsRemaining ?? 0 },
    (_, i) => {
      const value = i + 1;
      return { value: value, label: `${value} units` };
    }
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = useMemo(() => {
    let result = investmentData;
    if (filterCategory) {
      result = result.filter((item) => item.category === filterCategory);
    }
    if (searchQuery) {
      result = result.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return result;
  }, [filterCategory, investmentData, searchQuery]);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const bookInvestmentSchema = Yup.object().shape({
    units: Yup.number(),
    fundingSource: Yup.string().required("Please select a funding source"),
    answer: Yup.string().required("Please enter security answer"),
  });

  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookInvestRes = await HttpClient.post(
        "/investments/booking",
        { ...values, id: selectedInvestment?._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investment/my_investments");
      ToastComponent.success(bookInvestRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };

  return (
    <div className="pt-6">
      <div className="relative mx-[3%]">
        <input
          type="search"
          placeholder="Search Investments"
          onChange={handleSearchChange}
          value={searchQuery}
          className="bg-searchBar border dark:text-darkPrimary border-authbordercolor dark:border-textcolor font-PoppinsLight text-[12px] outline-none placeholder:text-authbordercolor w-full sm:py-3 sm:px-10 p-2.5 rounded-[8px]"
        />
        <IoIosSearch className="text-authbordercolor text-[22px] absolute left-2.5 top-2.5" />
      </div>
      <div className="border-b-[1.5px] border-transactionCard dark:border-textcolor pb-5">
        <div className="flex text-[12px] font-PoppinsMedium justify-between items-center gap-10 pt-6 px-[3%]">
          <div
            onClick={() => setFilterCategory("")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor  rounded-[10px] w-full text-center ${
              filterCategory === "" ? "bg-primary text-white" : ""
            }`}
          >
            All
          </div>
          <div
            onClick={() => setFilterCategory("Fixed Income")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor  rounded-[10px] w-full text-center ${
              filterCategory === "Fixed Income" ? "bg-primary text-white" : ""
            }`}
          >
            Fixed Income
          </div>
          <div
            onClick={() => setFilterCategory("Real Estate")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor  rounded-[10px] w-full text-center ${
              filterCategory === "Real Estate" ? "bg-primary text-white" : ""
            }`}
          >
            Real Estate
          </div>
          <div
            onClick={() => setFilterCategory("Agriculture")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor  rounded-[10px] w-full text-center ${
              filterCategory === "Agriculture" ? "bg-primary text-white" : ""
            }`}
          >
            Agriculture
          </div>
          <div
            onClick={() => setFilterCategory("Transportation")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor  rounded-[10px] w-full text-center ${
              filterCategory === "Transportation" ? "bg-primary text-white" : ""
            }`}
          >
            Transportation
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center relative">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
          </div>
        ) : (
          <>
            {filteredData?.map((item, index) => (
              <div
                key={index}
                className="border-y-[1.5px] cursor-pointer w-full border-transactionCard dark:border-textcolor mb-2 py-2 dark:shadow-[#000000] shadow-transactionCard shadow-sm"
                onClick={() => openShowInvestmentModal(item)}
              >
                <div className="flex items-center gap-[50px] px-[3%]">
                  <div className="w-[200px] h-[150px] relative">
                    <img
                      src={item.previewImage}
                      className="object-cover w-full h-full rounded-[10px]"
                      alt="investment-img"
                    />
                  </div>
                  <div>
                    <h1 className="font-PoppinsSemiBold uppercase text-[18px] pb-2">
                      {highlightText(item.title, searchQuery)}
                    </h1>
                    <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                      {item.rate}%{" "}
                      <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                        {`returns in ${item.durationNumber} ${item.durationInterval}`}
                      </span>
                    </h1>
                    <div className="flex items-center gap-9 py-2">
                      <div>
                        <h1 className=" font-PoppinsSemiBold text-[12px] text-primary dark:text-white">
                          {item?.amount ? formatCurrency(item?.amount) : "₦0"}
                        </h1>
                        <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                          per unit
                        </h1>
                      </div>
                      <>
                        {item.status === "pending" ? (
                          <div>
                            <h1 className="text-[12px] text-center font-PoppinsMedium">
                              <span>{item.unitsSold}</span> /
                              <span>{item.units}</span>
                            </h1>
                            <h1 className="font-PoppinsMedium text-center text-[9px] text-tabletext">
                              Units
                            </h1>
                          </div>
                        ) : item.status === "running" ? (
                          <div>
                            <h1 className="text-[12px] text-center font-PoppinsMedium">
                              <span>{item.numberOfInvestors}</span>
                            </h1>
                            <h1 className="font-PoppinsMedium text-center text-[9px] text-tabletext">
                              {item.numberOfInvestors === 1
                                ? "Investor"
                                : "Investors"}
                            </h1>
                          </div>
                        ) : item.status === "sold out" ? (
                          <div>
                            <h1 className="text-[12px] font-PoppinsMedium">
                              <span>{item.unitsSold}</span> /
                              <span>{item.units}</span>
                            </h1>
                            <h1 className="font-PoppinsMedium text-[9px] text-tabletext">
                              Units
                            </h1>
                          </div>
                        ) : null}
                      </>
                    </div>
                    <div
                      className={`${
                        item.status === "running"
                          ? "text-referral bg-referral"
                          : item.status === "matured"
                          ? isDarkMode
                            ? "text-[#C8A2C8] bg-[#C8A2C8]"
                            : "text-primary bg-primary"
                          : item.status === "pending"
                          ? "text-secondary bg-secondary"
                          : "text-error bg-error"
                      } bg-opacity-[28%] inline-block py-1 px-4 rounded-[10px] font-PoppinsMedium text-[10px]`}
                    >
                      {item.status}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {isModalOpen && selectedInvestment && (
        <Modal
          isOpenModal={openShowInvestmentModal}
          closeModal={closeShowInvestmentModal}
        >
          <div
            className="flex items-center mt-[90px] justify-center"
            style={{
              backgroundImage: `url(${selectedInvestment.previewImage})`,
              height: "250px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
              <img src={Logo} className="w-[30px]" alt="" />
            </div>
          </div>
          <div className="px-4">
            <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
              VERIFIED INVESTMENT
            </div>
            <div className="flex justify-between">
              <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                {selectedInvestment.title}
              </h1>
              {selectedInvestment.status === "pending" ? (
                <div>
                  <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                    {selectedInvestment.amount
                      ? formatCurrency(selectedInvestment.amount)
                      : "₦0"}
                  </h1>
                  <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                    per unit
                  </h1>
                </div>
              ) : selectedInvestment.status === "sold out" ? (
                <div>
                  <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                    {selectedInvestment.amount
                      ? formatCurrency(selectedInvestment.amount)
                      : "₦0"}
                  </h1>
                  <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                    per unit
                  </h1>
                </div>
              ) : (
                <div>
                  <h1 className="font-PoppinsBold uppercase text-center text-[20px] text-success">
                    {selectedInvestment.numberOfInvestors}
                  </h1>
                  <h1 className="font-PoppinsMedium text-center uppercase text-tabletext text-[9px]">
                    {selectedInvestment.numberOfInvestors === 1
                      ? "Investor"
                      : "Investors"}
                  </h1>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4 mt-5">
              {selectedInvestment.status === "sold out" ? (
                <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                  SOLD OUT
                </button>
              ) : selectedInvestment.status === "pending" ? (
                <button
                  onClick={() => openInvestNowModal(selectedInvestment)}
                  className="bg-investment border-[1.5px] border-investment text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                >
                  INVEST NOW
                </button>
              ) : selectedInvestment.status === "running" ? (
                <button className="bg-referral cursor-not-allowed border-[1.5px] border-referral bg-opacity-10 text-referral font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                  RUNNING
                </button>
              ) : null}
              {selectedInvestment.status === "sold out" ? (
                <button
                  onClick={() => setAboutModal(true)}
                  className="border-[1.5px] border-error text-error font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                >
                  ABOUT THIS INVESTMENT
                </button>
              ) : (
                <button
                  onClick={() => setAboutModal(true)}
                  className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                >
                  ABOUT THIS INVESTMENT
                </button>
              )}
            </div>
            <div
              className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                selectedInvestment.status === "sold out"
                  ? "text-error"
                  : selectedInvestment.status === "pending"
                  ? "text-success"
                  : selectedInvestment.status === "running"
                  ? "text-referral"
                  : "text-success"
              }`}
            >
              {selectedInvestment.status === "sold out"
                ? "This investment is currently sold out to Investors. Please look for other investment plans."
                : selectedInvestment.status === "pending"
                ? "This investment plan is still available for purchase."
                : selectedInvestment.status === "running"
                ? "This investment plan is already running for all Investors."
                : "This investment plan is still available for purchase."}
            </div>
            {selectedInvestment.disclaimer && (
              <div className="mt-4">
                <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                  Disclaimer <TiWarningOutline className="text-error" />
                </h1>
                <p className="font-PoppinsLight text-[13px]">
                  {selectedInvestment?.disclaimer}
                </p>
              </div>
            )}
            <hr className="text-authbordercolor my-10" />
            <div>
              <div className="flex justify-center gap-7 mb-5">
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Investment Type
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {selectedInvestment.category}
                  </h1>
                </div>
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Investment Close Date
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {formatDate(selectedInvestment.startDate)}
                  </h1>
                </div>
              </div>
              <div className="flex justify-center gap-7">
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Maturity Date
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {formatDate(selectedInvestment.endDate)}
                  </h1>
                </div>
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Payout Type
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    Capital + profit to be paid
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {aboutModal && selectedInvestment && (
        <Modal
          isOpenModal={() => setAboutModal(true)}
          closeModal={() => setAboutModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div>
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                About Investment
              </h1>
              <div className="py-2">
                <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                  Read about this investment below
                </p>
                {selectedInvestment.disclaimer && (
                  <div className="mt-4">
                    <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                      Disclaimer <TiWarningOutline className="text-error" />
                    </h1>
                    <p className="font-PoppinsLight text-[13px]">
                      {selectedInvestment?.disclaimer}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                className="flex items-center my-7 justify-center"
                style={{
                  backgroundImage: `url(${selectedInvestment.previewImage})`,
                  height: "250px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                  <img src={Logo} className="w-[30px]" alt="" />
                </div>
              </div>
              <p className="text-white text-[13px]">
                {selectedInvestment.description}
              </p>
            </div>
          </div>
        </Modal>
      )}
      {investNowModal && selectedInvestment && (
        <Modal
          isOpenModal={openInvestNowModal}
          closeModal={closeInvestNowModal}
        >
          <div className="mt-[120px] px-4">
            <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
              Invest Now
            </h1>
            <div className="bg-primary rounded-[8px] mt-4 p-4 text-white">
              <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
              <h1 className="font-PoppinsRegular text-[14px]">
                Fill the form below to purchase units from this investment.
              </h1>
            </div>
            <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor dark:text-white">
              {`Number of units to purchase ${formatCurrency(
                selectedInvestment.amount
              )}
              per unit in `}
              <span className="uppercase">{selectedInvestment.title}</span>
            </h1>
            <Formik
              initialValues={{
                units: "",
                fundingSource: "",
                answer: "",
              }}
              onSubmit={handleBookInvestment}
              validationSchema={bookInvestmentSchema}
            >
              {({ values, isValid, dirty, setFieldValue }) => (
                <Form>
                  <SelectInputWithLabel
                    name="units"
                    options={units}
                    label={false}
                  />
                  <SelectInputWithLabelAndPlaceholder
                    name="fundingSource"
                    options={wallet}
                    placeholder="Select a funding source"
                    label="Choose a Source of Funds"
                  />
                  {values.units && values.fundingSource && (
                    <TextInputWithLabel
                      name="answer"
                      type="text"
                      label="Answer for the security question set by you"
                    />
                  )}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#6B006B",
                      },
                    }}
                  >
                    {values.answer !== "" && (
                      <div>
                        <div className="flex items-center space-x-4 justify-between mb-4">
                          <Switch
                            checked={switch1}
                            onChange={() => setSwitch1((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            {`I confirm my purchase of ${values.units} ${
                              values.units === "1" ? "unit" : "units"
                            } of this investment for ${formatCurrency(
                              Number(values.units) * selectedInvestment.amount
                            )}. I agree to retain my units until ${formatDate(
                              selectedInvestment.endDate
                            )} before cashing out.`}
                          </span>
                        </div>
                        <div className="flex items-center justify-between space-x-4 mb-4">
                          <Switch
                            checked={switch2}
                            onChange={() => setSwitch2((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            I acknowledge that I have read and comprehended the
                            investment information. I recognize that investing
                            in third-party instruments carries risks, which may,
                            in rare instances, involve loss of capital and lack
                            of liquidity.
                          </span>
                        </div>
                      </div>
                    )}
                  </ConfigProvider>
                  <div>
                    <InvestmentBtn
                      disabled={!isValid || !dirty || !switch1 || !switch2}
                      isLoading={isBooking}
                    >
                      INVEST NOW
                    </InvestmentBtn>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AllInvestments;
